import axios from 'axios';
import { BACKEND_URL } from './commons/utils.tsx';

// Axios instance
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to update axios instance with new idToken
export const updateAxiosHeaders = () => {
  const idToken = localStorage.getItem("idToken");
  if (idToken) {
    axiosInstance.defaults.headers["Authorization"] = `Bearer ${idToken}`;
  }
};

// Function to listen to storage events and update the headers
export const setupStorageListener = () => {
  const handleStorageChange = () => {
    updateAxiosHeaders();
  };

  window.addEventListener("storage", handleStorageChange);
  updateAxiosHeaders();

  return () => {
    window.removeEventListener("storage", handleStorageChange);
  };
};

export { axiosInstance };
