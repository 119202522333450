import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import LearnCode from "./LearnCodeHistory.tsx";

const Home = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
      <Box width='full' >
        <Tabs index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="2">
            <Tab>LearnCode History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <LearnCode />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default Home;
