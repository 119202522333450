import { Box, Container, Heading, SimpleGrid, VStack, Text, Button, useColorMode, Tooltip } from '@chakra-ui/react';
import { FileCode, Palette, BrainCircuit } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import Navbar from '../NavBar.tsx';

const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);

export default function ConceptsPage() {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const {classId} = useParams();

  const concepts = [
    {
      icon: <FileCode size={20} />,
      title: 'HTML',
      description: 'Structure web content with semantic markup',
      path: 'html'
    },
    {
      icon: <Palette size={20} />,
      title: 'CSS',
      description: 'Style and layout your web pages',
      path: 'css'
    },
    {
      icon: <BrainCircuit size={20} />,
      title: 'JavaScript',
      description: 'Add interactivity to your websites',
      path: 'javascript'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <>
    <Navbar />
    <Box
      minH="calc(100vh - 72px)"
      bg={colorMode === 'light' ? 'white' : 'gray.900'}
      py={10}
    >
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <MotionBox
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading 
              size="lg" 
              color={colorMode === 'light' ? 'gray.800' : 'white'}
              fontWeight="bold"
            >
              Web Development Concepts
            </Heading>
          </MotionBox>
          
          <MotionSimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={6}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {concepts.map((concept, index) => (
              <MotionBox
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                p={6}
                bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                rounded="xl"
                borderWidth="1px"
                borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                display="flex"
                flexDirection="column"
                height="250px"
                shadow="lg"
              >
                <VStack align="stretch" spacing={4} flex={1}>
                  <Box
                    p={3}
                    bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                    color={colorMode === 'light' ? 'blue.500' : 'blue.200'}
                    rounded="lg"
                    display="inline-block"
                    width="fit-content"
                  >
                    {concept.icon}
                  </Box>
                  <Box flex={1}>
                    <Heading 
                      size="md"
                      color={colorMode === 'light' ? 'gray.800' : 'white'}
                      mb={2}
                    >
                      {concept.title}
                    </Heading>
                    <Text 
                      fontSize="md"
                      color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                    >
                      {concept.description}
                    </Text>
                  </Box>
                  <Button
                    size="md"
                    colorScheme="blue"
                    onClick={() => navigate(`/student-portal/${classId}/learncode/learn/${concept.path}`)}
                  >
                    Start Learning
                  </Button>
                </VStack>
              </MotionBox>
            ))}
          </MotionSimpleGrid>
        </VStack>
      </Container>
    </Box>
    </>
  );
}