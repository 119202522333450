import { useState, useRef, useEffect } from 'react';
import { Box, IconButton, useColorMode, Text, HStack } from '@chakra-ui/react';
import { Rnd } from 'react-rnd';
import { X, Copy, Check } from 'lucide-react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow, githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// Import all required languages
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
import typescript from 'react-syntax-highlighter/dist/esm/languages/hljs/typescript';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import React from 'react';

// Register all languages
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('html', xml);
SyntaxHighlighter.registerLanguage('css', css);
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('bash', bash);

type AIResponse = {
  type: 'text' | 'code' | 'explanation';
  content: string;
  language?: string;
  line?: string;
};

type AIChatProps = {
  isOpen: boolean;
  onClose: () => void;
  messages: AIResponse[];
  title: string;
};

const formatMarkdown = (text: string): string => {
  let formatted = text;

  // Inline code
  formatted = formatted.replace(/`([^`]+)`/g, '<code>$1</code>');

  // Bold and italic formatting
  formatted = formatted.replace(/\*\*\*(.*?)\*\*\*/g, '<strong><em>$1</em></strong>');
  formatted = formatted.replace(/___(.*)___/g, '<strong><em>$1</em></strong>');
  formatted = formatted.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formatted = formatted.replace(/__(.*)__/g, '<strong>$1</strong>');
  formatted = formatted.replace(/\*(.*?)\*/g, '<em>$1</em>');
  formatted = formatted.replace(/_(.*)_/g, '<em>$1</em>');

  // Lists
  formatted = formatted.replace(/^[\s]*[-*][\s]+(.*?)$/gm, '<li>$1</li>');
  formatted = formatted.replace(/(<li>.*<\/li>)/s, '<ul>$1</ul>');
  formatted = formatted.replace(/^\d+\.[\s]+(.*?)$/gm, '<li>$1</li>');
  formatted = formatted.replace(/(<li>.*<\/li>)/s, '<ol>$1</ol>');

  // Links
  formatted = formatted.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

  // Images
  formatted = formatted.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1">');

  return formatted;
};

const getLanguageAlias = (language: string | undefined, content: string): string => {
  // If content contains <!DOCTYPE html> or starts with <html>, treat it as HTML
  if (content.includes('<!DOCTYPE html>') || content.startsWith('<html')) {
    return 'html';
  }

  // Map common language names to their highlighter aliases
  const languageMap: { [key: string]: string } = {
    'js': 'javascript',
    'jsx': 'javascript',
    'ts': 'typescript',
    'tsx': 'typescript',
    'shell': 'bash',
    'sh': 'bash',
    'markup': 'html',
    'xml': 'html'
  };

  if (!language) return 'javascript';
  const normalizedLang = language.toLowerCase();
  return languageMap[normalizedLang] || normalizedLang;
};

export default function AIChat({ isOpen, onClose, messages, title }: AIChatProps) {
  const { colorMode } = useColorMode();
  const [isMinimized, setIsMinimized] = useState(false);
  const [size, setSize] = useState({ width: 400, height: 500 });
  const [position, setPosition] = useState({ x: window.innerWidth - 420, y: window.innerHeight - 520 });
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatRef.current && isOpen) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages, isOpen]);

  const handleCopyCode = (code: string, index: number) => {
    navigator.clipboard.writeText(code);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000);
  };

  if (!isOpen) return null;

  return (
    <Rnd
      size={size}
      position={position}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: parseInt(ref.style.width),
          height: parseInt(ref.style.height)
        });
        setPosition(position);
      }}
      minWidth={300}
      minHeight={200}
      bounds="window"
      dragHandleClassName="chat-handle"
      style={{ zIndex: 1000 }}
    >
      <Box
        bg={colorMode === 'light' ? 'white' : 'gray.800'}
        borderWidth="1px"
        borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
        rounded="lg"
        shadow="lg"
        h="full"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box
          className="chat-handle"
          p={2}
          bg={colorMode === 'light' ? 'gray.100' : 'gray.700'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          cursor="move"
          borderBottom="1px"
          borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
        >
          <Text fontWeight="medium">{title}</Text>
          <Box>
            <IconButton
              aria-label="Close"
              icon={<X size={16} />}
              size="sm"
              variant="ghost"
              onClick={onClose}
            />
          </Box>
        </Box>

        <Box
          ref={chatRef}
          flex={1}
          overflowY="auto"
          p={4}
          display={isMinimized ? 'none' : 'block'}
          sx={{
            'code': {
              px: 1,
              py: 0.5,
              rounded: 'md',
              bg: colorMode === 'light' ? 'gray.100' : 'gray.700',
              fontFamily: 'mono',
              fontSize: 'sm'
            },
            'ul, ol': {
              pl: 4,
              mb: 4
            },
            'li': {
              mb: 1
            },
            'a': {
              color: 'blue.500',
              textDecoration: 'underline',
              _hover: {
                color: 'blue.600'
              }
            },
            'img': {
              maxW: 'full',
              h: 'auto',
              rounded: 'md',
              my: 2
            },
            'strong': {
              fontWeight: 'bold'
            },
            'em': {
              fontStyle: 'italic'
            }
          }}
        >
          {messages.map((message, index) => (
            <Box key={index} mb={4}>
              {message.type === 'text' ? (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: formatMarkdown(message.content)
                  }}
                />
              ) : message.type === 'explanation' ? (
                <Box
                  p={3}
                  bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
                  rounded="md"
                  borderLeft="4px"
                  borderColor="blue.500"
                >
                  <Box
                    p={2}
                    bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                    rounded="md"
                    mb={2}
                    fontFamily="mono"
                  >
                    {message.line}
                  </Box>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: formatMarkdown(message.content)
                    }}
                  />
                </Box>
              ) : (
                <Box 
                  rounded="md" 
                  overflow="hidden" 
                  borderWidth="1px"
                  borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                >
                  <HStack 
                    spacing={2} 
                    p={2} 
                    bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
                    borderBottom="1px"
                    borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                    justify="space-between"
                  >
                    <Text fontSize="sm" fontWeight="medium" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                      {message.language?.toUpperCase() || 'CODE'}
                    </Text>
                    <IconButton
                      aria-label="Copy code"
                      icon={copiedIndex === index ? <Check size={16} /> : <Copy size={16} />}
                      size="xs"
                      variant="ghost"
                      onClick={() => handleCopyCode(message.content, index)}
                    />
                  </HStack>
                  <Box bg={colorMode === 'dark' ? 'gray.900' : 'white'}>
                    <SyntaxHighlighter
                      language={getLanguageAlias(message.language, message.content)}
                      style={colorMode === 'light' ? githubGist : tomorrow}
                      customStyle={{
                        margin: 0,
                        padding: '1rem',
                        fontSize: '0.9rem',
                        backgroundColor: 'transparent'
                      }}
                      showLineNumbers={true}
                      wrapLines={true}
                      wrapLongLines={true}
                    >
                      {message.content}
                    </SyntaxHighlighter>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Rnd>
  );
}