import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Heading, SimpleGrid, VStack, Text, Button, useColorMode, Badge } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { GraduationCap, Trophy, Target } from 'lucide-react';
import React from 'react';
import Navbar from '../NavBar.tsx';

const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);

const levels = [
  {
    title: 'Beginner Path',
    description: 'Master the fundamentals with hands-on practice',
    icon: <GraduationCap size={32} />,
    color: 'green',
    difficulty: 'beginner'
  },
  {
    title: 'Intermediate Path',
    description: 'Tackle more complex challenges and real-world scenarios',
    icon: <Target size={32} />,
    color: 'yellow',
    difficulty: 'intermediate'
  },
  {
    title: 'Advanced Path',
    description: 'Push your skills to the next level with expert challenges',
    icon: <Trophy size={32} />,
    color: 'red',
    difficulty: 'advanced'
  }
];

export default function LearningPathPage() {
  const { concept } = useParams<{ concept: string }>();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const {classId} = useParams();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <>
    <Navbar />
    <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <MotionBox
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading
              size="xl"
              color={colorMode === 'light' ? 'gray.800' : 'white'}
              mb={4}
            >
              {concept?.charAt(0).toUpperCase() + concept?.slice(1)} Learning Paths
            </Heading>
            <Text
              fontSize="lg"
              color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
            >
              Choose your path and master {concept} through hands-on challenges
            </Text>
          </MotionBox>

          <MotionSimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={8}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {levels.map((level, index) => {
              
              return (
                <MotionBox
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  p={6}
                  bg={colorMode === 'light' ? 'white' : 'gray.800'}
                  rounded="xl"
                  shadow="lg"
                  borderWidth="1px"
                  borderColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
                  display="flex"
                  flexDirection="column"
                  height="full"
                >
                  <VStack align="stretch" spacing={6} flex={1}>
                    <Box
                      p={3}
                      bg={colorMode === 'light' ? `${level.color}.50` : `${level.color}.900`}
                      color={colorMode === 'light' ? `${level.color}.500` : `${level.color}.200`}
                      rounded="lg"
                      width="fit-content"
                    >
                      {level.icon}
                    </Box>

                    <VStack align="stretch" spacing={2}>
                      <Heading size="md" color={colorMode === 'light' ? 'gray.800' : 'white'}>
                        {level.title}
                      </Heading>
                      <Text color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                        {level.description}
                      </Text>
                    </VStack>
                    <Button
                      colorScheme={level.color}
                      onClick={() => navigate(`/student-portal/${classId}/learncode/challenges/${concept}?difficulty=${level.difficulty}`)}
                      mt="auto"
                    >
                      {'Start Path'}
                    </Button>
                  </VStack>
                </MotionBox>
              );
            })}
          </MotionSimpleGrid>
        </VStack>
      </Container>
    </Box>
    </>
  );
}