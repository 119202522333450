import React, { useState } from "react";
import { Box, Button, Heading, Image, Spinner } from "@chakra-ui/react";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import {app} from "../../../config"; 
import loginimage from '../../../Assets/loginImage1.png';
import { getAdminByEmail } from "../../../Services/Admin/AdminService.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import giimage from '../../../Assets/G-Ideas.png'
import { LoadingSpinner } from "../../../commons/LoadingSpinner.tsx";

const TLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope("https://www.googleapis.com/auth/drive");
    provider.addScope("https://www.googleapis.com/auth/drive.file");
    provider.addScope("https://www.googleapis.com/auth/documents");

    const auth = getAuth(app);

    try {
      
      const result = await signInWithPopup(auth, provider);
      setLoading(true);
      const loggedInUser = result.user;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      const expirationTimeUTC = new Date(accessToken.expirationTime).toISOString(); 
      localStorage.setItem("AdminTokenExpirationTime", expirationTimeUTC);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const idToken = await loggedInUser.getIdToken();
      localStorage.setItem("idToken", idToken);
      window.dispatchEvent(new Event('storage'));
      const token = credential.accessToken;
      localStorage.setItem("adminGoogleAccessToken", token);
      sessionStorage.setItem("userType","admin");
      sessionStorage.setItem("activeNav",JSON.stringify(0));

      if(loggedInUser){
          try{
            const admin = await getAdminByEmail(loggedInUser.email);
            if(admin.length == 0){
                navigate('/accessdenied');
                setLoading(false);
            }
            else{
            sessionStorage.setItem("CurrentLoggedInAId",admin._id);
            sessionStorage.setItem("adminUserData", JSON.stringify(admin));
            sessionStorage.setItem('isAdmin',true);
          
          const redirectPath = new URLSearchParams(location.search).get("redirect") || '/admin-portal/home';
          navigate(redirectPath);
          setLoading(false);
            }
          }
          catch(error){
            console.log("error occurred", error);
          }
      }

      // Handle successful login and redirect
    } catch (error) {
      console.error("Sign-in Error:", error);
    }
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
      borderRadius="md"
    >
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="md"
        p={8}
        maxWidth="lg"
        width="full"
        textAlign="center"
      >
        <Image
          src={giimage}
          alt="GI Logo"
          boxSize="80px"
          borderRadius="full"
          mx="auto"
          mb={6}
        />
        <Heading as="h1" size="lg" mb={6}>
          Welcome to Generative Ideas
        </Heading>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderWidth="1px"
          borderRadius="md"
          p={4}
          bg="gray.50"
        >
          <Image
            src={loginimage}
            alt="Login Illustration"
            boxSize="200px" // Increased size
            borderRadius="md"
          />
          <Button
            onClick={signInWithGoogle}
            bg="purple.900"
            color="white"
            width="200px"
            _hover={{ bg: "purple.900" }} 
            _focus={{ boxShadow: "none" }} 
          >
            Sign in with Google
            {loading && (
                  <LoadingSpinner/>  
                )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TLogin;
