import { Box, Container, Heading, Text, Button, SimpleGrid, VStack, useColorMode } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileUp, BookOpen } from 'lucide-react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Navbar from './NavBar.tsx';
import { getClassById } from '../../Services/Teacher/teacherService.tsx';

const MotionBox = motion(Box);
const MotionVStack = motion(VStack);
const MotionSimpleGrid = motion(SimpleGrid);

export default function LearnCodeLandingPage() {
  const navigate = useNavigate();
  const { classId } = useParams();
  const { colorMode } = useColorMode();
  const [classDetails, setClassDetails] = useState([]);
  const sId = sessionStorage.getItem("CurrentLoggedInSId").trim();

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const data = await getClassById(classId);
        if(data.students.includes(sId)){
        setClassDetails(data);

        const storedClassName = sessionStorage.getItem(`class_${data._id}`);
        
        if (!storedClassName) {
          sessionStorage.setItem(`class_${data._id}`, data.classname);
        }
        }
      } catch (error) {
        console.error('Error fetching class details:', error);
        navigate('/student-portal/login')
      } 
    };

    fetchClassDetails();
  }, [classId]);

  const paths = [
    {
      icon: <FileUp size={24} />,
      title: 'Assignments',
      description: 'Access assignments section',
      action: () => navigate(`/student-portal/${classId}/learncode/assignments`)
    },
    {
      icon: <BookOpen size={24} />,
      title: 'Web Development Course',
      description: 'Access front end learning path and practical exercises',
      action: () => navigate(`/student-portal/${classId}/learncode/concepts`)
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <>
    <Navbar />
    <Box 
      minH="calc(100vh - 72px)"
      display="flex"
      alignItems="center"
      bg={colorMode === 'light' ? 'white' : 'gray.900'}
    >
      <Container maxW="container.lg">
        <MotionVStack
          spacing={12}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <MotionVStack
            spacing={3}
            textAlign="center"
            variants={itemVariants}
          >
            <Heading
              size="xl"
              color={colorMode === 'light' ? 'gray.800' : 'white'}
            >
              {classDetails?.classname}
            </Heading>
            <Text 
              fontSize="lg" 
              color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
            >
              Programming Assignments & Frontend Learning Path
            </Text>
          </MotionVStack>

          <MotionSimpleGrid 
            columns={{ base: 1, md: 2 }} 
            spacing={8} 
            w="full"
            variants={containerVariants}
          >
            {paths.map((path, index) => (
              <MotionBox
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                p={6}
                bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                rounded="lg"
                borderWidth="1px"
                borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                display="flex"
                flexDirection="column"
                minH="280px"
              >
                <Box
                  p={2}
                  rounded="md"
                  color={colorMode === 'light' ? 'blue.600' : 'blue.300'}
                >
                  {path.icon}
                </Box>
                
                <VStack align="flex-start" spacing={2} flex={1}>
                  <Heading 
                    size="md"
                    color={colorMode === 'light' ? 'gray.800' : 'white'}
                  >
                    {path.title}
                  </Heading>
                  <Text
                    color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                  >
                    {path.description}
                  </Text>
                </VStack>

                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={path.action}
                  size="md"
                  mt={4}
                  w="full"
                >
                  Access
                </Button>
              </MotionBox>
            ))}
          </MotionSimpleGrid>
        </MotionVStack>
      </Container>
    </Box>
    </>
  );
}