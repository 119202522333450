import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormLabel,  HStack, Heading, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, VStack, useToast, useDisclosure, Text, Center, Spinner } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons';
import { addGroup, getProjectsByIds, getStudentsByIds } from '../../../Services/Teacher/teacherService.tsx';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import { useNavigate, useParams } from 'react-router-dom';

interface CreateGroupProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateGroup: React.FC<CreateGroupProps> = ({ isOpen, onClose }) => {
    const [selectedStudents, setSelectedStudents] = useState([]);
    const { isOpen: isAddStudentsOpen, onOpen: onAddStudentsOpen, onClose: onAddStudentsClose } = useDisclosure();
    const [createGroupNameError, setCreateGroupNameError] = useState('');
    const [createGroupGoalError, setCreateGroupGoalError] = useState('');
    const [showSelected,setShowSelected] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupGoal, setGroupGoal] = useState('');
    const [messagingOption, setMessagingOption] = useState('nativeChat');
    const [discordServerId,setDiscordServerId] = useState("");
    const [students, setStudents] = useState([]);
    const toast = useToast();
    const [createDiscordError, setCreateDiscordError] = useState('');
    const [loader, setLoader] = useState(false);
    const [projectDetails, setProjectDetails] = useState([]);
    const projectId = sessionStorage.getItem("currentProjectId");
    const classId = sessionStorage.getItem("currentClassId");
    const navigate = useNavigate();
    const userType = sessionStorage.getItem("userType");

    useEffect(() => {
     
      const fetchProjectDetails = async () => {
        if(projectId && userType == "teacher"){
        try {
          const data = await getProjectsByIds([projectId]);
          setProjectDetails(data[0]);
        } catch (error) {
          console.error('Error fetching project details:', error);
        } 
      }
    }
      fetchProjectDetails();
    }, [projectId]);

    useEffect(() => {
        if (createGroupNameError ) {
          const timer = setTimeout(() => {
            setCreateGroupNameError('');
          }, 2000);
          return () => clearTimeout(timer);
        }
        if (createGroupGoalError ) {
            const timer = setTimeout(() => {
              setCreateGroupGoalError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
          if (createDiscordError ) {
            const timer = setTimeout(() => {
              setCreateDiscordError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
      }, [createGroupNameError,createGroupGoalError, createDiscordError]);

      useEffect(() => {
        if (isAddStudentsOpen === true) {
          fetchStudents(projectDetails.studentIds);
        }
      }, [isAddStudentsOpen]);

      const imageUrls = [
        "https://gstatic.com/classroom/themes/img_breakfast.jpg",
    "https://gstatic.com/classroom/themes/Honors.jpg",
    "https://gstatic.com/classroom/themes/img_graduation.jpg",
    "https://gstatic.com/classroom/themes/img_bookclub.jpg",
    "https://gstatic.com/classroom/themes/img_code.jpg",
    "https://gstatic.com/classroom/themes/img_reachout.jpg",
    "https://gstatic.com/classroom/themes/img_learnlanguage.jpg",
    "https://gstatic.com/classroom/themes/img_backtoschool.jpg",
    "https://gstatic.com/classroom/themes/WorldStudies.jpg",
    "https://gstatic.com/classroom/themes/English.jpg",
    "https://gstatic.com/classroom/themes/WorldHistory.jpg",
    "https://gstatic.com/classroom/themes/SocialStudies.jpg",
    "https://gstatic.com/classroom/themes/Geography.jpg",
    "https://gstatic.com/classroom/themes/USHistory.jpg",
    "https://gstatic.com/classroom/themes/Writing.jpg",
    "https://gstatic.com/classroom/themes/LanguageArts.jpg",
    "https://gstatic.com/classroom/themes/Geometry.jpg",
    "https://gstatic.com/classroom/themes/Psychology.jpg",
    "https://gstatic.com/classroom/themes/Math.jpg",
    "https://gstatic.com/classroom/themes/Chemistry.jpg",
    "https://gstatic.com/classroom/themes/Physics.jpg",
    "https://gstatic.com/classroom/themes/Biology.jpg",
    "https://gstatic.com/classroom/themes/img_coffee.jpg",
    "https://gstatic.com/classroom/themes/img_cinema.jpg",
    "https://gstatic.com/classroom/themes/img_violin2.jpg",
    "https://gstatic.com/classroom/themes/img_arts.jpg",
    "https://gstatic.com/classroom/themes/img_theatreopera.jpg",
    "https://gstatic.com/classroom/themes/img_mealfamily.jpg",
    "https://gstatic.com/classroom/themes/img_learninstrument.jpg",
    "https://gstatic.com/classroom/themes/Design.jpg",
    "https://gstatic.com/classroom/themes/img_concert.jpg",
    "https://gstatic.com/classroom/themes/img_gamenight.jpg",
    "https://gstatic.com/classroom/themes/img_handcraft.jpg",
    "https://gstatic.com/classroom/themes/img_camping.jpg",
    "https://gstatic.com/classroom/themes/img_hiking.jpg",
    "https://gstatic.com/classroom/themes/img_hobby.jpg",
    "https://gstatic.com/classroom/themes/img_sailing.jpg",
    "https://gstatic.com/classroom/themes/img_videogaming.jpg",
    "https://gstatic.com/classroom/themes/img_carmaintenance.jpg",
    
      ];
      
  const getRandomImageUrl = () => {
        const randomIndex = Math.floor(Math.random() * imageUrls.length);
        return imageUrls[randomIndex];
      };
    

  const selectedImageUrl = getRandomImageUrl();

  const handleCreateGroup = async () => {
    setLoader(true);
    const ac = sessionStorage.getItem("DummyClassIndex")
    sessionStorage.setItem("activeClass",ac);
    sessionStorage.setItem("activeNav",null);
    if (!groupName) {
      setCreateGroupNameError('Group Name is required.');
      return;
    }
    if (!groupGoal) {
      setCreateGroupGoalError('Group Description is required.');
      return;
    }
    if(!discordServerId && messagingOption !=="nativeChat"){
        setCreateDiscordError('Discord Server Id is required');
        return;
    }
    const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId").trim();
    let token;
    const userType = sessionStorage.getItem("userType");
    
    if (userType === "student") {
      token = localStorage.getItem("studentGoogleAccessToken");
    } else if (userType === "teacher") {
      token = localStorage.getItem("googleAccessToken");
    }

    const expirationTime = new Date(localStorage.getItem("TokenExpirationTime"));
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 5 * 60 * 1000) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      localStorage.setItem("studentGoogleAccessToken", token);
      localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        localStorage.setItem("googleAccessToken", token);
        localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    
    const requestData = {
      groupName,
      groupDescription: groupGoal,
      studentIds: selectedStudents,
      teacherIds: [currentLoggedInTId],
      nativeChat: messagingOption === 'nativeChat',
      discordServerId: messagingOption === 'discord' ? discordServerId : null,
      url: selectedImageUrl,
      projectId : projectDetails._id,
      token: token,
    };
  
    const storedSidebarData = sessionStorage.getItem("sideBarData");
    try {
      const groupSaved = await addGroup(requestData);
      setLoader(false);


      toast({
        description: 'group Created successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
      let sideBarData = JSON.parse(storedSidebarData);

              const newGroup = {
                _id: groupSaved._id,
                groupName: groupSaved.groupName,
              };
              const classIndex = sideBarData?.classes.findIndex(cls => cls._id === classId);
              const projectIndex = sideBarData?.classes[classIndex]?.projects.findIndex(prj => prj._id === projectDetails._id);
              sideBarData?.classes[classIndex]?.projects[projectIndex]?.groups.push(newGroup);
              sessionStorage.setItem("sideBarData", JSON.stringify(sideBarData));
      sessionStorage.setItem("activeTabInClass","1");
      const path = `teacher-portal/class/${classId}/project/${projectDetails._id}/group/${groupSaved._id}`;
      navigate(path);
    } catch (error) {
      toast({
        description: 'Failed to create group.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      console.error('Error creating group:', error);
    }
  };
  

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };


      const fetchStudents = async (studentIds) => {
        try {
          const data = await getStudentsByIds(studentIds);
          setStudents(data);
        } catch (error) {
          console.error('Error fetching students:', error);
        } finally {
        }
      };

    return(

        <>
      <Modal isOpen={isOpen} onClose={() => { setSelectedStudents([]); onClose(); }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {loader ? (
              <Center py={10}>
                <LoadingSpinner />
              </Center>
            ) : ( 
              <>
          <FormControl mb={4} isInvalid={!!createGroupNameError}>
            <FormLabel>Group Name</FormLabel>
            <Input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              isRequired
            />
          </FormControl>
          <FormControl mb={4} isInvalid={!!createGroupGoalError}>
            <FormLabel>Group Description</FormLabel>
            <Input
              type="text"
              value={groupGoal}
              onChange={(e) => setGroupGoal(e.target.value)}
              isRequired
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Messaging Option</FormLabel>
            <Select
              value={messagingOption}
              onChange={(e) => setMessagingOption(e.target.value)}
            >
              <option value="nativeChat">Native Chat</option>
              <option value="discord">Discord</option>
            </Select>
          </FormControl>
    
    
          {/* Conditionally render the Discord server ID input */}
          {messagingOption === 'discord' && (
            <FormControl mb={4} isInvalid={!!createDiscordError}>
              <FormLabel>Discord Server ID</FormLabel>
              <Input
                type="text"
                value={discordServerId}
                onChange={(e) => setDiscordServerId(e.target.value)}
                placeholder="Enter Discord Server ID"
              />
            </FormControl>
          )}
    
          {/* Display selected students */}
    
          {selectedStudents.length > 0 && showSelected && (
            <Box
              p={4}
              mt={4}
              mb={4}
              borderWidth="1px"
              borderRadius="md"
              bg="gray.50"
              position="relative"  
            >
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={() => setSelectedStudents([])}
              />
              <Heading size="sm" mb={2}>Selected Students:</Heading>
              {students
                .filter((student) => selectedStudents.includes(student._id))
                .map((student) => (
                  <Text key={student._id}>{student.email}</Text>
                ))}
            </Box>
          )}
    
          {/* Conditionally render the "Add students" button */}
          {selectedStudents.length === 0 && (
            <Button
              bg="purple.900"
              color="white"
              _hover={{ bg: 'purple.800' }}
              onClick={onAddStudentsOpen}
            >
              Add students
            </Button>
          )}
          </> 
          )
}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="purple.900"
            color="white"
            _hover={{ bg: 'purple.800' }}
            onClick={handleCreateGroup}
          >
            Create
          </Button>
          <Button
            marginLeft={2}
            bg="gray.100"
            color="purple.900"
            _hover={{ bg: 'gray.300' }}
            onClick={() => { setSelectedStudents([]); onClose(); }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

        <Modal isOpen={isAddStudentsOpen} onClose={() => {setSelectedStudents([]); onAddStudentsClose(); }} size="lg">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Students to Group</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormControl mb={4}>
                <FormLabel>Select Students</FormLabel>
                <VStack align="start" spacing={4}>
                {students.map(student => (
                    <HStack key={student._id} spacing={4}>
                    <Checkbox
                        isChecked={selectedStudents.includes(student._id)}
                        onChange={() => handleStudentSelection(student._id)}
                    >
                        {student.email}
                    </Checkbox>
                    </HStack>
                ))}
                </VStack>
            </FormControl>
            </ModalBody>
            <ModalFooter>
            <Button
                bg="purple.900"
                color="white"
                _hover={{ bg: 'purple.800' }}
                onClick={() => {
                // Handle adding selected students to project
                toast({
                    description: 'Students added to group successfully.',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                onAddStudentsClose(); setShowSelected(true);
                }}
            >
                Add Students
            </Button>
            <Button marginLeft={2} bg="gray.100" color="purple.900" _hover={{ bg: 'gray.300' }} onClick={onAddStudentsClose}>
                  Cancel
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
        </>
    );
}

export default CreateGroup;