import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import QuestionsTable from "./QuestionHistory.jsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
      <Box width='full' >
        <Tabs index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="2">
            <Tab>Question History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <QuestionsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default Message;
