import React, { useState, useEffect, useRef } from 'react';
import { getQuestionsFromGPT, getTopicsFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import {getQuestionHistory, postQuestion} from '../../../Services/Student/studentService.tsx';
import { 
  Radio, RadioGroup, Stack, HStack, Button, Text, Box, Heading, Flex,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, VStack,  ListItem, UnorderedList, IconButton, useDisclosure,
} from '@chakra-ui/react';
import {  FaHome } from 'react-icons/fa';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import * as pdfjsLib from 'pdfjs-dist';
import html2pdf from 'html2pdf.js';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import { keyframes } from '@emotion/react';
const isProduction = process.env.NODE_ENV === 'production';

pdfjsLib.GlobalWorkerOptions.workerSrc = isProduction
  ? "https://generativeideas.org/pdf.worker.min.mjs"
  : "/pdf.worker.min.mjs";

  

  const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;
  
  const slideIn = keyframes`
    from { opacity: 0; transform: translateX(-10px); }
    to { opacity: 1; transform: translateX(0); }
  `;
  
  const slideUp = keyframes`
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  `;
  
  const pulse = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.8; }
    100% { opacity: 1; }
  `;

const LearnQ = ({setActiveTab, fileText, currentTId, setFileText, classId, assignmentId, setAssignmentId}) => {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [difficulty, setDifficulty] = useState(1);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);  
  const [fileData, setFileData] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopic,setSelectedTopic] = useState("");
  const [questionHistory, setQuestionHistory] = useState({});
  const [questionCountForTopic, setQuestionCountForTopic] = useState({});
  const [currentTopicName, setCurrentTopicName] = useState(topics[0] || ""); 
  const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();
  const [currentTeacherId, setCurrentTeacherId] = useState("");

  const [sidebarWidth, setSidebarWidth] = useState(30); // Default width percentage
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(30);
  const containerRef = useRef(null);
  const resizeHandleRef = useRef(null);

  const boxRef = useRef(null);
  const resultRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.clientX);
    setStartWidth(sidebarWidth);
    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging || !containerRef.current) return;
      
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      
      const deltaX = e.clientX - startX;
      const deltaPercentage = (deltaX / containerWidth) * 100;
      
      let newWidth = startWidth + deltaPercentage;
      
      newWidth = Math.max(20, Math.min(60, newWidth)); 
      
      setSidebarWidth(newWidth);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      
      document.body.style.cursor = '';
      document.body.style.userSelect = '';
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX, startWidth]);


  useEffect(() => {
    setCurrentTopicName(topics[0] || ""); 
}, [topics]);


  const fetchQuestion = async (subject, topic, difficulty, updatedHistory) => {
    setLoading(true);
    try {
        let response;
        if(updatedHistory)
      response = await getQuestionsFromGPT(subject, topic, difficulty, classId, updatedHistory[topic]);
    else
        response = await getQuestionsFromGPT(subject, topic, difficulty, classId);
      let trimmedResponse = response;
      if (response.startsWith("```")) {
        trimmedResponse = response.replace(/^```json|```$/g, "");
    }
      const { question, options, correct_answer } = JSON.parse(trimmedResponse);
      setCurrentQuestion({ question, options, correct_answer });
      setSelectedOption(null);
      setShowAnswer(false);
    } catch (error) {
        setSelectedOption(null);
      console.error("Error fetching question:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopics = async (subject) => {
    setLoading(true);
    let response;
    let firstTopic = null; 
    try {
        response = await getTopicsFromGPT(subject,currentTeacherId || currentTId, currentLoggedInSId, classId || "", assignmentId || "");
        let trimmedResponse = response;
        
        if (response.startsWith("```")) {
            trimmedResponse = response.replace(/^```json|```$/g, "");
        }
        
        const parsedResponse = JSON.parse(trimmedResponse);
        const { topics } = parsedResponse; 
        setTopics(topics); 
        setSelectedTopic(topics[0]);
        firstTopic = topics[0] || null; 
    } catch (error) {
        setSelectedOption(null);
        console.error("Error fetching topics:", error);
    } finally {
        setLoading(false);
    }
    return firstTopic; 
};


  useEffect(() => {
    const fetchData = async () => {
      if (fileText) {
        let questions;
        let questionCount;
        try {
          const firstTopic = await fetchTopics(fileText);
          if (assignmentId && assignmentId !== "") {
            try {
              const response = await getQuestionHistory(currentLoggedInSId, classId, assignmentId, firstTopic);
              const { questions: fetchedQuestions, questionCount: fetchedQuestionCount } = response;
              questions = fetchedQuestions; 
              questionCount = fetchedQuestionCount;  
            } catch (error) {
              console.error('Error fetching question history:', error);
            }
          }
        
          setQuestionCountForTopic(prevCounts => ({
            ...prevCounts,
            [firstTopic]: questionCount
          }));
          await fetchQuestion(fileText, firstTopic, 1,questions);
        } catch (error) {
          console.error('Error fetching topics or questions:', error);
          
        }
      }
    };
  
    fetchData();
  }, [fileText,assignmentId,classId,currentLoggedInSId]);


  const handleAnswer = async () => {
    if (!currentQuestion) return;
    const trimmedSelected = selectedOption.trim();
    const trimmedCorrect = currentQuestion.correct_answer.trim();
    const selectedPrefix = trimmedSelected.slice(0, 2);
    const isCorrect = trimmedCorrect.includes(selectedPrefix);
    setShowAnswer(true);

    setTimeout(() => {
      if (resultRef.current) {
        resultRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'start'
        });
      }
    }, 100);
  
    let updatedHistory;
    setQuestionHistory(prevHistory => {
      updatedHistory = { ...prevHistory };
    
      if (!updatedHistory[selectedTopic]) {
        updatedHistory[selectedTopic] = [];
      } else {
        updatedHistory[selectedTopic] = [...updatedHistory[selectedTopic]];
      }
  
      updatedHistory[selectedTopic].push({
        question: currentQuestion.question,
        options: currentQuestion.options,
        selectedOption,
        correctAnswer: currentQuestion.correct_answer,
        isCorrect,
      });
      
  
      return updatedHistory;
    });
    setQuestionCountForTopic(prevCounts => ({
      ...prevCounts,
      [selectedTopic]: (prevCounts[selectedTopic] || 0) + 1,  
  }));

    const questionData = {
        ...currentQuestion,
        selectedOption,
        isCorrect,
        topic: selectedTopic,  
      };
      if(classId!=="" && assignmentId!=="")
        await postQuestion(questionData, currentLoggedInSId, classId, assignmentId);
    
  
    if (isCorrect) {
      setScore(score + 1);
      setDifficulty(difficulty + 1);
    } else {
      setDifficulty(Math.max(1, difficulty - 1));
    }
  
    setTimeout(async () => {
      if (fileText) {
        await fetchQuestion(fileText, selectedTopic, difficulty + 1, updatedHistory);
      } else if (fileData) {
        await fetchQuestion(fileData, selectedTopic, difficulty + 1, updatedHistory);
      } 
    }, 3000);
  };

  
  const handleEndQuiz = () => setShowEndModal(true);

  const handleConfirmEndQuiz = () => {
    setQuizEnded(true);
    setSelectedTopic("");
    setShowEndModal(false);
  };

  const handleBack = () => {
    setQuizEnded(false);
    setSelectedTopic(topics[0]);
  }

  
  const handleHome = () => {
    setCurrentQuestion(null);
    setQuizEnded(false);
    setDifficulty(1);
    setScore(0);
    setFileText(null);
    setAssignmentId("");
    setFileData("");
    setQuestionHistory({});
    setSelectedTopic("");
    setTopics([]);
    setActiveTab(3);
  }


  const renderContent = (content) => {
    if (!content) return null;
    const codeBlockRegex = /```([a-zA-Z0-9]*)\n?([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;
  
    while ((match = codeBlockRegex.exec(content)) !== null) {
      const [fullMatch, language, code] = match;
      const startIndex = match.index;
      if (startIndex > lastIndex) {
        parts.push(
          <Text key={`text-${lastIndex}`} display="inline">
            {content.slice(lastIndex, startIndex)}
          </Text>
        );
      }
      parts.push(
        <SyntaxHighlighter
          key={`code-${startIndex}`}
          language={language?.trim() || 'plaintext'} 
          style={materialDark}
          wrapLongLines
        >
          {code.trim()}
        </SyntaxHighlighter>
      );
  
      lastIndex = codeBlockRegex.lastIndex;
    }
    if (lastIndex < content.length) {
      parts.push(
        <Text key={`text-${lastIndex}`} display="inline">
          {content.slice(lastIndex)}
        </Text>
      );
    }
  
    return parts;
  };



  const handleTopicClick = async (topic, index) => {
    setSelectedTopic(topic);
    
    let questions = []; 
    let questionCount = 0;  
  
    if (assignmentId && assignmentId !== "") {
      try {
        const response = await getQuestionHistory(currentLoggedInSId, classId, assignmentId, topic);
        const { questions: fetchedQuestions, questionCount: fetchedQuestionCount } = response;
        questions = fetchedQuestions; 
        questionCount = fetchedQuestionCount;  
      } catch (error) {
        console.error('Error fetching question history:', error);
      }
    }
  
    setCurrentTopicName(topic);
    setQuestionCountForTopic(prevCounts => ({
      ...prevCounts,
      [topic]: questionCount
    }));
  
    fetchQuestion(fileText, topic, 1, questions);
  };  

  const generatePDF = () => {
    const element = document.getElementById("quiz-summary");
    const options = {
        margin: 0.5,
        filename: 'quiz_summary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { 
            unit: 'in', 
            format: [10, 11], 
            orientation: 'portrait' 
        }
    };

    html2pdf().from(element).set(options).save();
};

const fadeInAnimation = `${fadeIn} 0.5s ease-in-out`;
const slideInAnimation = `${slideIn} 0.5s ease-in-out`;
const pulseAnimation = `${pulse} 2s infinite ease-in-out`;

return (
  <Box 
    w="full" 
    h="84vh" 
    p={4} 
    borderRadius="xl" 
    bg="white" 
    boxShadow="0 4px 20px rgba(0,0,0,0.08)"
  >
    <Flex 
      h="100%" 
      ref={containerRef}
      position="relative"
      overflow="hidden"
    >
      {/* Left Sidebar */}
      {(fileData || fileText) && (
        <Box 
          w={`${sidebarWidth}%`} 
          h="100%" 
          p={4} 
          boxShadow="0 0 15px rgba(0,0,0,0.05)"
          overflowY="auto" 
          overflowX="hidden" 
          css={{
            '&::-webkit-scrollbar': {
                display: 'none', 
            },
            '-ms-overflow-style': 'none', 
            'scrollbar-width': 'none', 
        }}
          bg="white"
          transition="width 0.2s ease"
          minW="200px"
          borderRight="1px solid"
          borderColor="gray.100"
        >
          <VStack align="start" spacing={6}>
            <Text 
              fontSize="lg" 
              fontWeight="bold" 
              color="purple.900" 
              borderBottom="2px solid" 
              borderColor="purple.900" 
              pb={2} 
              w="full"
              sx={{
                animation: fadeInAnimation
              }}
            >
              Topics
            </Text>
            <UnorderedList spacing={2} styleType="none" w="full" ml={0}>
              {topics.map((topicName, index) => (
                <ListItem 
                  key={index} 
                  fontSize="md" 
                  w="full"
                  sx={{
                    animation: `${slideIn} 0.3s ease-in-out ${index * 0.1}s both`
                  }}
                >
                  <Box 
                    as="button"
                    onClick={() => handleTopicClick(topicName)} 
                    width="100%" 
                    textAlign="left" 
                    p={3} 
                    borderRadius="md" 
                    bg={currentTopicName === topicName ? 'gray.100' : 'white'} 
                    color={currentTopicName === topicName ? 'purple.900' : 'gray.700'}
                    fontWeight={currentTopicName === topicName ? 'medium' : 'normal'}
                    boxShadow={currentTopicName === topicName ? '0 2px 8px rgba(0,0,0,0.08)' : 'none'}
                    cursor="pointer" 
                    transition="all 0.2s ease-in-out"
                    _hover={{ bg: currentTopicName === topicName ? 'gray.100' : 'gray.50', transform: 'translateX(4px)' }}
                  >
                    {topicName}
                    {questionCountForTopic[topicName] > 0 && (
                      <Box 
                        as="span" 
                        ml={2} 
                        display="inline-flex" 
                        alignItems="center" 
                        justifyContent="center" 
                        px={2} 
                        py={1} 
                        fontSize="xs" 
                        fontWeight="bold" 
                        lineHeight="none" 
                        color="purple.900" 
                        bg="gray.200" 
                        borderRadius="full"
                      >
                        {questionCountForTopic[topicName] || 0}
                      </Box>
                    )}
                  </Box>
                </ListItem>
              ))}
            </UnorderedList>
          </VStack>
        </Box>
      )}

      {/* Resize Handle */}
      {(fileData || fileText) && (
        <Box
          position="absolute"
          top="0"
          left={`${sidebarWidth}%`}
          width="1px"
          height="100%"
          bg={isDragging ? "purple.900" : "gray.200"}
          cursor="col-resize"
          zIndex="10"
          onMouseDown={handleMouseDown}
          ref={resizeHandleRef}
          _hover={{ bg: "purple.900" }}
          transition="background 0.2s, left 0.2s ease"
        />
      )}

      {/* Right Quiz Content */}
      <Box 
        w={`${100 - sidebarWidth}%`}
        h="100%" 
        p={6}  
        boxShadow="0 0 15px rgba(0,0,0,0.05)"
        overflowY="auto" 
        overflowX="hidden" 
        css={{
          '&::-webkit-scrollbar': {
              display: 'none', 
          },
          '-ms-overflow-style': 'none', 
          'scrollbar-width': 'none', 
      }}
        bg="white"
        transition="width 0.2s ease"
        minW="300px"
      >
        <Flex 
          justifyContent="space-between" 
          alignItems="center" 
          mb={6}
          sx={{
            animation: fadeInAnimation
          }}
        >
          <Box 
            as={FaHome}
            size={24} 
            cursor="pointer" 
            color="purple.900"
            _hover={{ color: "purple.700", transform: "scale(1.1)" }}
            transition="all 0.2s"
            onClick={handleHome} 
          />
          
          <Heading 
            size="lg" 
            color="purple.900" 
            textAlign="center"
            sx={{
              animation: pulseAnimation
            }}
          >
            Learn with AI
          </Heading>
          
          <Box w="24px"></Box> 
        </Flex>

        {loading ? (
          <Box 
            textAlign="center" 
            mt={20}
            sx={{
              animation: fadeInAnimation
            }}
          >
            <LoadingSpinner />
          </Box>
        ) : !fileText ? (
          <Box
            sx={{
              animation: fadeInAnimation
            }}
          >
            <HStack mt={6} mb={4} justifyContent="center" spacing={8}>
              <Text color="purple.900">Start by selecting an assignment in the assignment tab.</Text>
            </HStack>
          </Box>
        ) : (
          <Box 
            mt={4}
            sx={{
              animation: fadeInAnimation
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
              {quizEnded && (
                <>
                  <Box>
                    <IconButton
                      aria-label="Go back"
                      icon={<ArrowBackIcon />}
                      onClick={handleBack}
                      bg="purple.900"
                      color="white"
                      _hover={{ bg: "purple.700", transform: "scale(1.05)" }}
                      size="sm"
                      transition="all 0.2s"
                    />
                  </Box>
                  <Button 
                    onClick={generatePDF}
                    bg="purple.900"
                    color="white"
                    _hover={{ bg: "purple.700", transform: "translateY(-2px)" }}
                    transition="all 0.2s"
                    boxShadow="0 4px 10px rgba(0,0,0,0.1)"
                  >
                    Generate PDF
                  </Button>
                </>
              )}
            </Flex>

            {!currentQuestion ? (
              <Box 
                textAlign="center" 
                p={8} 
                bg="gray.50" 
                borderRadius="lg" 
                boxShadow="0 4px 12px rgba(0,0,0,0.05)"
                sx={{
                  animation: fadeInAnimation
                }}
              >
                <Text color="purple.900" fontSize="lg">No more questions available.</Text>
              </Box>
            ) : quizEnded ? (
              <Box 
                id="quiz-summary" 
              >
                <Heading as="h2" size="lg" mb={6} color="purple.900" borderBottom="2px solid" borderColor="gray.200" pb={2}>Quiz Summary</Heading>
                {Object.entries(questionHistory).map(([topicTitle, questions], topicIndex) => (
                  <Box 
                    key={topicIndex} 
                    mb={8}
                  >
                    <Heading as="h3" size="md" mb={4} color="purple.900" bg="gray.50" p={2} borderRadius="md">{topicTitle}</Heading>
                    {questions.map((item, questionIndex) => (
                      <Box 
                        key={questionIndex} 
                        mb={6} 
                        p={5} 
                        border="1px solid" 
                        borderColor="gray.200" 
                        borderRadius="lg" 
                        boxShadow="0 2px 8px rgba(0,0,0,0.05)" 
                        _hover={{ boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }} 
                        transition="all 0.3s ease" 
                        bg="white"
                      >
                        <Text fontSize="lg" mb={3} fontWeight="medium">
                          <Box as="span" color="purple.900">Question {questionIndex + 1}:</Box> {renderContent(item.question)}
                        </Text>
                        <Box mb={3} pl={4} borderLeft="2px solid" borderColor="gray.200">
                          <Text mb={2} fontWeight="medium" color="purple.900">Options:</Text>
                          {item.options.map((option, index) => (
                            <Text key={index} mb={1} pl={2}>{renderContent(option)}</Text>
                          ))}
                        </Box>
                        <Text mb={2} fontWeight="medium" color={item.isCorrect ? 'green.600' : 'red.600'}>
                          Your answer: {renderContent(item.selectedOption)} {item.isCorrect ? "✓" : "✗"}
                        </Text>
                        {!item.isCorrect && (
                          <Text color="purple.900" fontWeight="medium">Correct answer: {renderContent(item.correctAnswer)}</Text>
                        )}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box 
                ref={boxRef} 
                bg="white" 
                p={6} 
                sx={{
                  animation: fadeInAnimation
                }}
              >
                <Text 
                  fontSize="lg" 
                  mb={6} 
                  fontWeight="medium" 
                  color="purple.900"
                  sx={{
                    animation: slideInAnimation
                  }}
                >
                  {renderContent(currentQuestion.question)}
                </Text>
                <RadioGroup onChange={setSelectedOption} value={selectedOption}>
                  <Stack spacing={4}>
                    {currentQuestion.options.map((option, idx) => (
                      <Box 
                        key={idx} 
                        p={3} 
                        border="1px solid" 
                        borderColor={selectedOption === option ? "purple.900" : "gray.200"} 
                        borderRadius="lg" 
                        bg={selectedOption === option ? "gray.100" : "white"}
                        cursor="pointer"
                        transition="all 0.2s"
                        _hover={{ bg: "gray.50", transform: "translateY(-2px)", boxShadow: "0 4px 12px rgba(0,0,0,0.05)" }}
                        onClick={() => setSelectedOption(option)}
                        sx={{
                          animation: `${slideUp} 0.3s ease-in-out ${idx * 0.1 + 0.2}s both`
                        }}
                      >
                        <Radio 
                          value={option} 
                          colorScheme="purple"
                          cursor="pointer"
                        >
                          {renderContent(option)}
                        </Radio>
                      </Box>
                    ))}
                  </Stack>
                </RadioGroup>
                <HStack 
                  justify="space-between" 
                  mt={8}
                  sx={{
                    animation: `${fadeIn} 0.5s ease-in-out 0.5s both`
                  }}
                >
                  <Button 
                    bg="purple.900" 
                    color="white"
                    onClick={handleAnswer}
                    isDisabled={!selectedOption} 
                    _hover={{ bg: "purple.900", transform: "translateY(-2px)" }}
                    _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
                    transition="all 0.2s"
                    boxShadow="0 4px 10px rgba(0,0,0,0.1)"
                    _active={{ boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}
                  >
                    Submit
                  </Button>
                  <Button 
                    onClick={handleEndQuiz} 
                    variant="outline"
                    borderWidth="2px"
                    borderColor="purple.900"
                    color="purple.900"
                    _hover={{ bg: "gray.50", transform: "translateY(-2px)" }}
                    transition="all 0.2s"
                  >
                    End Quiz
                  </Button>
                </HStack>

                {showAnswer && (
                  <Box 
                    ref={resultRef} 
                    mt={8} 
                    p={4} 
                    borderRadius="lg" 
                    border="1px solid"
                    borderColor={selectedOption === currentQuestion.correct_answer ? "green.500" : "red.500"}
                    bg={selectedOption === currentQuestion.correct_answer ? "green.50" : "red.50"}
                    boxShadow="0 4px 12px rgba(0,0,0,0.05)"
                    sx={{
                      animation: `${slideUp} 0.5s ease-in-out`
                    }}
                  >
                    <Text 
                      fontSize="lg" 
                      fontWeight="medium" 
                      color={selectedOption === currentQuestion.correct_answer ? "green.600" : "red.600"}
                    >
                      {selectedOption === currentQuestion.correct_answer ? "✓ Correct Answer!" : "✗ Wrong Answer!"}
                    </Text>

                    {selectedOption !== currentQuestion.correct_answer && (
                      <Text mt={2} color="purple.900" fontSize="lg">
                        Correct answer is: <Box as="span" fontWeight="medium">{renderContent(currentQuestion.correct_answer)}</Box>
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Flex>

    {/* End Quiz Confirmation Modal */}
    <Modal isOpen={showEndModal} onClose={() => setShowEndModal(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="purple.900">End Quiz</ModalHeader>
        <ModalCloseButton onClick={() => setShowEndModal(false)} /> 
        <ModalBody py={4}>
          <Text color="gray.700">Are you sure you want to end the quiz?</Text>
        </ModalBody>
        <ModalFooter>
          <Button 
            variant="outline" 
            color="purple.900" 
            borderColor="purple.900" 
            mr={3} 
            _hover={{ bg: "gray.50" }}
            onClick={() => setShowEndModal(false)} 
          >
            No
          </Button>
          <Button 
            bg="purple.900" 
            color="white" 
            _hover={{ bg: "purple.700" }} 
            onClick={handleConfirmEndQuiz}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

  </Box>
);
}
export default LearnQ;  