import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormLabel,  HStack, Heading, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, VStack, useToast, useDisclosure, Text, Spinner } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons';
import { addProject, getClassById, getStudentsByIds } from '../../../Services/Teacher/teacherService.tsx';
import { useDropzone } from 'react-dropzone';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import { useNavigate, useParams } from 'react-router-dom';

interface CreateProjectProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProject: React.FC<CreateProjectProps> = ({ isOpen, onClose }) => {
    const [selectedStudents, setSelectedStudents] = useState([]);
    const { isOpen: isAddStudentsOpen, onOpen: onAddStudentsOpen, onClose: onAddStudentsClose } = useDisclosure();
    const [createProjectNameError, setCreateProjectNameError] = useState('');
    const [createProjectGoalError, setCreateProjectGoalError] = useState('');
    const [createProjectDescriptionError, setCreateProjectDescriptionError] = useState('');
    const [createProjectBrandingError, setCreateProjectBrandingError] = useState('');
    const [createDiscordError, setCreateDiscordError] = useState('');
    const [showSelected,setShowSelected] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectGoal, setProjectGoal] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectBranding, setProjectBranding] = useState('');
    const [messagingOption, setMessagingOption] = useState('nativeChat');
    const [discordServerId,setDiscordServerId] = useState("");
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [classDetails, setClassDetails] = useState([]);
    const classId = sessionStorage.getItem("currentClassId");

    const [uploadFile, setUploadFile] = useState(false);
  const [projectFile, setProjectFile] = useState(null);

  const [uploadBFile, setUploadBFile] = useState(false);
  const [projectBFile, setProjectBFile] = useState(null);
  const navigate = useNavigate();

  const {
    getRootProps: getRootPropsA,
    getInputProps: getInputPropsA,
  } = useDropzone({
    onDrop: acceptedFiles => {
      setProjectFile(acceptedFiles[0]);
    },
  });
  const {
    getRootProps: getRootPropsB,
    getInputProps: getInputPropsB,
  } = useDropzone({
    onDrop: acceptedFiles => {
      setProjectBFile(acceptedFiles[0]);
    },
  });

  const userType = sessionStorage.getItem("userType");

  useEffect(() => {
    const fetchClassDetails = async () => {
      if(classId && userType === "teacher"){
      try {
        const data = await getClassById(classId);
        setClassDetails(data);
      } catch (error) {
        console.error('Error fetching class details:', error)
      } 
    }}

    fetchClassDetails();
  }, [classId]);

    useEffect(() => {
        if (createProjectNameError ) {
          const timer = setTimeout(() => {
            setCreateProjectNameError('');
          }, 2000);
          return () => clearTimeout(timer);
        }
        if (createProjectGoalError ) {
            const timer = setTimeout(() => {
              setCreateProjectGoalError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
          if (createProjectDescriptionError ) {
            const timer = setTimeout(() => {
              setCreateProjectDescriptionError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
          if (createProjectBrandingError ) {
            const timer = setTimeout(() => {
              setCreateProjectBrandingError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
          if (createDiscordError ) {
            const timer = setTimeout(() => {
              setCreateDiscordError('');
            }, 2000);
            return () => clearTimeout(timer);
          }
      }, [createProjectNameError,createProjectGoalError,createDiscordError, createProjectDescriptionError,createProjectBrandingError]);

      useEffect(() => {
        if (isAddStudentsOpen === true) {
          fetchStudents(classDetails.students);
        }
      }, [isAddStudentsOpen]);

      const imageUrls = [
        "https://gstatic.com/classroom/themes/img_breakfast.jpg",
    "https://gstatic.com/classroom/themes/Honors.jpg",
    "https://gstatic.com/classroom/themes/img_graduation.jpg",
    "https://gstatic.com/classroom/themes/img_bookclub.jpg",
    "https://gstatic.com/classroom/themes/img_code.jpg",
    "https://gstatic.com/classroom/themes/img_reachout.jpg",
    "https://gstatic.com/classroom/themes/img_learnlanguage.jpg",
    "https://gstatic.com/classroom/themes/img_backtoschool.jpg",
    "https://gstatic.com/classroom/themes/WorldStudies.jpg",
    "https://gstatic.com/classroom/themes/English.jpg",
    "https://gstatic.com/classroom/themes/WorldHistory.jpg",
    "https://gstatic.com/classroom/themes/SocialStudies.jpg",
    "https://gstatic.com/classroom/themes/Geography.jpg",
    "https://gstatic.com/classroom/themes/USHistory.jpg",
    "https://gstatic.com/classroom/themes/Writing.jpg",
    "https://gstatic.com/classroom/themes/LanguageArts.jpg",
    "https://gstatic.com/classroom/themes/Geometry.jpg",
    "https://gstatic.com/classroom/themes/Psychology.jpg",
    "https://gstatic.com/classroom/themes/Math.jpg",
    "https://gstatic.com/classroom/themes/Chemistry.jpg",
    "https://gstatic.com/classroom/themes/Physics.jpg",
    "https://gstatic.com/classroom/themes/Biology.jpg",
    "https://gstatic.com/classroom/themes/img_coffee.jpg",
    "https://gstatic.com/classroom/themes/img_cinema.jpg",
    "https://gstatic.com/classroom/themes/img_violin2.jpg",
    "https://gstatic.com/classroom/themes/img_arts.jpg",
    "https://gstatic.com/classroom/themes/img_theatreopera.jpg",
    "https://gstatic.com/classroom/themes/img_mealfamily.jpg",
    "https://gstatic.com/classroom/themes/img_learninstrument.jpg",
    "https://gstatic.com/classroom/themes/Design.jpg",
    "https://gstatic.com/classroom/themes/img_concert.jpg",
    "https://gstatic.com/classroom/themes/img_gamenight.jpg",
    "https://gstatic.com/classroom/themes/img_handcraft.jpg",
    "https://gstatic.com/classroom/themes/img_camping.jpg",
    "https://gstatic.com/classroom/themes/img_hiking.jpg",
    "https://gstatic.com/classroom/themes/img_hobby.jpg",
    "https://gstatic.com/classroom/themes/img_sailing.jpg",
    "https://gstatic.com/classroom/themes/img_videogaming.jpg",
    "https://gstatic.com/classroom/themes/img_carmaintenance.jpg",
    
      ];
      
  const getRandomImageUrl = () => {
        const randomIndex = Math.floor(Math.random() * imageUrls.length);
        return imageUrls[randomIndex];
      };
    

  const selectedImageUrl = getRandomImageUrl();

  
  const handleCreateProject = async () => {
    const ac = sessionStorage.getItem("DummyClassIndex");
    sessionStorage.setItem("activeClass", ac);
    sessionStorage.setItem("activeNav", null);
  
    if (!projectName) {
      setCreateProjectNameError('Project Name is required.');
      return;
    }
    if (!projectGoal ) {
      setCreateProjectGoalError('Project Goal is required.');
      return;
    }
    if (!projectDescription && !uploadFile) {
      setCreateProjectGoalError('Project Description is required.');
      return;
    }
    if (!projectBranding && !uploadBFile) {
      setCreateProjectBrandingError('Project Planning is required.');
      return;
    }

    if (!discordServerId && messagingOption !== "nativeChat") {
      setCreateDiscordError('Discord Server Id is required');
      return;
    }
    setLoading(true); 

    const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId").trim();
    let token;
    const userType = sessionStorage.getItem("userType");
    
    if (userType === "student") {
      token = localStorage.getItem("studentGoogleAccessToken");
    } else if (userType === "teacher") {
      token = localStorage.getItem("googleAccessToken");
    }

    const expirationTime = new Date(localStorage.getItem("TokenExpirationTime"));
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 5 * 60 * 1000) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      localStorage.setItem("studentGoogleAccessToken", token);
      localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        localStorage.setItem("googleAccessToken", token);
        localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    
  
    // Create FormData object
    const formData = new FormData();
    formData.append("projectName", projectName);
    formData.append("projectGoal", projectGoal);
    formData.append("projectDescription", projectDescription);
    formData.append("projectBranding", projectBranding);
    formData.append("studentIds", JSON.stringify(selectedStudents));
    formData.append("teacherIds", JSON.stringify([currentLoggedInTId]));
    formData.append("nativeChat", messagingOption === 'nativeChat');
    formData.append("discordServerId", messagingOption === 'discord' ? discordServerId : null);
    formData.append("url", selectedImageUrl);
    formData.append("classId", classDetails._id);
    formData.append("token", token);
  
    // Append the project file if it exists
    if (projectFile) {
      formData.append("projectFile", projectFile);
    }
    if(projectBFile){
      formData.append("projectBFile", projectBFile);
    }

    const storedSidebarData = sessionStorage.getItem("sideBarData");
  
    try {
      const projectSaved = await addProject(formData);
  
      toast({
        description: 'Project Created successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      let sideBarData = JSON.parse(storedSidebarData);

              const newProject = {
                _id: projectSaved._id,
                projectName: projectSaved.projectName,
                groups: [],
              };
              const classIndex = sideBarData?.classes.findIndex(cls => cls._id === classDetails._id);
              sideBarData?.classes[classIndex]?.projects.push(newProject);
              sessionStorage.setItem("sideBarData", JSON.stringify(sideBarData));

      sessionStorage.setItem("activeTabInClass", "1");
      const path = `teacher-portal/class/${classId}/project/${projectSaved._id}`;
      navigate(path);
    } catch (error) {
      toast({
        description: 'Failed to create project.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      console.error('Error creating project:', error);
    }finally {
      setLoading(false); // Stop loading
      onClose();
  }
  };
  
  

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };


      const fetchStudents = async (studentIds) => {
        try {
          const data = await getStudentsByIds(studentIds);
          setStudents(data);
        } catch (error) {
          console.error('Error fetching students:', error);
        } finally {
        }
      };

    return(

        <>
      <Modal isOpen={isOpen} onClose={() => { setSelectedStudents([]); onClose(); }}>
      <ModalOverlay />
      <ModalContent >
        <ModalHeader>Create Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {loading ? (
                            <HStack justify="center" align="center" spacing={4} height="200px">
                                <LoadingSpinner/>
                                <Text>Creating project...</Text>
                            </HStack>
                        ):(<>
            <FormControl mb={4} isInvalid={!!createProjectNameError}>
            <FormLabel>Project Name</FormLabel>
            <Input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              isRequired
            />
          </FormControl>
          <FormControl mb={4} isInvalid={!!createProjectGoalError}>

        <FormLabel>Project Goal</FormLabel>
        <Input
          type="text"
          value={projectGoal}
          onChange={(e) => setProjectGoal(e.target.value)}
          isRequired
        />
    </FormControl>

    <FormControl mb={4} isInvalid={!!createProjectDescriptionError}>
      <HStack justify="space-between" align="center">
        <FormLabel>Project Description</FormLabel>
        <Checkbox
          isChecked={uploadFile}
          onChange={(e) => setUploadFile(e.target.checked)}
        >
          Upload file?
        </Checkbox>
      </HStack>

      {uploadFile ? (
        <Box 
          {...getRootPropsA()} 
          border="1px" 
          borderColor="gray.300" 
          p={4} 
          textAlign="center" 
          cursor="pointer"
          borderRadius="md"
          _hover={{ borderColor: 'gray.400' }}
        >
          <input {...getInputPropsA()} />
          {projectFile ? (
            <Text>{projectFile.name}</Text>
          ) : (
            <Text>Drag 'n' drop a file here, or click to select one</Text>
          )}
        </Box>
      ) : (
        <Input
          type="text"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          isRequired
        />
      )}
    </FormControl>
    <FormControl mb={4} isInvalid={!!createProjectBrandingError}>
      <HStack justify="space-between" align="center">
        <FormLabel>Project Planning</FormLabel>
        <Checkbox
          isChecked={uploadBFile}
          onChange={(e) => setUploadBFile(e.target.checked)}
        >
          Upload file?
        </Checkbox>
      </HStack>

      {uploadBFile ? (
        <Box 
          {...getRootPropsB()} 
          border="1px" 
          borderColor="gray.300" 
          p={4} 
          textAlign="center" 
          cursor="pointer"
          borderRadius="md"
          _hover={{ borderColor: 'gray.400' }}
        >
          <input {...getInputPropsB()} />
          {projectBFile ? (
            <Text>{projectBFile.name}</Text>
          ) : (
            <Text>Drag 'n' drop a file here, or click to select one</Text>
          )}
        </Box>
      ) : (
        <Input
          type="text"
          value={projectBranding}
          onChange={(e) => setProjectBranding(e.target.value)}
          isRequired
        />
      )}
    </FormControl>

          <FormControl mb={4}>
            <FormLabel>Messaging Option</FormLabel>
            <Select
              value={messagingOption}
              onChange={(e) => setMessagingOption(e.target.value)}
            >
              <option value="nativeChat">Native Chat</option>
              <option value="discord">Discord</option>
            </Select>
          </FormControl>
    
          {/* Conditionally render the Discord server ID input */}
          {messagingOption === 'discord' && (
            <FormControl mb={4} isInvalid={!!createDiscordError}>
              <FormLabel>Discord Server ID</FormLabel>
              <Input
                type="text"
                value={discordServerId}
                onChange={(e) => setDiscordServerId(e.target.value)}
                placeholder="Enter Discord Server ID"
              />
            </FormControl>
          )}
    
          {/* Display selected students */}
          {selectedStudents.length > 0 && showSelected && (
            <Box
              p={4}
              mt={4}
              mb={4}
              borderWidth="1px"
              borderRadius="md"
              bg="gray.50"
              position="relative"  // Ensure position is relative for the close button
            >
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={() => setSelectedStudents([])}
              />
              <Heading size="sm" mb={2}>Selected Students:</Heading>
              {students
                .filter((student) => selectedStudents.includes(student._id))
                .map((student) => (
                  <Text key={student._id}>{student.email}</Text>
                ))}
            </Box>
          )}
    
          {/* Conditionally render the "Add students" button */}
          {selectedStudents.length === 0 && (
            <Button
              bg="purple.900"
              color="white"
              _hover={{ bg: 'purple.800' }}
              onClick={onAddStudentsOpen}
            >
              Add students
            </Button>
          )}
          </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="purple.900"
            color="white"
            _hover={{ bg: 'purple.800' }}
            onClick={handleCreateProject}
          >
            Create
          </Button>
          <Button
            marginLeft={2}
            bg="gray.100"
            color="purple.900"
            _hover={{ bg: 'gray.300' }}
            onClick={() => { setSelectedStudents([]); onClose(); }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

        <Modal isOpen={isAddStudentsOpen} onClose={() => {setSelectedStudents([]); onAddStudentsClose(); }} size="lg">
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Students to Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormControl mb={4}>
                <FormLabel>Select Students</FormLabel>
                <VStack align="start" spacing={4}>
                {students.map(student => (
                    <HStack key={student._id} spacing={4}>
                    <Checkbox
                        isChecked={selectedStudents.includes(student._id)}
                        onChange={() => handleStudentSelection(student._id)}
                    >
                        {student.email}
                    </Checkbox>
                    </HStack>
                ))}
                </VStack>
            </FormControl>
            </ModalBody>
            <ModalFooter>
            <Button
                bg="purple.900"
                color="white"
                _hover={{ bg: 'purple.800' }}
                onClick={() => {
                // Handle adding selected students to project
                toast({
                    description: 'Students added to project successfully.',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                onAddStudentsClose(); setShowSelected(true);
                }}
            >
                Add Students
            </Button>
            <Button marginLeft={2} bg="gray.100" color="purple.900" _hover={{ bg: 'gray.300' }} onClick={onAddStudentsClose}>
                  Cancel
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
        </>
    );
}

export default CreateProject;