import { axiosInstance } from '../../../axiosUtils.ts';

export const getChatGPTResponse = async (messages, currentTeacherId) => {
  try {
    const response = await axiosInstance.post(`/api/student/getChatGPTResponse/${currentTeacherId}`, { messages });
    return response.data;
  } catch (error) {
    console.error('Error getting chatGPT message:', error);
    throw new Error('Failed to get chatGPT message');
  }
};

export const appendGPT = async (messages, sId) => {
  try {
    const response = await axiosInstance.post('/api/student/storeChatGPTResponse', { messages, sId });
    return response;
  } catch (error) {
    console.error('Error storing chatGPT message:', error);
    throw new Error('Failed to store chatGPT message');
  }
};

export const appendProjectGPT = async (messages, pId) => {
  try {
    const response = await axiosInstance.post('/api/student/storeChatGPTResponseToProject', { messages, pId });
    return response;
  } catch (error) {
    console.error('Error storing chatGPT message:', error);
    throw new Error('Failed to store chatGPT message');
  }
};

export const appendGroupGPT = async (messages, gId) => {
  try {
    const response = await axiosInstance.post('/api/student/storeChatGPTResponseToGroup', { messages, gId });
    return response;
  } catch (error) {
    console.error('Error storing chatGPT message:', error);
    throw new Error('Failed to store chatGPT message');
  }
};

export const getQuestionsFromGPT = async (subject, firstTopic, difficulty, classId, previousQuestion = null) => {
  try {
    const response = await axiosInstance.post('/api/student/getquestionsfromgpt', {
      subject,
      difficulty,
      previousQuestion,
      topic: firstTopic,
      classId
    });
    return response.data;
  } catch (error) {
    console.error('Error getting chatGPT questions:', error);
    throw new Error('Failed to get chatGPT questions');
  }
};

export const getTopicsFromGPT = async (subject, currentTeacherId, currentStudentId, classId, assignmentId) => {
  try {
    const response = await axiosInstance.post('/api/student/gettopicsfromgpt', {
      subject,
      teacherId: currentTeacherId,
      studentId: currentStudentId,
      classId,
      assignmentId
    });
    return response.data;
  } catch (error) {
    console.error('Error getting chatGPT topics:', error);
    throw new Error('Failed to get chatGPT topics');
  }
};

export const getSRSFromGPT = async (description, backlog, isNonFunctionalRequired, classId) => {
  try {
    const response = await axiosInstance.post('/api/student/getsrsfromgpt', {
      description,
      backlog,
      isNonFunctionalRequired,
      classId,
    });
    return response.data;
  } catch (error) {
    console.error('Error getting chatGPT SRS:', error);
    throw new Error('Failed to get chatGPT SRS');
  }
};

export const regenerateSRSFromGPT = async (srsData, missingItem, isNonFunctionalRequired, projectBackLog, projectDescription, classId, messages) => {
  try {
    const response = await axiosInstance.post('/api/student/regeneratesrsfromgpt', {
      srsData,
      missingItem,
      isNonFunctionalRequired,
      projectBackLog,
      projectDescription,
      classId,
      userMessages: messages
    });
    return response.data;
  } catch (error) {
    console.error('Error regenerating chatGPT SRS:', error);
    throw new Error('Failed to regenerate chatGPT SRS');
  }
};
