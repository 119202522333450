import React, { useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, FormControl, FormLabel, Heading, Image, Input, Spinner, Stack } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { app } from '../../../config'; // Ensure to use your correct Firebase config
import logo from '../../../Assets/UnichatLogo.png';
import loginimage from '../../../Assets/loginImage1.png';
import { getTeacherByEmail, getUserData, sendUserData } from '../../../Services/Teacher/teacherService.tsx';
import { useNavigate, useLocation } from 'react-router-dom';
import giimage from '../../../Assets/G-Ideas.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const questions = [
  { id: 'first-name', label: "What's your first name?", placeholder: 'First Name' },
  { id: 'last-name', label: "What's your last name?", placeholder: 'Last Name' },
  { id: 'school-name', label: "What's your school name?", placeholder: 'School Name' },
  { id: 'school-address', label: "What's your school address?", placeholder: 'School Address' },
  { id: 'school-email', label: "What's your school email?", placeholder: 'School Email' },
  { id: 'subject-area', label: "What's your subject area?", placeholder: 'Subject Area' },
  { id: 'phone', label: "What's your phone number?", placeholder: 'Phone' },
  { id: 'website', label: "What's your website?", placeholder: 'Website' },
];

const TLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [formData, setFormData] = useState(questions.reduce((acc, question) => ({ ...acc, [question.id]: '' }), {}));
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [step, setStep] = useState(0);
  const path = localStorage.getItem("redirectPath");

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    provider.addScope('https://www.googleapis.com/auth/drive');
    provider.addScope('https://www.googleapis.com/auth/drive.file');
    provider.addScope('https://www.googleapis.com/auth/documents');

    const auth = getAuth(app);

    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true);
      const loggedInUser = result.user;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      const expirationTimeUTC = new Date(accessToken.expirationTime).toISOString(); 
      localStorage.setItem("TokenExpirationTime", expirationTimeUTC);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const idToken = await loggedInUser.getIdToken();
      localStorage.setItem("idToken", idToken);
      window.dispatchEvent(new Event('storage'));
      const token = credential.accessToken;
      localStorage.setItem('googleAccessToken', token);
      sessionStorage.setItem('userType', 'teacher');
      sessionStorage.setItem('activeNav', JSON.stringify(0));
      // if(path?.toString().includes('homeframe'))
      // sessionStorage.setItem('activeNav', JSON.stringify(0));
      // else 
      // sessionStorage.setItem('activeNav', JSON.stringify(null));

      if (loggedInUser) {
        setUser(loggedInUser);
        try {
          const teacher = await getTeacherByEmail(loggedInUser.email);
          if (teacher.length === 0 || teacher.isFirstTimeLogin) {
            setIsFirstTimeLogin(true);
            setLoading(false);
          } else {
            if(teacher.status == "active"){
            const response = await getUserData(teacher?._id,"teacher");
            sessionStorage.setItem('sideBarData',JSON.stringify(response));
            sessionStorage.setItem('CurrentLoggedInTId', teacher._id);
            sessionStorage.setItem('teacherUserData', JSON.stringify(teacher));
            setLoading(false);
            //const redirectPath = path ||  '/teacher-portal/homeframe';
            const redirectPath = '/teacher-portal/homeframe';
            localStorage.removeItem("redirectPath");
            navigate(redirectPath);
            }
            else if(teacher.status == "new")
            {
              navigate('/processing');
            }
          }
        } catch (error) {
          console.log('error occurred', error);
        }
      }
    } catch (error) {
      console.error('Sign-in Error:', error);
    }
  };

  const handleNext = () => {
    if(formData[questions[step].id] == "")
    {
      setError("Field is required! Please enter");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
    else{
    if (step < questions.length - 1) {
      setStep(step + 1);
    }
  }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [questions[step].id]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!formData['first-name'] || !formData['last-name'] || !formData['school-email']) {
      setError('All fields are mandatory. Please fill in all fields.');
    } else if (!/\S+@\S+\.\S+/.test(formData['school-email'])) {
      setError('Email format is invalid. Please check your email and try again.');
    } else {
      const userData = {
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        firstName: formData['first-name'],
        lastName: formData['last-name'],
        schoolEmail: formData['school-email'],
        schoolName: formData['school-name'],
        schoolAddress: formData['school-address'],
        subjectArea: formData['subject-area'],
        phone: formData['phone'],
        website: formData['website'],
        isFirstTimeLogin: false,
      };

      sessionStorage.setItem('teacherUserData', JSON.stringify(userData));

      try {
        setLoading(true);
        const stud = await sendUserData(userData);
        navigate('/processing');
        setLoading(false);
      } catch (error) {
        console.error('Error submitting user data:', error);
      }
    }

    setTimeout(() => {
      setError('');
    }, 2000);
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
      borderRadius="md"
    >
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="md"
        p={8}
        maxWidth={isFirstTimeLogin ? '3xl' : 'lg'}
        width="full"
        textAlign="center"
      >
        <Image
          src={giimage}
          alt="GI Logo"
          boxSize="80px"
          borderRadius="full"
          mx="auto"
          mb={6}
        />
        <Heading as="h1" size="lg" mb={6}>
          Welcome to Generative Ideas
        </Heading>
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertTitle mr={2}>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError('')} />
          </Alert>
        )}
        {isFirstTimeLogin ? (
          <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderWidth="1px"
          borderRadius="md"
          p={4}
          bg="gray.50"
        >
          <Image
            src={loginimage}
            alt="Login Illustration"
            boxSize="250px"
            borderRadius="md"
            my={4}
            ml={2}
            
          />
          <AnimatePresence mode='wait'>
            {questions.map((question, index) =>
              index === step ? (
                <motion.div
                  key={question.id}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <FormControl mb={4}>
                    <FormLabel>{question.label}</FormLabel>
                  </FormControl>
                  <Input
                    variant="flushed"
                    placeholder={question.placeholder}
                    value={formData[question.id] || ''}
                    onChange={handleChange}
                    mb={4}
                    mr={48}
                  />
                  <Stack direction="row" spacing={4} justify="flex-end">
                    {step > 0 && (
                      <Button
                        onClick={() => setStep(step - 1)}
                        bg="gray.300"
                        color="black"
                        _hover={{ bg: 'gray.400' }}
                        _focus={{ boxShadow: 'none' }}
                        mt={4}
                        size={'sm'}
                      >
                        <FaArrowLeft />
                      </Button>
                    )}
                    {step < questions.length - 1 ? (
                      <Button
                        onClick={handleNext}
                        bg="gray.300"
                        color="black"
                        _hover={{ bg: 'gray.400' }}
                        _focus={{ boxShadow: 'none' }}
                        mt={4}
                        size={'sm'}
                      >
                        <FaArrowRight />
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        bg="purple.900"
                        color="white"
                        _hover={{ bg: 'purple.800' }}
                        _focus={{ boxShadow: 'none' }}
                        mt={2.5}
                      >
                        Submit
                      </Button>
                    )}
                  </Stack>
                </motion.div>
              ) : null
            )}
          </AnimatePresence>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            bg="gray.50"
          >
            <Image
              src={loginimage}
              alt="Login Illustration"
              boxSize="200px"
              borderRadius="md"
            />
            <Button
              onClick={signInWithGoogle}
              bg="purple.900"
              color="white"
              width="200px"
              _hover={{ bg: 'purple.900' }}
              _focus={{ boxShadow: 'none' }}
            >
              Sign in with Google
              {loading && <LoadingSpinner />}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TLogin;
