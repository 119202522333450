import { axiosInstance } from "../../../axiosUtils.ts";

const sanitizeHtml = (html) => {
  return html
    .replace(/\\/g, '\\\\')  
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t')
    .replace(/"/g, '\\"')
    .replace(/'/g, "\\'");
};



const getBaseTemplate = (type) => {
  switch (type.toLowerCase()) {
    case 'html':
      return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>HTML Challenge</title>
</head>
<body>
    <!-- Your code here -->
</body>
</html>`;

    case 'css':
      return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CSS Challenge</title>
    <style>
        /* Your code here */
        body {
            margin: 0;
            padding: 20px;
            font-family: system-ui, -apple-system, sans-serif;
        }
    </style>
</head>
<body>
    <div class="container">
        <!-- Elements to style -->
    </div>
</body>
</html>`;

    case 'javascript':
      return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>JavaScript Challenge</title>
    <style>
        body {
            margin: 0;
            padding: 20px;
            font-family: system-ui, -apple-system, sans-serif;
        }
        #root {
            max-width: 800px;
            margin: 0 auto;
        }
    </style>
</head>
<body>
    <div id="root"></div>
    <script>
        // Your code here
    </script>
</body>
</html>`;

    case 'react':
      return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>React Challenge</title>
    <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
    <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
    <style>
        body {
            margin: 0;
            padding: 20px;
            font-family: system-ui, -apple-system, sans-serif;
        }
        #root {
            max-width: 800px;
            margin: 0 auto;
        }
    </style>
</head>
<body>
    <div id="root"></div>
    <script type="text/javascript">
        // Your code here
        
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(<App />);
    </script>
</body>
</html>`;

    default:
      return '';
  }
};

const getPromptForConcept = (concept, difficulty = 'beginner', existingChallenges = []) => {
  const baseTemplate = getBaseTemplate(concept);
  const points = difficulty === 'beginner' ? 30 : difficulty === 'intermediate' ? 50 : 100;
  
  let prompt = `Create a ${difficulty} level ${concept.toUpperCase()} challenge.

Requirements:
1. Return a valid JSON object
2. MUST Include 5 test cases
3. Provide helpful hints
4. Template code must be minimal
5. Solution must be complete and working
6. Expected output must show visual result
7. If you use any image links in the code, use ONLY picsum.photos links example (https://picsum.photos/300/200).

IMPORTANT: Return ONLY a JSON object with NO additional text or formatting. The JSON object should have this exact structure:
{
  "title": "Brief descriptive title",
  "description": "Clear requirements explaining what to build",
  "difficulty": "${difficulty}",
  "points": ${points},
  "code": "This is the template code must return as is. **STRICTLY** do not include any soultion in this ${sanitizeHtml(baseTemplate)}",
  "solution": "Complete working solution code. (solution code and expected solution code should be same -> which is the final output)  <!DOCTYPE html>...</html>",
  "hints": [
    "Specific hint about approach",
    "Hint about technique",
    "Hint about best practices"
  ],
  "testCases": [
    {
      "name": "Test case description",
      "input": "What to test",
      "expected": "Expected result"
    }
  ]
}`;

if (existingChallenges.length > 0) {
  prompt = `Avoid generating a challenge similar to the following technically and functionally too and fluctuate difficulty based on points in existing challenges. Max two challenges similar allowed: ${JSON.stringify(existingChallenges)}. ` + prompt;
}

  const conceptSpecificPrompts = {
    html: `${prompt}

Focus on:
- Semantic HTML5 elements
- Proper document structure
- Accessibility
- Meta tags and SEO

Test cases should verify:
- Required elements exist
- Proper nesting
- Required attributes
- ARIA labels where needed`,

    css: `${prompt}

Focus on:
- Modern CSS techniques
- Flexbox/Grid layouts
- Responsive design
- Animations/transitions

Test cases should verify:
- Layout properties
- Media queries
- Style values
- Selectors`,

    javascript: `${prompt}

IMPORTANT: For solution, provide the COMPLETE HTML document including:
- DOCTYPE and HTML structure
- Required meta tags
- Any CSS styles in a style tag
- JavaScript code in a script tag

Example solution format:
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Solution</title>
    <style>
        /* Any required styles */
    </style>
</head>
<body>
    <div id="app"></div>
    <script>
        // JavaScript solution code
    </script>
</body>
</html>

Focus on:
- DOM manipulation
- Event handling
- Array methods
- Error handling

Test cases should verify:
- Function results
- DOM updates
- Event handlers
- Error cases`,
  };

  return conceptSpecificPrompts[concept] || prompt;
};

const getPromptForAssignment = (fileText, type, existingChallenges = []) => {
  const templateCode = getBaseTemplate(type.toLowerCase());

    let prompt = `Create a ${type.toUpperCase()} coding challenge based on this assignment, Challenge should be with various toughness and it should focus on particular topic in assignment each time, only front-end ignore challenges that require backend code or api or installing external packages. The code will be run in Monaco Editor with full HTML document support.

    Requirements:
    1. Return ONLY valid JSON
    2. MUST Include 5 test cases
    3. Points should be between 30-100
    4. Include 3 helpful hints
    5. Both code and solution must follow the base template structure
    6. Solution must pass all test cases
    7. If you use any image links in the code, use ONLY picsum.photos links example (https://picsum.photos/300/200).
    8. If React then add appropriate React CDN links
    9. Code template must not contain solution.


    Base Template: ${templateCode}

    Assignment: ${fileText}

Return a JSON object with this EXACT structure (no additional text):
{
  "title": "string (clear, descriptive title)",
  "description": "string (detailed requirements)",
  "points": number (30-100) Striclty select in (30,50,100) if easy then 30 if medium then 50 if hard then 100,
  "code": "This is the template code must return as is. **STRICTLY** do not include any soultion in this ${sanitizeHtml(templateCode)}",
  "solution": "Complete working solution code. (solution code and expected solution code should be same -> which is the final output)  <!DOCTYPE html>...</html>",
  "timeToComplete": "Expected time the user can code this challenge based on difficulty in minutes. If easy then 30, medium then 45, hard 59",
  "hints": [
    "Specific hint about approach",
    "Hint about technique",
    "Hint about best practices"
  ],
  "testCases": [
    {
      "name": "Test case description",
      "input": "What to test",
      "expected": "Expected result"
    }
  ]
}

`;

  if (existingChallenges.length > 0) {
    prompt = `Avoid generating a challenge similar to the following technically and functionally too and fluctuate difficulty. Max two challenges similar allowed: ${JSON.stringify(existingChallenges)}. ` + prompt;
  }
    return prompt;
}

export const getAssignmentChallengesFromAI = async (fileText, type, classId, studentId, assignmentId, existingChallenges = []) => {
  
  try {
    const prompt = getPromptForAssignment(fileText, type, existingChallenges);
    const { data } = await axiosInstance.post('/api/learncode/generatechallenge', {
      prompt,
      classId,
      studentId,
      assignmentId,
      language:type,
      type: 'assignment'
    });
    try {
      return data.challenge;
    } catch (parseError) {
      console.error('Error parsing challenge:', parseError);
      throw new Error('Failed to parse challenge response');
    }
  } catch (error) {
    console.error('Error generating challenge:', error);
    throw new Error('Failed to generate challenge');
  }
};

export const getChallengesFromAI = async (concept, difficulty, classId, studentId, existingChallenges = []) => {
  
  try {
    const prompt = getPromptForConcept(concept, difficulty, existingChallenges);
    const { data } = await axiosInstance.post('/api/learncode/generatechallenge', {
      prompt,
      classId,
      studentId,
      concept,
      type: 'frontend'
    });
    try {
      return data.challenge;
    } catch (parseError) {
      console.error('Error parsing challenge:', parseError);
      throw new Error('Failed to parse challenge response');
    }
  } catch (error) {
    console.error('Error generating challenge:', error);
    throw new Error('Failed to generate challenge');
  }
};

export const fetchChallenges = async (studentId, classId, frontendCategory, difficulty) => {
    try {
      const response = await axiosInstance.get("/api/learncode/challenges", {
        params: {
          studentId,
          classId,
          frontendCategory,
          difficulty,
          type: "frontend",
        },
      });
  
      return response.data.challenges;
    } catch (error) {
      console.error("Error fetching challenges:", error);
      throw new Error("Failed to fetch challenges");
    }
  };

export const fetchAssignmentChallenges = async (studentId, classId, assignmentId) => {
    try {
      const response = await axiosInstance.get("/api/learncode/challenges", {
        params: {
          studentId,
          classId,
          assignmentId,
          type: "assignment",
        },
      });
  
      return response.data.challenges;
    } catch (error) {
      console.error("Error fetching challenges:", error);
      throw new Error("Failed to fetch challenges");
    }
  };

  export const fetchChallengeById = async (challengeId, studentId, classId, frontendCategory, difficulty) => {
    try {
      const response = await axiosInstance.get("/api/learncode/challenges/byid", {
        params: {
          studentId,
          classId,
          frontendCategory,
          difficulty,
          type: "frontend",
          challengeId,
        },
      });
  
      return response.data.challenge;
    } catch (error) {
      console.error("Error fetching challenge by ID:", error);
      throw new Error("Failed to fetch challenge by ID");
    }
  };
  export const fetchAssignmentChallengeById = async (challengeId, studentId, classId, assignmentId) => {
    try {
      const response = await axiosInstance.get("/api/learncode/challenges/byid", {
        params: {
          studentId,
          classId,
          assignmentId,
          type: "assignment",
          challengeId,
        },
      });
  
      return response.data.challenge;
    } catch (error) {
      console.error("Error fetching challenge by ID:", error);
      throw new Error("Failed to fetch challenge by ID");
    }
  };
  export const getLast15Challenges = async (studentId, classId, frontendCategory, difficulty) => {
    try {
      const response = await axiosInstance.get('/api/learncode/challenges/last15', {
        params: {
          studentId,
          classId,
          frontendCategory,
          difficulty,
          type: 'frontend'
        }
      });
  
      return response.data.challenges;
    } catch (error) {
      console.error('Error fetching last 15 challenges:', error);
      throw new Error('Failed to fetch last 15 challenges');
    }
  };
  


export const evaluateCode = async (challenge, code, classId, studentId, concept, difficulty) => {
  const maxPoints = difficulty === 'beginner' ? 30 : difficulty === 'intermediate' ? 50 : 100;
  try {
    const prompt = `You are an expert code evaluator. Evaluate the following user code by comparing it with the provided solution and test cases. 

### Task
1. CAREFULLY analyze the user code and compare it against the solution and challenge description to verify correctness. 
2. Execute the user code against the provided test cases carefully:
   - If both the user code and solution produce identical outputs and all test cases pass, assign a score of ${maxPoints} and mark it as passed.
   - If the user code produces partially correct results, assign a partial score (e.g., between 1 and ${maxPoints - 1}) based on correctness and number of tests passed.
   - If the user code is incomplete, incorrect, or fails all test cases, assign a score of 0 and mark it as failed.
3. Check for the following:
   - Correct implementation of logic as described in the challenge.
   - Similarities and differences between the user code and solution.
   - Adherence to the test case expectations.

### Challenge Details
Title: ${challenge.challengeData.title}
Description: ${challenge.challengeData.description}
 
### Solution
  ${challenge.challengeData.solution}
  
  ### User Code
  ${code}
  
  ### Test Cases
  ${JSON.stringify(challenge.challengeData.testCases, null, 2)}
  
  ### Output Format
  Return ONLY a JSON object in the following format:
  {
    "passed": boolean, // true if all test cases pass, false otherwise
    "score": number (0-${maxPoints}), // score based on the evaluation criteria
    "testResults": [
      {
        "name": "Test name",
        "passed": boolean,
        "expected": "Expected output with clear formatting",
        "output": "Actual output with clear formatting"
      }
    ],
    "differences": "Clear and concise explanation of feedback of only based on functional differences between user code and solution, if any."
  }`;
  
  
      const { data } = await axiosInstance.post('/api/learncode/evaluatecode', {
        challengeId: challenge.challengeId,
        code,
        prompt,
        classId,
        studentId,
        frontendCategory: concept, difficulty,
        type: 'frontend'
      });
  
      return data.evaluation;
    } catch (error) {
      console.error('Error evaluating code:', error);
      throw new Error('Code evaluation failed');
    }
  };

  export const evaluateAssignmentCode = async (challenge, code, classId, studentId, assignmentId) => {
    const maxPoints = challenge.points;
    try {
      const prompt = `You are an expert code evaluator. Evaluate the following user code by comparing it with the provided solution and test cases. 
  
  ### Task
  1. CAREFULLY analyze the user code and compare it against the solution and challenge description to verify correctness. 
  2. Execute the user code against the provided test cases carefully:
     - If both the user code and solution produce identical outputs and all test cases pass, assign a score of ${maxPoints} and mark it as passed.
     - If the user code produces partially correct results, assign a partial score (e.g., between 1 and ${maxPoints - 1}) based on correctness and number of tests passed.
     - If the user code is incomplete, incorrect, or fails all test cases, assign a score of 0 and mark it as failed.
  3. Check for the following:
     - Correct implementation of logic as described in the challenge.
     - Similarities and differences between the user code and solution.
     - Adherence to the test case expectations.
  
  ### Challenge Details
  Title: ${challenge.challengeData.title}
  Description: ${challenge.challengeData.description}
  
  ### Solution
  ${challenge.challengeData.solution}
  
  ### User Code
  ${code.replace(/text\/javascript/g, 'text/babel')}
  
  ### Test Cases
  ${JSON.stringify(challenge.challengeData.testCases, null, 2)}
  
  ### Output Format
  Return ONLY a JSON object in the following format:
  {
    "passed": boolean, // true if all test cases pass, false otherwise
    "score": number (0-${maxPoints}), // score based on the evaluation criteria
    "testResults": [
      {
        "name": "Test name",
        "passed": boolean,
        "expected": "Expected output with clear formatting",
        "output": "Actual output with clear formatting"
      }
    ],
    "differences": "Clear and concise explanation of feedback of only functional differences between user code and solution, if any."
  }`;
  
  
      const { data } = await axiosInstance.post('/api/learncode/evaluatecode', {
        challengeId: challenge.challengeId,
        code,
        prompt,
        classId,
        studentId,
        assignmentId,
        type: 'assignment'
      });
  
      return data.evaluation;
    } catch (error) {
      console.error('Error evaluating code:', error);
      throw new Error('Code evaluation failed');
    }
  };


export const getAIHelp = async (challenge, code, classId) => {
  try {
    const prompt = `For this ${challenge.difficulty} level challenge:
"${challenge.title}"

And this code:
${code}

Provide 2-3 specific, helpful hints that guide the user without giving away the solution.
Focus on concepts and approaches with small code snippets rather than exact solution code.

Format the response as a clear, bulleted list.`;

    const { data } = await axiosInstance.post('/api/learncode/aihelp', {
      prompt,
      classId
    });

    return data.hints; 
  } catch (error) {
    console.error('Error in getAIHelp:', error);
    throw new Error('Failed to get help from AI');
  }
};

export const getCodeExplanation = async (code, type, classId) => {
  const prompt =
    type === 'line-by-line'
      ? `Explain this code line by line:
${code}

Return ONLY a JSON array with this exact structure:
[
  {
    "line": "code line here",
    "explanation": "clear explanation here in one or two lines"
  }
]`
      : `Provide a concise summary of what this code does:
${code}

Focus on:
1. Main functionality
2. Key techniques used
3. Important patterns or concepts

Keep it clear and brief.`;

  try {
    const { data } = await axiosInstance.post('/api/learncode/explaincode', {
      prompt,
      classId,
    });

    
    if (typeof data.explanation === 'string') {
      try {
        return JSON.parse(data.explanation);
      } catch {
        return data.explanation; 
      }
    }

    return data.explanation; 
  } catch (error) {
    console.error('Error getting code explanation:', error);
    throw new Error('Failed to get code explanation');
  }
};

export const saveViewedSolution = async (challengeId, studentId, classId, frontendCategory, difficulty) => {
  try {
    const response = await axiosInstance.post('/api/learncode/viewedSolution', {
          studentId,
          classId,
          frontendCategory,
          difficulty,
          type: "frontend",
          challengeId,
    });

    return response.data; 
  } catch (error) {
    console.error('Error marking solution as viewed:', error);
    throw new Error('Failed to update viewed solution');
  }
};

export const saveViewedSolutionForAssignment = async (challengeId, studentId, classId, assignmentId) => {
  try {
    const response = await axiosInstance.post('/api/learncode/viewedSolution', {
          studentId,
          classId,
          assignmentId,
          type: "assignment",
          challengeId,
    });

    return response.data; 
  } catch (error) {
    console.error('Error marking solution as viewed:', error);
    throw new Error('Failed to update viewed solution');
  }
};

export const analyzeAssignment = async (content, classId) => {

  const prompt = `Analyze this assignment content and determine if it primarily focuses on front-end web development (HTML, CSS, JavaScript, React). 

      Rules:
      1. If the content is **NOT** related to front-end web development, return this exact JSON: 
         { "error": "Content is not related to front-end web development" }
      2. Otherwise, return ONLY a JSON object in this format:
         {
           "type": "html" | "css" | "javascript" | "react"
         }
      3. Do NOT assume content is about front-end development if there are no relevant keywords.

      Content to analyze: ${content}`;
  try {
    const { data } = await axiosInstance.post('/api/learncode/analyzeAssignment', {
      content,
      classId, 
      prompt
    });

    return data; 
  } catch (error) {
    console.error('Error in analyzeAssignment:', error);
    throw new Error(error.response?.data?.error || 'Failed to analyze assignment');
  }
};
