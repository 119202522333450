import React, { useState, useEffect } from 'react';
import {
  Flex,
  FlexProps,
  IconButton,
  useColorModeValue,
  Text,
  Box,
  MenuButton,
  MenuList,
  HStack,
  MenuDivider,
  MenuItem,
  Menu,
  Avatar,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  VStack,
  ModalBody,
  Input,
  ModalFooter,
  Button
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FiMenu, FiPlus, FiChevronDown, FiEdit } from 'react-icons/fi';
import useDrivePicker from 'google-drive-picker';
import { signOut } from 'firebase/auth';
import { auth } from '../../config.js';
import CreateClass from "../teacher-portal/CreateClass/CreateClass.tsx";
import ChatGPTIcon from "../../Assets/chatgpt-bw.png";
import TeacherChatBox from './TeacherChatBox.tsx';
import StudentChatBox from './StudentChatBox.tsx';
import { updateStudentProfile } from '../../Services/Student/studentService.tsx';


const MobileNav = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [showCreateClassModal, setShowCreateClassModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showChatBox, setShowChatBox] = useState<boolean>(false);
  const userType = sessionStorage.getItem("userType");
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    schoolmail: ''
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState(formData);
  const [isLoading, setIsLoading] = useState(false); 
  const toast = useToast();

  

  useEffect(() => {
    if (userType === "student") {
      const stored = sessionStorage.getItem("studentUserData");
      const user = JSON.parse(stored);
      setUserInfo(user);
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        schoolmail: user.schoolmail,
      });
    } else if (userType === "teacher") {
      const stored = sessionStorage.getItem("teacherUserData");
      const user = JSON.parse(stored);
      setUserInfo(user);
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        schoolmail: user.schoolmail,
      });
    }
  }, [userType]);

  const handleEditProfile = () => {
    setInitialValues(formData); 
    setIsEditMode(!isEditMode);
  };
  const hasChanges = JSON.stringify(formData) !== JSON.stringify(initialValues);
  
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

const handleSaveChanges = async () => {
  try {
    setIsLoading(true);  
    const response = await updateStudentProfile(userInfo._id, formData);
    toast({
      title: "Profile updated.",
      description: "Your profile has been updated successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setUserInfo(response);
    setIsEditMode(false);
    if(userType === "student"){
      sessionStorage.setItem("studentUserData", JSON.stringify(response));
    }

  } catch (error) {
    console.error('Error saving profile changes:', error);
    toast({
      title: "Error updating profile.",
      description: "Failed to update your profile. Please try again later.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  } finally {
    setIsLoading(false); 
    setShowProfileModal(false);
  }
};


  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.clear();
      localStorage.clear();
      navigate('/')
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleChatGPTIconClick = () => {
    setShowChatBox(!showChatBox);
  };

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
 

  return (
    <>
    <Flex
      position="fixed"
      top={0}
      right={0}
      left="260px"
      h="64px"
      bg={bg}
      borderBottom="1px"
      borderColor={borderColor}
      px={6}
      align="center"
      justify="space-between"
      zIndex={100}
      mb={2}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>
      <HStack spacing={4}>
        <Image
          src={ChatGPTIcon}
          boxSize="24px"
          alt="ChatGPT"
          onClick={handleChatGPTIconClick}
          cursor="pointer"
        />
        

        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  bg="purple.800"
                  textColor={'white'}
                  name={
                    _.capitalize((userInfo as any)?.name)
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text
                    fontSize="md"
                    fontWeight={'semibold'}
                    mb="0"
                  >
                    {userType === "teacher"
                      ? userInfo?.name
                      : `${userInfo?.firstName} ${userInfo?.lastName}`
                    }

                  </Text>
                  {userType === "teacher" ? (
                    <Text
                      fontSize="sm"
                      textColor="green.800"
                      fontWeight={'semibold'}
                    >
                      Teacher
                    </Text>
                  ) : (
                    <Text
                      fontSize="sm"
                      textColor="green.800"
                      fontWeight={'semibold'}
                    >
                      Student
                    </Text>
                  )}
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }} mx="2">
                  <FiChevronDown size={18} />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => setShowProfileModal(true)}>
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>

      <CreateClass
        isOpen={showCreateClassModal}
        onClose={() => setShowCreateClassModal(false)}
      />
      {userType === "teacher" ? (
        showChatBox && <TeacherChatBox onClose={() => setShowChatBox(false)} />
      ) : (
        userType === "student" && showChatBox && <StudentChatBox onClose={() => setShowChatBox(false)} />
      )}
    </Flex>
    <Modal isOpen={showProfileModal && userType === "student"} onClose={() => setShowProfileModal(false)}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader display="flex" flexDirection="column" alignItems="center">
      <Avatar 
        src={userInfo?.photoURL} 
        size="lg" 
        mb={4} 
        name={`${formData.firstName} ${formData.lastName}`} 
      />
      <HStack spacing={2} justifyContent="center">
    <Text fontSize="lg" fontWeight="bold">
      {formData.firstName} {formData.lastName}
    </Text>
    <IconButton
      icon={<FiEdit />}
      variant="ghost"
      aria-label="Edit Profile"
      onClick={handleEditProfile}
      size="sm"
    />
  </HStack>
    </ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <VStack spacing={4} align="stretch">
        <Text fontWeight="bold">First Name:</Text>
        <Input
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          isReadOnly={!isEditMode}
        />
        <Text fontWeight="bold">Last Name:</Text>
        <Input
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          isReadOnly={!isEditMode}
        />
        <Text fontWeight="bold">School Email:</Text>
        <Input
          name="schoolmail"
          value={formData.schoolmail}
          onChange={handleInputChange}
          isReadOnly={!isEditMode}
          mb={8}
        />
      </VStack>
    </ModalBody>
    {isEditMode && (
      <ModalFooter>
        <Button
          bg="purple.900"
          color={"white"}
          _hover={{ bg: "purple.900", color: "white" }}
          variant="solid"
          mr={3}
          onClick={handleSaveChanges}
          isLoading={isLoading}  
          loadingText="Saving..."  
          isDisabled={!hasChanges} 
        >
          Save Changes
        </Button>
        <Button variant="ghost" onClick={() => setIsEditMode(false)}>
          Discard
        </Button>
      </ModalFooter>
    )}
  </ModalContent>
</Modal>

</>
  );
};

export default MobileNav;
