import React, { useState, useEffect } from 'react';
import { getClassesByUserId, getQuestionsByTeacherId, getStudentsByIds } from '../../../Services/Teacher/teacherService.tsx';
import {
  Box,
  useToast,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Select,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SearchIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { DatePicker } from 'antd';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const QuestionsTable = () => {
  const userId = sessionStorage.getItem('CurrentLoggedInTId').trim();
  const toast = useToast();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const user = sessionStorage.getItem('userType');
  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [currentClassStudents, setCurrentClassStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState('');
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedIsCorrect, setSelectedIsCorrect] = useState(''); 
  const [isTopicsOpen, setIsTopicsOpen] = useState(false);
  const [isCorrectOpen, setIsCorrectOpen] = useState(false);
  const [appliedTopics, setAppliedTopics] = useState([]);
  const [appliedIsCorrect, setAppliedIsCorrect] = useState('');
  const [isCorrectFilter, setIsCorrectFilter] = useState(''); 

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const questionsData = await getQuestionsByTeacherId(userId);
        if (!Array.isArray(questionsData)) return;
        setAllQuestionsData(questionsData);
      } catch (error) {
        toast({
          title: 'Error fetching questions',
          description: 'There was an error fetching your questions. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
  
    fetchQuestions();
  }, [userId, toast]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClassesByUserId(userId, user);
        if (!classesData.message) setClasses(classesData);
        else setClasses([]);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, [userId, user]);

  useEffect(() => {
    const fetchAssignments = () => {
      if (!selectedClass) {
        setAssignments([]); 
        return;
      }
  
      const assignmentsList = allQuestionsData
        .filter((q) => q.classId._id === selectedClass && q.assignmentId) 
        .reduce((acc, q) => {
          if (!acc.some(item => item._id === q.assignmentId._id)) {
            acc.push({ _id: q.assignmentId._id, filename: q.assignmentId.filename });
          }
          return acc;
        }, []);
  
      setAssignments(assignmentsList);
    };
  
    fetchAssignments();
    setSelectedAssignment("");
    setSelectedStudent("");
    setFilteredStudents([]);
  }, [selectedClass, allQuestionsData]);

  useEffect(() => {
    if (selectedClass) {
      const classQuestions = allQuestionsData.filter(question => question.classId._id === selectedClass);
      const studentsInClass = classQuestions.reduce((acc, question) => {
        const studentId = question.studentId._id;
        if (!acc.includes(studentId)) {
          acc.push(studentId);
        }
        return acc;
      }, []);
      const fetchStudentDetails = async () => {
        if (studentsInClass.length === 0) {
          setFilteredQuestions([]);
          setQuestions([]);
          return;
        }
        try {
          const students = await getStudentsByIds(studentsInClass);
          const userMap = {};
  
          students.forEach(user => {
            userMap[user._id] = {
              id: user._id,
              name: user.name,
              email: user.email,
              photoURL: user.photoURL,
            };
          });
  
          setUserMap(userMap);
          const Slist = studentsInClass.map((studentId) => userMap[studentId]);
          setFilteredStudents(Slist);
          setCurrentClassStudents(Slist); 
    
        } catch (error) {
          console.error("Error fetching student details:", error);
        }
      };
  
      fetchStudentDetails();
      setSelectedAssignment("");
      setSelectedStudent("");
      setFilteredStudents([]);
    } else {
      setFilteredQuestions([]);
      setQuestions([]);
    }
  }, [selectedClass, allQuestionsData]);

  useEffect(() => {
    if (!selectedClass || !selectedStudent || !selectedAssignment) {
      setQuestions([]);
      setFilteredQuestions([]);
      return;
    }
  
    const filteredData = allQuestionsData.filter(
      (question) =>
        question.classId._id === selectedClass &&
        question.studentId._id === selectedStudent &&
        question?.assignmentId?._id === selectedAssignment 
    );
    const filteredQuestions = filteredData.flatMap((q) => q.questions); 
  
    setQuestions(filteredQuestions);
    setFilteredQuestions(filteredQuestions);
    setTopics(filteredData[0]?.topics)
  }, [selectedClass, selectedStudent, selectedAssignment, allQuestionsData]);

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    if (!term.trim()) {
      setFilteredStudents(currentClassStudents);
      return;
    }

    const searchTermLower = term.toLowerCase();
    const filtered = currentClassStudents.filter((student) => {
      const studentName = student?.name?.toLowerCase() || '';
      const studentEmail = student?.email?.toLowerCase() || '';
      
      return studentName.includes(searchTermLower) || 
             studentEmail.includes(searchTermLower);
    });

    setFilteredStudents(filtered);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredStudents(currentClassStudents);
  };

  useEffect(() => {
    const adjustedEndDate = dateRange[1]
      ? new Date(dateRange[1]).setDate(new Date(dateRange[1]).getDate() + 1) 
      : null;
  
    const filtered = questions.filter((question) => {
      const isInDateRange = (dateRange[0] && adjustedEndDate)
        ? new Date(question.sentTime) >= dateRange[0] && new Date(question.sentTime) <= adjustedEndDate
        : true;
  
      const queryLower = searchQuery.toLowerCase();
      const matchesSearch = question.question.toLowerCase().includes(queryLower) ||
        question.topic.toLowerCase().includes(queryLower) ||
        question.options.some(option => option.toLowerCase().includes(queryLower));

      const matchesIsCorrect = appliedIsCorrect
        ? (selectedIsCorrect === 'Yes' ? question.isCorrect : !question.isCorrect)
        : true;

      const matchesTopics = appliedTopics.length > 0 
        ? selectedTopics.includes(question.topic)
        : true;

      return isInDateRange && matchesSearch && matchesIsCorrect && matchesTopics;
    });
  
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
  
    setFilteredQuestions(filtered);
  }, [searchQuery, dateRange, questions, sortConfig, isCorrectFilter, appliedTopics, appliedIsCorrect]);

  const openQuestionModal = (question) => { 
    setSelectedQuestion(question);
    setIsQuestionModalOpen(true);
  };

  const closeQuestionModal = () => { 
    setIsQuestionModalOpen(false);
    setSelectedQuestion(null);
  };

  const renderContent = (content) => {
    const codeBlockRegex = /```([a-zA-Z]*)\n?([\s\S]*?)```|```([^`]+)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(content)) !== null) {
      const [fullMatch, language, code] = match;
      const startIndex = match.index;

      if (startIndex > lastIndex) {
        parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex, startIndex)}</Text>);
      }

      parts.push(
        <SyntaxHighlighter key={`code-${startIndex}`} language={language || 'javascript'} style={materialDark}>
          {code.trim()}
        </SyntaxHighlighter>
      );
      lastIndex = codeBlockRegex.lastIndex;
    }

    if (lastIndex < content.length) {
      parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex)}</Text>);
    }

    return parts;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const applyFilters = () => {
    setAppliedTopics(selectedTopics);
    setAppliedIsCorrect(selectedIsCorrect);
    setFilterModalOpen(false); 
  };

  const clearFilters = () => {
    setSelectedTopics([]);
    setSelectedIsCorrect('');
    setAppliedIsCorrect('');
    setAppliedTopics([]);
  };

  const toggleTopics = () => setIsTopicsOpen((prev) => !prev);
  const toggleIsCorrect = () => setIsCorrectOpen((prev) => !prev);

  const handleIsCorrectChange = (value) => {
    setSelectedIsCorrect(value);
  };

  const toggleTopicSelection = (topic) => {
    setSelectedTopics((prev) => {
      const updatedTopics = prev.includes(topic)
        ? prev.filter((t) => t !== topic)
        : [...prev, topic];
  
      return updatedTopics;
    });
  };

  return (
    <> 
      <Flex mb={4} align="center" justify="space-between">
        <DatePicker.RangePicker
          onChange={(dates) => setDateRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null])}
          format="YYYY-MM-DD"
          style={{ width: '30%' }}
        />
        <InputGroup ml={80} width="30%">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
          />
          {searchQuery && (
            <InputRightElement
              cursor="pointer"
              children={<CloseIcon fontSize={14} _hover={{ color: "gray.600" }} color="gray.300" />}
              onClick={() => setSearchQuery('')}
            />
          )}
        </InputGroup>
        <Button onClick={() => setFilterModalOpen(true)} variant="outline" leftIcon={<SearchIcon />}>
          Filters
        </Button>
      </Flex>

      <Box p={6} mx="auto" mt={4} ml={-5} borderWidth="1px" borderRadius="lg" boxShadow="lg">
        <Heading fontSize="lg" mb={4}>Answered Questions</Heading>

        {loading ? (
          <Flex justify="center" align="center" height="100%">
            <LoadingSpinner />
          </Flex>
        ) : (
          <>
            <HStack mt={6} mb={4} justifyContent="right" spacing={8}>
              <Select
                width="200px"
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
                cursor="pointer"
              >
                <option value="">Choose class</option>
                {classes.map((classItem) => (
                  <option key={classItem._id} value={classItem._id}>
                    {classItem.classname}
                  </option>
                ))}
              </Select>
              <Select
                width="200px"
                value={selectedAssignment}
                onChange={(e) => setSelectedAssignment(e.target.value)}
                cursor="pointer"
              >
                <option value="">{assignments.length > 0 ? 'Choose assignment' : 'No Assignments'}</option>
                {assignments.map((assignment) => (
                  <option key={assignment._id} value={assignment._id}>
                    {assignment.filename}
                  </option>
                ))}
              </Select>
              <Box width="200px">
                <Menu>
                  <MenuButton 
                    variant={'outline'} 
                    as={Button} 
                    rightIcon={<ChevronDownIcon />} 
                    width="100%" 
                    fontWeight="normal"
                  > 
                    {selectedStudent ? userMap[selectedStudent]?.name : "Choose student"}
                  </MenuButton>
                  <MenuList maxHeight="250px" overflowY="auto" p={2}>
                    <InputGroup size="sm" mb={2}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.400" />}
                      />
                      <Input
                        placeholder="Search student..."
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        onClick={(e) => e.stopPropagation()}
                        borderRadius="md"
                        
                      />
                      {searchTerm && (
                        <InputRightElement>
                          <CloseIcon 
                            fontSize="10px"
                            color="gray.500"
                            cursor="pointer"
                            onClick={clearSearch}
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    {filteredStudents.length > 0 ? (
                      filteredStudents.map((student) => (
                        <MenuItem
                          key={student.id}
                          onClick={() => {
                            setSelectedStudent(student.id);
                            setSearchTerm('');
                            setFilteredStudents(currentClassStudents);
                          }}
                        >
                          <Box>
                            <Text fontWeight="medium">{student.name}</Text>
                            <Text fontSize="sm" color="gray.500">{student.email}</Text>
                          </Box>
                         
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem isDisabled justifyContent={'center'} alignItems={'center'}>
                        No students found
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            </HStack>

            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th onClick={() => handleSort('topic')} cursor="pointer">Topic</Th>
                  <Th onClick={() => handleSort('question')} cursor="pointer">Question</Th>
                  <Th onClick={() => handleSort('correct_answer')} cursor="pointer">Correct Answer</Th>
                  <Th onClick={() => handleSort('selectedOption')} cursor="pointer">Selected Option</Th>
                  <Th onClick={() => handleSort('isCorrect')} cursor="pointer">Is Correct?</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredQuestions.length > 0 ? (
                  filteredQuestions.map((question, index) => (
                    <Tr key={index} onClick={() => openQuestionModal(question)} style={{ cursor: 'pointer' }}>
                      <Td>{question.topic}</Td>
                      <Td>{question.question}</Td>
                      <Td>{question.correct_answer}</Td>
                      <Td>{question.selectedOption}</Td>
                      <Td>{question.isCorrect ? 'Yes' : 'No'}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="10" textAlign="center">No questions found.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            <Modal isOpen={isQuestionModalOpen} onClose={closeQuestionModal}> 
              <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
              <ModalContent maxWidth="600px" maxHeight="600px">
                <ModalHeader>Question Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody maxHeight="600px" overflowY="auto">
                  {selectedQuestion && (
                    <>
                      <Box mb={4}><strong>Question:</strong> {renderContent(selectedQuestion.question)}</Box>
                      <Box mb={4}><strong>Options:</strong></Box>
                      <Box pl={4}>
                        {selectedQuestion.options.map((option, index) => (
                          <Box key={index} bg={option === selectedQuestion.correct_answer && selectedQuestion.isCorrect ? 'green.100' : option === selectedQuestion.selectedOption && !selectedQuestion.isCorrect ? 'red.100' : 'transparent'} p={2} borderRadius="md">
                            {renderContent(option)}
                          </Box>
                        ))}
                      </Box>
                      <Box mt={4}><strong>Correct Answer:</strong> {renderContent(selectedQuestion.correct_answer)}</Box>
                      <Box><strong>Your Selected Option:</strong> {renderContent(selectedQuestion.selectedOption)}</Box>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }} onClick={closeQuestionModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal size={'lg'} isOpen={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
              <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
              <ModalContent>
                <ModalHeader>Filter Questions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box mb={4} cursor="pointer">
                    <Flex onClick={toggleTopics} align="center" justify="space-between">
                      <Text fontWeight="bold">Filter by Topics</Text>
                      <Text>{isTopicsOpen ? '▲' : '▼'}</Text>
                    </Flex>
                    {isTopicsOpen && (
                      <Box mt={2} pl={4}>
                        {topics?.map((topic) => (
                          <Box key={topic} mb={1}>
                            <label style={{ fontSize: '14px' }}>
                              <input
                                type="checkbox"
                                checked={selectedTopics.includes(topic)}
                                onChange={() => toggleTopicSelection(topic)}
                              />{' '}
                              {topic}
                            </label>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>

                  <Box mb={4} cursor="pointer">
                    <Flex onClick={toggleIsCorrect} align="center" justify="space-between">
                      <Text fontWeight="bold">Filter by Is Correct</Text>
                      <Text>{isCorrectOpen ? '▲' : '▼'}</Text>
                    </Flex>
                    {isCorrectOpen && (
                      <Box mt={2} pl={4}>
                        <Box mb={1}>
                          <label style={{ fontSize: '14px' }}>
                            <input
                              type="radio"
                              value="Yes"
                              checked={selectedIsCorrect === 'Yes'}
                              onChange={() => handleIsCorrectChange('Yes')}
                            />{' '}
                            Yes
                          </label>
                        </Box>
                        <Box>
                          <label style={{ fontSize: '14px' }}>
                            <input
                              type="radio"
                              value="No"
                              checked={selectedIsCorrect === 'No'}
                              onChange={() => handleIsCorrectChange('No')}
                            />{' '}
                            No
                          </label>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={applyFilters} bg="purple.900" color="white" _hover={{ bg: 'purple.900' }} mr={3}>
                    Apply Filters
                  </Button>
                  <Button onClick={clearFilters} variant="ghost">
                    Clear Filters
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    </>
  );
};

export default QuestionsTable;