import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Button,
  Image,
  useToast,
  Avatar,
  SimpleGrid,
  Textarea,
  Divider
} from '@chakra-ui/react';
import { pusherClient } from '../../../pusherConfig.js';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { getClassById, getTeachersByIds, getStudentsByIds, getProjectsByIds, addAnnouncementToClass, fetchAnnouncementsForClass, addCommentToAnnouncement } from '../../../Services/Teacher/teacherService.tsx';
import ProjectCard from './projectCard.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import LearnQHome from './LearnQHome.jsx';
import LearnQ from './LearnQ.jsx';
import { fetchAssignmentsForClass } from '../../../Services/Student/studentService.tsx';

const ClassView = () => {
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teacherLoading, setTeacherLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0); 
  const { classId } = useParams();
  const sId = sessionStorage.getItem("CurrentLoggedInSId").trim();
  const [announcementError, setAnnouncementError] = useState('');
  const [fileText, setFileText] = useState(""); 
  const [currentTId, setCurrentTId] = useState("");
  const [assignmentId, setAssignmentId] = useState("");
  const toast = useToast();
  const [assignments, setAssignments] = useState([]);


  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        setLoading(true);
        const data = await getClassById(classId);
        if(data.students.includes(sId)){
        setClassDetails(data);
        const storedClassName = sessionStorage.getItem(`class_${data._id}`);
        
        if (!storedClassName) {
          sessionStorage.setItem(`class_${data._id}`, data.classname);
        }
        }
      } catch (error) {
        console.error('Error fetching class details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClassDetails();
    setActiveTab(0);
  }, [classId]);

  useEffect(() => {
    if (!classId) return;
  
    const announcementChannel = pusherClient.subscribe(`announcements-${classId}`);
  
    const handleNewAnnouncements = (newAnnouncements) => {
      const newAnnouncement = newAnnouncements.announcements[0]; 
      setAnnouncements(prevAnnouncements => {
        const announcementExists = prevAnnouncements.some(announcement => announcement._id === newAnnouncement._id);
        const updatedAnnouncements = announcementExists
          ? prevAnnouncements.map(announcement => 
              announcement._id === newAnnouncement._id ? newAnnouncement : announcement
            )
          : [...prevAnnouncements, newAnnouncement];
        const sortedData = updatedAnnouncements.sort((a, b) => new Date(b.time) - new Date(a.time));
  
        return sortedData;
      });
    };
  
    announcementChannel.bind('announcements:new', handleNewAnnouncements);
  
    return () => {
      announcementChannel.unbind('announcements:new', handleNewAnnouncements);
      pusherClient.unsubscribe(`announcements-${classId}`);
    };
  }, [classId]);
  
  

  const fetchTeachers = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  const fetchStudents = async (studentIds) => {
    setStudentLoading(true);
    try {
      const data = await getStudentsByIds(studentIds);
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError(error.message);
    } finally {
      setStudentLoading(false);
    }
  };
  
  const [projectLoading, setProjectLoading] = useState(true);
  const fetchProjects = async (projectIds) => {
    setProjectLoading(true);
    try {
      const data = await getProjectsByIds(projectIds);
      setProjects(data.filter((proj)=>proj.studentIds.includes(sId)));
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error.message);
    } finally {
      setProjectLoading(false);
    }
  };

  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [newComment, setNewComment] = useState({});
  const [commentErrors, setCommentErrors] = useState({});
  const [showComments, setShowComments] = useState({});
  


  const handleCommentChange = (announcementId, value) => {
    setNewComment(prevState => ({
      ...prevState,
      [announcementId]: value
    }));
  };

  const toggleComments = (id) => {
    setShowComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  

  useEffect(() => {
    fetchAnnouncements();
  }, [classId]);
  const fetchAnnouncements = async () => {
    try {
      const data = await fetchAnnouncementsForClass(classId);
      const sortedData = data.sort((a, b) => new Date(b.time) - new Date(a.time));
      setAnnouncements(sortedData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  
  const handleCommentSubmit = async (announcementId) => {
    const comment = newComment[announcementId] || '';
  
  if (comment.trim() === '') {
    setCommentErrors(prevState => ({
      ...prevState,
      [announcementId]: 'Comment cannot be empty.'
    }));
    setTimeout(() => {
      setCommentErrors(prevState => ({
        ...prevState,
        [announcementId]: ''
      }));
    }, 2000); 

    return;
  }

  
    try {
      await addCommentToAnnouncement(announcementId, {
        userId: sId,  
        comment: comment,
        userModel: "Student",
        classId: classId,
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
      setNewComment('');
      setCommentErrors({});
    } catch (error) {
      console.error('Error submitting comment:', error);
      
    }
  };
  const [postcommentloading, setPostCommentLoading] = useState(false);
  const handleAnnouncementSubmit = async () => {
    if (newAnnouncement.trim() === '') {
      setAnnouncementError('Announcement cannot be empty.');
      setTimeout(() => {
        setAnnouncementError('');
      }, 2000); 
      return;
    }
  setPostCommentLoading(true);
    try {
      await addAnnouncementToClass(classId, {
        userId: sId,  
        announcement: newAnnouncement,
        comments: [],
        userModel: "Student",
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
    } catch (error) {
      console.error('Error submitting announcement:', error);
      
    }finally {
      setPostCommentLoading(false); 
    }
  };

  const fetchAssignments = async () => {
    try {
      setLoading(true);
      const assignmentsData = await fetchAssignmentsForClass(classId);
      setAssignments(assignmentsData || []);
    } catch (error) {
      toast({
        title: 'Error fetching assignments',
        description: 'There was an error fetching your assignments. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }finally{
      setLoading(false);
    }
  };
  


  useEffect(() => {
    if (activeTab === 2 && classDetails && classDetails.teachers) {
      fetchTeachers(classDetails.teachers);
      fetchStudents(classDetails.students);
    }
    else if(activeTab === 1 && classDetails && classDetails.students){
        fetchProjects(classDetails.projects);
        fetchStudents(classDetails.students);
    }
    else if(activeTab === 3 && classId){
      fetchAssignments();
    }
  }, [activeTab, classDetails]);

  const cleanPhotoURL = (url) => {
    const plink = url.replace(/['"]/g, '');  
    return plink;
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  

  if (loading) {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  const handleTabChange = (index) => {
    setActiveTab(index);
    sessionStorage.setItem("activeTabInStudentClass", index.toString());
  };


  return (
      <Box w={'full'}>
        <Tabs index={activeTab} onChange={handleTabChange}>
          <TabList mb="1em">
            <Tab>Announcements</Tab>
            <Tab>Projects</Tab>
            <Tab>People</Tab>
            <Tab>Assignments</Tab>
            {classDetails?.isLearnQEnabled && fileText && (
            <Tab>LearnQ</Tab>
            )}
            {classDetails?.isLearnCodeEnabled && (
            <Tab>Learncode</Tab>
            )}
            {/* <Tab>Submit Code(coming soon)</Tab> */}
          </TabList>
          <TabPanels>
          <TabPanel>
            <Box p={4} ml={-4}>
              <Box position="relative" w="full" h="200px" bg="gray.200" display="flex" alignItems="center" justifyContent="center" mb={6}>
                <Image borderRadius="md" objectFit="cover" w="full" h="full" src={classDetails?.url} alt={classDetails?.classname} />
                <Box position="absolute" bottom={2} left={2} color="white" fontWeight="bold" fontSize="lg" display="flex" flexDirection="column" alignItems="flex-start">
                  <Text>{classDetails?.classname}</Text>
                  <Text fontSize="md">{classDetails?.term}</Text>
                </Box>
              </Box>
            <Flex>
              <Box
                w="100%"
                p={4}
                bg="white"
              >
                <Box mb={4} p={4} borderWidth="1px" borderRadius="lg" boxShadow={"md"} bg="white">
                  {/* <Heading size="md" mb={4}>Create Announcement</Heading> */}
                  <Textarea
                    placeholder="Write a new announcement..."
                    value={newAnnouncement}
                    onChange={(e) => {
                      setNewAnnouncement(e.target.value);
                      setAnnouncementError(''); 
                    }}
                    isInvalid={announcementError.length > 0}
                    errorBorderColor="red.300"
                  />
                  <Text color="red.500" mt={2}>{announcementError}</Text>
                  <Button
                  mt={2}
                  _hover={{ background: "purple.900", color: "white" }}
                  onClick={handleAnnouncementSubmit}
                  cursor="pointer"
                  isDisabled={postcommentloading}
                >
                  {postcommentloading ? "Posting..." : "Post"}
                </Button>
                </Box>

                <Box width="100%">
                <VStack spacing={4} align="start">
                  {announcements.map(announcement => (
                    <Box
                      width="100%"
                      p={4}
                      borderWidth="1px"
                      borderRadius="lg"
                      boxShadow="md"
                      key={announcement._id}
                      bg="white"
                    >
                      <Flex align="center" mb={2}>
                        <Avatar src={cleanPhotoURL(announcement.user.photoURL)} size="sm" mr={2} />
                        <Box>
                          <Text fontSize="md" fontWeight="bold">
                            {announcement.user.name}
                          </Text>
                          <Text fontSize="sm" color="gray.500">
                            {formatDate(announcement.time)}
                          </Text>
                        </Box>
                      </Flex>
                      <Text fontSize="sm" fontWeight="normal">
                        {announcement.announcement}
                      </Text>
                      <br />
                      <Divider my={4} borderWidth={1} width="calc(100% + 2rem)" ml={-4} />
                      {announcement.comments.length > 0 && (
                        <Button
                        mt={2}
                        size={'md'}
                        cursor="pointer"
                        onClick={() => toggleComments(announcement._id)}
                      >
                        Class comments
                      </Button>
                      )}
                      {showComments[announcement._id] && announcement.comments.length > 0 && (
                        <VStack spacing={2} mt={2} align="start">
                          {announcement.comments.map((comment, index) => (
                            <Box key={index} width="100%" mt={2}>
                              <Flex align="center" mb={2}>
                                <Avatar src={cleanPhotoURL(comment.user.photoURL)} size="sm" mr={2} />
                                <Box>
                                <Flex>
                                  <Text fontSize="sm" fontWeight="bold">
                                    {comment.user.name}
                                  </Text>
                                  <Text ml={2} fontSize="sm" color="gray.500">
                                    {formatDate(comment.time)}
                                  </Text>
                                  </Flex>
                                  <Text>{comment.comment}</Text>
                                </Box>
                              </Flex>
                            </Box>
                          ))}

                        </VStack>
                      )}
                      <Box width="100%" mt={4}>
                        <Textarea
                          placeholder="Write a comment..."
                          value={newComment[announcement._id] || ''}
                          onChange={(e) => {
                            handleCommentChange(announcement._id, e.target.value);
                            setCommentErrors(prevState => ({
                              ...prevState,
                              [announcement._id]: ''
                            }));
                          }}
                          isInvalid={commentErrors[announcement._id]?.length > 0}
                          errorBorderColor="red.300"
                        />
                        {commentErrors[announcement._id] && (
                          <Text color="red.500" mt={2}>
                            {commentErrors[announcement._id]}
                          </Text>
                        )}
                        <Button
                          _hover={{ background: 'purple.900', color: 'white' }}
                          mt={2}
                          onClick={() => handleCommentSubmit(announcement._id)}
                        >
                          Comment
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </VStack>
              </Box>


              </Box>
            </Flex>
            </Box>
          </TabPanel>

            <TabPanel>
              <Box>
                <Flex mb={2} justify="space-between" align="center">
                  <Heading size="md">Projects</Heading>
                </Flex>
                {projectLoading ? (<LoadingSpinner />) : projects.length === 0 ? (
                  <Flex direction="column" align="center" justify="center" height="200px">
                    <AiOutlineExclamationCircle size={40} color="gray.500" />
                    <Text mt={4} fontSize="lg" color="gray.500">
                      No projects found
                    </Text>
                  </Flex>
                ) : (
                  <Flex direction="column" alignItems="start" mx="2" ml={-4}>
                  <Box p={3}>
                    <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                      {projects?.map((project, index) => (
                        <ProjectCard key={project._id} project={project} classId={classDetails._id} index={index} />
                      ))}
                    </SimpleGrid>
                  </Box>
                </Flex>
                )}
              </Box>
            </TabPanel>
            

            <TabPanel>
              <Box p={4}>
                <Heading size="md" mb={4}>People</Heading>
                <Tabs variant="soft-rounded" colorScheme="purple">
                  <TabList>
                    <Tab>Teachers</Tab>
                    <Tab>Students</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <VStack spacing={4} align="start">
                        {teacherLoading ? (
                          <LoadingSpinner />
                        ) : teachers.length === 0 ? (
                          <Text>No teachers found.</Text>
                        ) : (
                          teachers.map((teacher) => (
                            <Flex
                              key={teacher._id}
                              p={4}
                              borderWidth="1px"
                              borderRadius="lg"
                              align="center"
                              width="full"
                            >
                              <Avatar name={teacher.name} src={teacher.photoURL} />
                              <Box ml={4}>
                                <Text fontWeight="bold">{teacher.name}</Text>
                                <Text>{teacher.email}</Text>
                              </Box>
                            </Flex>
                          ))
                        )}
                      </VStack>
                    </TabPanel>
                    <TabPanel>
                      <VStack spacing={4} align="start">
                      {studentLoading ? (
                          <LoadingSpinner />
                        ) : students.length === 0 ? (
                            <Text>No students found.</Text>
                        ) 
                        
                         : (
                          students.map((student) => (
                            <Flex
                              key={student._id}
                              p={4}
                              borderWidth="1px"
                              borderRadius="lg"
                              align="center"
                              width="full"
                            >
                                
                              <Avatar name={student.name} src={student.photoURL} />
                              <Box ml={4}>
                                <Text fontWeight="bold">{student.name}</Text>
                                <Text>{student.email}</Text>
                              </Box>
                            </Flex>
                          ))
                        )}
                      </VStack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
            <TabPanel>
              <LearnQHome isEnabled={classDetails?.isLearnQEnabled} setActiveTab={setActiveTab} assignments={assignments} setFileText={setFileText} setCurrentTId={setCurrentTId} classId={classId}  setAssignmentId={setAssignmentId}/> 
            </TabPanel>
            {fileText && (
              <TabPanel>
                <LearnQ 
                  setActiveTab={setActiveTab}
                  fileText={fileText} 
                  currentTId={currentTId} 
                  setFileText={setFileText} 
                  classId={classId}  
                  assignmentId={assignmentId} 
                  setAssignmentId={setAssignmentId} 
                /> 
              </TabPanel>
            )}

            <TabPanel>
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                mt={160}
              >
                <VStack spacing={6} p={8} bg="white">
                  <Heading fontSize="lg" color="purple.900" textAlign="center">
                    Start Learning front-end Coding Assignments with AI Mentor
                  </Heading>
                  <Button 
                    bg={'purple.900'}
                    color={'white'}
                    _hover={{bg:"purple.900", color:'white'}}
                    size="md" 
                    onClick={() => window.open(`/student-portal/${classId}/learncode`, '_blank')}
                  >
                    Access Learning Page
                  </Button>
                </VStack>
                </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default ClassView;

