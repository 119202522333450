import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import TLogin from './components/teacher-portal/Login/Login.tsx';
import SLogin from './components/student-portal/Login/Login.tsx'; 
import ProtectedRoute from './components/protectedRoute/protectedRoute.tsx';
import StudentProtectedRoute from './components/protectedRoute/StudentProtectedRoute.tsx';
import HomeFrame1 from './components/teacher-portal/HomeFrame/HomeFrame.tsx';
import ClassView1 from './components/student-portal/ClassView/ClassView.tsx';
import ClassView2 from './components/teacher-portal/ClassView/ClassView.tsx';
import ProjectView2 from './components/teacher-portal/ProjectView/ProjectView.tsx';
import ProjectView1 from './components/student-portal/ProjectView/ProjectView.tsx';
import Message1 from './components/student-portal/Messaging/Message.tsx';
import Message2 from './components/teacher-portal/Messaging/Message.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { StudentAuthProvider } from './contexts/StudentAuthContext.tsx';
import HomeFrame2 from './components/student-portal/HomeFrame/HomeFrame.tsx';
import LandingPage from './components/Landingpage/LandingPage.jsx';
import GroupView2 from './components/teacher-portal/GroupView/GroupView.tsx';
import GroupView1 from './components/student-portal/GroupView/GroupView.tsx';
import ChatGPTHistory1 from './components/student-portal/GPTHistory/GPTHistory.tsx'
import ChatGPTHistory2 from './components/teacher-portal/GPTHistory/GPTHistory.tsx'
import './App.css'
import LearnQ from './components/student-portal/LearnQ/Home.jsx';
import LearnQ2 from './components/teacher-portal/LearnQ/Home.jsx';
import Config from './components/teacher-portal/Config/Home.jsx';
import AdminProtectedRoute from './components/protectedRoute/AdminProtectedRoute.tsx'
import ALogin from './components/admin-portal/Login/Login';
import Adenied from './components/admin-portal/AccessDenied/AccessDenied.jsx';

import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import Dashboard from './components/admin-portal/Home/Dashboard.tsx';
import TeacherRequests from './components/admin-portal/TeacherRequests/TeacherRequests.tsx';
import Teachers from './components/admin-portal/Teachers/Teachers.tsx';
import Students from './components/admin-portal/Students/Students.tsx';
import Classes from './components/admin-portal/Classes/Classes.tsx';
import Processing from './components/Processing/page.tsx';
import SessionExpiryModal from './expiryModal.jsx';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { setupStorageListener } from './axiosUtils.ts';
import { Box, Flex, useToast } from "@chakra-ui/react";
import Chat from './components/chat/Chat.tsx';
import { SideNav } from './components/sidebar/SideNav.tsx';
import MobileNav from './components/sidebar/MobileNav.tsx';

import AssignmentChallengesPage from './components/LearnCode/AssignmentChallenges/AssignmentChallengesPage.tsx';
import AssignmentChallengeViewPage from './components/LearnCode/AssignmentChallenges/AssignmentChallengeViewPage.tsx';
import AssignmentsPage from './components/LearnCode/Assignments/AssignmentsPage.tsx';
import ChallengesListPage from './components/LearnCode/Challenges/ChallengesListPage.tsx';
import ChallengeViewPage from './components/LearnCode/Challenges/ChallengeViewPage.tsx';
import ConceptsPage from './components/LearnCode/Challenges/ConceptsPage.tsx';
import LearningPathPage from './components/LearnCode/Challenges/LearningPathPage.tsx';
import LearnCodeLandingPage from './components/LearnCode/LandingPage.tsx';
import Learncode from './components/teacher-portal/LearnCode/Home.jsx';
import { app } from './config.js';



function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const location = useLocation(); 
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const cleanup = setupStorageListener();
    return () => {
      cleanup(); 
    };
  }, []);

  const handleRefreshToken = async () => {
    try {
      setModalOpen(false);
      
      const userType = sessionStorage.getItem("userType");
      let tokenExpirationKey = null;
      let at = null;

      if(userType){
      switch (userType) {
        case "teacher":
          tokenExpirationKey = "TokenExpirationTime";
          at = "googleAccessToken";
          break;
        case "student":
          tokenExpirationKey = "studentTokenExpirationTime";
          at = "studentGoogleAccessToken";
          break;
        case "admin":
          tokenExpirationKey = "AdminTokenExpirationTime";
          at="adminGoogleAccessToken";
          break;
        default:
          console.warn("Invalid user type. Redirecting to login.");
          sessionStorage.clear();
          localStorage.clear();
          navigate('/');
          return;
      }
    }

    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
      const currentUser = auth.currentUser;
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
  
      if (currentUser?.email === loggedInUser?.email) {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = await loggedInUser.getIdToken();
        localStorage.setItem("idToken", idToken); 
        const token = credential.accessToken;
        localStorage.setItem(at, token);
        const access = await currentUser.getIdTokenResult(true);
        const newExpirationTime = new Date(access.expirationTime).toISOString();
        localStorage.setItem(tokenExpirationKey, newExpirationTime);
        window.dispatchEvent(new Event('storage'));
        

        await new Promise((resolve) => setTimeout(resolve, 500));
  
        toast({
          title: "GenI",
          description: "Token refreshed successfully.",
          status: "success",
          duration: 2000, 
          isClosable: true,
        });

      } else {
        throw new Error("Different email used. Please use the same email to login.");
      }
    } catch (error) {
      setModalOpen(false);
      console.error("Error refreshing token:", error);
      toast({
        title: "Error refreshing token",
        description: error.message || "Please try again.",
        status: "error",
        duration: 2000, 
        isClosable: true,
      });
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
      }, 2000);
    }
  };
  
  
  
  const handleLogout = async () => {
    try {
      const auth = getAuth(app);
      await signOut(auth);
      sessionStorage.clear();
      localStorage.clear();
      setModalOpen(false);
      navigate('/')
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const checkSessionExpiry = async () => {
    const auth = getAuth(app);
    const userType = sessionStorage.getItem("userType");
    let tokenExpirationKey = null;
    if(userType){
    switch (userType) {
      case "teacher":
        tokenExpirationKey = "TokenExpirationTime";
        break;
      case "student":
        tokenExpirationKey = "studentTokenExpirationTime";
        break;
      case "admin":
        tokenExpirationKey = "AdminTokenExpirationTime";
        break;
      default:
        console.warn("Invalid user type. Redirecting to login.");
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
        return;
    }
  }

    const expirationTimeString = localStorage.getItem(tokenExpirationKey);
    const expirationTime = expirationTimeString ? new Date(expirationTimeString) : null;
    const currentTime = new Date();

    if (expirationTime != null && currentTime >= expirationTime) {
      await signOut(auth);
      sessionStorage.clear();
      localStorage.clear();
      navigate('/');
      return;
    }

    const warningTime = new Date(expirationTime - 5 * 60 * 1000);
    if (
      currentTime >= warningTime &&
      location.pathname !== '/' &&
      !location.pathname.includes('/login') &&
      expirationTime != null
    ) {
      setModalOpen(true);
    }
    else{
      setModalOpen(false);
    }
  };
  
  useEffect(() => {
    checkSessionExpiry();
    const timer = setInterval(checkSessionExpiry, 30000);
    return () => clearInterval(timer);
}, [location, navigate]);
  
  
  const noHeaderSideNavRoutes = [
    "/teacher-portal/login",
    "/admin-portal/login",
    "/accessdenied",
    "/processing",
    "/student-portal/login",
    "/",
    "/privacypolicy",
    "/student-portal/:classId/learncode",
    "/student-portal/:classId/learncode/assignments",
    "/student-portal/:classId/learncode/concepts",
    "/student-portal/:classId/learncode/learn/:concept",
    "/student-portal/:classId/learncode/challenges/:concept",
    "/student-portal/:classId/learncode/challenge/:challengeId/:concept",
    "/student-portal/:classId/learncode/assignment-challenges/:assignmentId",
    "/student-portal/:classId/learncode/:assignmentId/assignment-challenge/:challengeId" 

  ];

  const hideHeaderAndSideNav = noHeaderSideNavRoutes.some(route =>
    new RegExp(`^${route.replace(/:\w+/g, '[^/]+')}$`).test(location.pathname)
  );
  

  
  return (
    <div>
      <StudentAuthProvider>
        <AuthProvider>
          <Routes>
          <Route path="/teacher-portal/login" element={<TLogin />} />
            <Route path="/admin-portal/login" element={<ALogin />} />
            <Route path="/accessdenied" element={<Adenied />} />
            <Route path="/processing" element={<Processing />} />
            <Route path="/student-portal/login" element={<SLogin />} /> 
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/student-portal/:classId/learncode" element={<StudentProtectedRoute element={<LearnCodeLandingPage />} />} />
            <Route path="/student-portal/:classId/learncode/assignments" element={<StudentProtectedRoute element={<AssignmentsPage />} />} /> 
            <Route path="/student-portal/:classId/learncode/concepts" element={<StudentProtectedRoute element={<ConceptsPage />} />} />
            <Route path="/student-portal/:classId/learncode/learn/:concept" element={<StudentProtectedRoute element={<LearningPathPage />} />} />
            <Route path="/student-portal/:classId/learncode/challenges/:concept" element={<StudentProtectedRoute element={<ChallengesListPage />} />} />
            <Route path="/student-portal/:classId/learncode/challenge/:challengeId/:concept" element={<StudentProtectedRoute element={<ChallengeViewPage />} />} />
            <Route path="/student-portal/:classId/learncode/assignment-challenges/:assignmentId" element={<StudentProtectedRoute element={<AssignmentChallengesPage />} />} />
            <Route path="/student-portal/:classId/learncode/:assignmentId/assignment-challenge/:challengeId" element={<StudentProtectedRoute element={<AssignmentChallengeViewPage />} />} />
            <Route
              path="/admin-portal/home"
              element={<AdminProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/admin-portal/teacherrequests"
              element={<AdminProtectedRoute element={<TeacherRequests />} />}
            />
            <Route
              path="/admin-portal/teachers"
              element={<AdminProtectedRoute element={<Teachers />} />}
            />
            <Route
              path="/admin-portal/students"
              element={<AdminProtectedRoute element={<Students />} />}
            />
            <Route
              path="/admin-portal/classes"
              element={<AdminProtectedRoute element={<Classes />} />}
            />
          </Routes>
        {!hideHeaderAndSideNav && (
        <Box>
          <SideNav />
          <Flex
            direction="column"
            ml="280px"
            w="calc(100% - 280px)"
            minH="100vh"
          >
            <MobileNav />
            <Box
              as="main"
              p="6"
              mt="64px" // Height of MobileNav
            >
          <Routes>
            <Route
              path="/teacher-portal/:projectId/chat"
              element={<ProtectedRoute element={<Chat />} />}
            />
            <Route
              path="/teacher-portal/homeframe"
              element={<ProtectedRoute element={<HomeFrame1 />} />}
            />
            <Route
              path="/teacher-portal/learncode/history"
              element={<ProtectedRoute element={<Learncode />} />}
            />
            <Route
              path="/teacher-portal/class/:classId"
              element={<ProtectedRoute element={<ClassView2 />} />}
            />
            <Route
              path="/student-portal/class/:classId"
              element={<StudentProtectedRoute element={<ClassView1 />} />}
            />
            <Route
              path="/student-portal/class/:classId/project/:projectId"
              element={<StudentProtectedRoute element={<ProjectView1 />} />}
            />
            <Route
              path="/teacher-portal/class/:classId/project/:projectId"
              element={<ProtectedRoute element={<ProjectView2 />} />}
            />
            <Route
              path="/teacher-portal/class/:classId/project/:projectId/group/:groupId"
              element={<ProtectedRoute element={<GroupView2 />} />}
            />
            <Route
              path="/student-portal/class/:classId/project/:projectId/group/:groupId"
              element={<StudentProtectedRoute element={<GroupView1 />} />}
            />
            <Route
              path="/teacher-portal/messaging"
              element={<ProtectedRoute element={<Message2 />} />}
            />
            <Route
              path="/student-portal/messaging"
              element={<StudentProtectedRoute element={<Message1 />} />}
            />
            <Route
              path="/student-portal/homeframe"
              element={<StudentProtectedRoute element={<HomeFrame2 />} />}
            />
            <Route
              path="/student-portal/gpthistory"
              element={<StudentProtectedRoute element={<ChatGPTHistory1 />} />}
            />
            <Route
              path="/teacher-portal/gpthistory"
              element={<ProtectedRoute element={<ChatGPTHistory2 />} />}
            />
            <Route
              path="/student-portal/learnq"
              element={<StudentProtectedRoute element={<LearnQ />} />}
            />
            <Route
              path="/teacher-portal/learnq"
              element={<ProtectedRoute element={<LearnQ2 />} />}
            />
            <Route
              path="/teacher-portal/:classId/config"
              element={<ProtectedRoute element={<Config />} />}
            />
          </Routes>
          
          </Box>
          </Flex>
          </Box>
        )}
        </AuthProvider>
      </StudentAuthProvider>
      <SessionExpiryModal
      isOpen={isModalOpen}
      onRefresh={handleRefreshToken}
      onLogout={handleLogout}
      onClose={() => setModalOpen(false)} 
    />
    </div>
  );
}

export default App;

