import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Container, Heading, VStack, Text, Button, SimpleGrid, Badge, useColorMode, useToast, HStack, Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Rocket, Trophy, Brain, Code2, Clock } from 'lucide-react';
import { analyzeAssignment, fetchAssignmentChallenges, getAssignmentChallengesFromAI } from '../services/learn.js';
import React from 'react';
import Navbar from '../NavBar.tsx';
import { FaEye } from 'react-icons/fa';
import axios from 'axios';
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { app } from '../../../config.js';

const isProduction = process.env.NODE_ENV === 'production';

pdfjsLib.GlobalWorkerOptions.workerSrc = isProduction
  ? "https://generativeideas.org/pdf.worker.min.mjs"
  : "/pdf.worker.min.mjs";

const MAX_FILE_SIZE_MB = 5;



const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);


const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 }
  }
};

export default function AssignmentChallengesPage() {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(false);
  const {classId, assignmentId, } = useParams();
  const studentId = sessionStorage.getItem("CurrentLoggedInSId")?.trim();
  const [file, setFile] = useState(null);
  const location = useLocation();
  const [fileText, setFileText] = useState("");
  const [type, setType] = useState("");

  const extractFileData = async (file) => {

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
      return null;
    }

    const fileType = file.type;

    if (fileType.includes('pdf')) {
      const pdfData = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const pageText = await page.getTextContent();
        text += pageText.items.map(item => item.str).join(' ');
      }
      return text;
    } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return result.value;
    } else if (fileType.includes('text/plain')) {
      const textData = await file.text();
      return textData;
    } else {
      alert('Unsupported file type.');
      return null;
    }
  };

const downloadFileWithAccessToken = async (accessToken, fileId) => {
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        });

        const blob = response.data;


  if (response) {
    return blob;
  } else {
    console.error('Error fetching file content:', response.statusText);
  }
};


const extractFileId = (link) => {
  const regex = /\/d\/(.*?)\//;
  const matches = link.match(regex);
  return matches ? matches[1] : null;
};

useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const fileParam = queryParams.get('file');  

  if (fileParam) {
    try {
      const decodedFileData = decodeURIComponent(fileParam);
      const parsedFileData = JSON.parse(decodedFileData);
      setFile(parsedFileData);
    } catch (error) {
      console.error('Error parsing file data from URL:', error);
    }
  }
}, [location]);

  useEffect(() => {
    const loadChallenges = async () => {
      setLoading(true);
      try {
        const result = await fetchAssignmentChallenges(studentId, classId, assignmentId);
        setChallenges(result?.challenges || []);
      } catch (error) {
        console.error("Error loading challenges:", error);
      } finally {
        setLoading(false);
      }
    };

    loadChallenges();
  }, [studentId, classId, assignmentId])

  useEffect(() => {
    if (!file) return; 
  
    const handleOpenAssignment = async () => {
  
      try {
        setLoading(true);
        const fileId = extractFileId(file.classFiles[0].link);
        let token;
  
        if (fileId) {
          const userType = sessionStorage.getItem("userType");
  
          if (userType === "student") {
            token = localStorage.getItem("studentGoogleAccessToken");
          } else if (userType === "teacher") {
            token = localStorage.getItem("googleAccessToken");
          }
  
          const expirationTime = new Date(localStorage.getItem("studentTokenExpirationTime"));
          const currentTime = new Date();
          const timeLeft = expirationTime - currentTime;
  
          if (timeLeft < 5 * 60 * 1000) {
            const auth = getAuth(app);
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: "select_account" });
            provider.addScope("https://www.googleapis.com/auth/drive");
            provider.addScope("https://www.googleapis.com/auth/drive.file");
            provider.addScope("https://www.googleapis.com/auth/documents");
            const result = await signInWithPopup(auth, provider);
            const loggedInUser = result.user;
            const credential = GoogleAuthProvider.credentialFromResult(result);
            token = credential.accessToken;
            const accessToken = await loggedInUser.getIdTokenResult(true);
  
            if (userType === "student") {
              localStorage.setItem("studentGoogleAccessToken", token);
              localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
            } else if (userType === "teacher") {
              localStorage.setItem("googleAccessToken", token);
              localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
            }
          }
  
          const fileContent = await downloadFileWithAccessToken(token, fileId);
          const fileText = await extractFileData(fileContent);
  
          try {
            const res = await analyzeAssignment(fileText, classId);
            setFileText(fileText);
            setType(res.type);
          } catch (analysisError) {
            console.error("Assignment analysis error:", analysisError);
            toast({
              title: "Analysis Error",
              description: analysisError.message || "Failed to analyze the assignment.",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        console.error("Error opening assignment:", error);
        toast({
          title: "Error",
          description: error.message || "Failed to open the assignment. Please try again.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
  
    handleOpenAssignment();
  }, [file, classId]);

 

  const handleStartFirstChallenge = async () => {
    setLoading(true);
    try {
      if(fileText!==""){
      const result = await getAssignmentChallengesFromAI(fileText, type, classId || '', studentId || '', assignmentId);
      setChallenges(result?.challenges || []);
      
      
      toast({
        title: 'Challenge Created',
        description: 'Your first challenge is ready. Good luck!',
        status: 'success',
        duration: 3000,
      });
    }
    } catch (error) {
      console.error('Error loading challenges:', error);
      toast({
        title: 'Error loading challenges',
        description: 'Failed to load challenges. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNextChallenge = async () => {
    setLoading(true);
    try {
      const last15Challenges = challenges.slice(-15).map(ch => ({
        title: ch.challengeData.title,
        points: ch.points
      }));
      const result = await getAssignmentChallengesFromAI(fileText || '', type, classId || '', studentId || '', assignmentId, last15Challenges || []);
      setChallenges(result?.challenges || []);
    } catch (error) {
      console.error('Error loading next challenge:', error);
      toast({
        title: 'Error loading next challenge',
        description: 'Failed to load the next challenge. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  if (challenges.length === 0) {
    return (
      <>
      <Navbar />
      <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
        <Container maxW="container.xl">
          <MotionBox
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <VStack spacing={8} align="center" textAlign="center">
              <MotionBox variants={itemVariants}>
                <Box
                  p={4}
                  bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                  color={colorMode === 'light' ? 'blue.500' : 'blue.200'}
                  rounded="full"
                  display="inline-block"
                  mb={4}
                >
                  <Rocket size={40} />
                </Box>
                <Heading
                  size="xl"
                  color={colorMode === 'light' ? 'gray.800' : 'white'}
                  mb={4}
                > 
                  Get Started with {file?.filename}
                </Heading>
                <Text
                  fontSize="lg"
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                  maxW="2xl"
                  mb={8}
                >
                  Begin your learning journey with hands-on challenges designed to build your skills from the ground up.
                </Text>
                <Button
                  size="lg"
                  colorScheme="blue"
                  leftIcon={<Brain size={20} />}
                  onClick={handleStartFirstChallenge}
                  isLoading={loading}
                  loadingText="Loading Challenge"
                >
                  Start Your First Challenge
                </Button>
              </MotionBox>
            </VStack>
          </MotionBox>
        </Container>
      </Box>
      </>
    );
  }

  return (
    <>
    <Navbar />
    <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box 
              onClick={() => window.open(file?.classFiles[0]?.link, '_blank')}
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }} 
            >
              <Heading
                size="xl"
                color={colorMode === 'light' ? 'gray.800' : 'white'}
                display="flex"
                alignItems="center"
                gap={3}
              >
                <Trophy size={32} />
                {file?.filename.charAt(0).toUpperCase() + file?.filename.slice(1)}
              </Heading>
            </Box>

            <Button
              colorScheme="blue"
              onClick={handleNextChallenge}
              isLoading={loading}
              leftIcon={<Code2 size={20} />}
            >
              Next Challenge
            </Button>
          </Box>

          <MotionSimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={6}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {challenges.map((challenge) => (
              <MotionBox
                key={challenge.challengeId}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                p={6}
                bg={colorMode === 'light' ? 'white' : 'gray.800'}
                rounded="xl"
                shadow="lg"
                borderWidth="1px"
                borderColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
              >
                <VStack align="stretch" spacing={4} height="100%">
                <HStack spacing={3} align="center">
                  <Box
                    p={3}
                    bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                    color={colorMode === 'light' ? 'blue.500' : 'blue.200'}
                    rounded="lg"
                    width="fit-content"
                  >
                     <Code2 size={24} />
                  </Box>
                  <Badge colorScheme={challenge.progress === 100 ? 'green' : 'blue'}>
                    Total Points: {challenge.points}
                  </Badge>
                  {challenge?.challengeData?.timeToComplete && (
                  <Badge colorScheme={challenge.progress === 100 ? 'green' : 'blue'} display="flex" alignItems="center" gap={1} >
                  <Clock size={14} /> {/* Reduce size */}
                  <span>{challenge?.challengeData?.timeToComplete} min</span>
                </Badge>                
                  )}

                </HStack>


                  <Box>
                    <Heading size="md" mb={2}>
                      {challenge.challengeData.title}
                    </Heading>
                    <Text 
                      color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                      noOfLines={3}
                    >
                      {challenge.challengeData.description}
                    </Text>
                  </Box>

                  <Box mt="auto">
                    {challenge.viewedSolution && (
                    <HStack mb={4} align="center">
                      <Icon as={FaEye} color="gray.500" boxSize={3} />
                      <Text fontSize="xs" color="gray.500">Viewed Solution</Text>
                    </HStack>
                  )}
                    <HStack mb={4}>
                      <Badge colorScheme={challenge.progress === 100 ? 'green' : 'blue'}>
                        awarded points: {challenge.awardedPoints} 
                      </Badge>
                    </HStack>

                    <Button
                      colorScheme={challenge.awardedPoints > 0 ? 'green' : 'blue'}
                      onClick={() => navigate(`/student-portal/${classId}/learncode/${assignmentId}/assignment-challenge/${challenge.challengeId}`)}
                      leftIcon={challenge.awardedPoints > 0 ? <Trophy size={18} /> : <Code2 size={18} />}
                      width="100%"
                    >
                      {challenge.awardedPoints > 0 ? 'Restart challenge' : 'Start Challenge'}
                    </Button>
                  </Box>

                </VStack>
              </MotionBox>
            ))}
          </MotionSimpleGrid>
        </VStack>
      </Container>
    </Box>
    </>
  );
}