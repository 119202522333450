import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import QuestionTable from "./QuestionHistory.jsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  

  return (
      <Box height="90%" w={'full'}>
        <Tabs index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="2">
            <Tab>Questions History</Tab>
          </TabList>
         
          <TabPanels>
            
            <TabPanel>
              <QuestionTable /> 
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default Message;
