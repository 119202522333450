import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Text,
    Spinner,
    VStack,
    UnorderedList,
    ListItem,
    IconButton,
} from "@chakra-ui/react";
import html2pdf from 'html2pdf.js';

import { fetchSRSforProject } from '../../../Services/Student/studentService.tsx';
import { useParams } from 'react-router';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import { Rnd } from 'react-rnd';
import { FiX } from 'react-icons/fi';

const SRSHistory = () => {
    const { projectId } = useParams();
    const [isDocumentOpen, setIsDocumentOpen] = useState(false);
    const [documentSize, setDocumentSize] = useState({ width: 800, height: 500 });
    const [documentPosition, setDocumentPosition] = useState({ x: 0, y: 0 });
    const [srsHistory, setSRSHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const { isOpen: isOpenBacklog, onOpen: onOpenBacklog, onClose: onCloseBacklog } = useDisclosure();
    const { isOpen: isChatHistoryOpen, onOpen: onOpenChatHistory, onClose: onCloseChatHistory } = useDisclosure();

    const [selectedBacklog, setSelectedBacklog] = useState(null);
    const [selectedChatHistory, setSelectedChatHistory] = useState([]);
    const [chatBoxSize, setChatBoxSize] = useState({ width: 400, height: 500 });
    const [chatBoxPosition, setChatBoxPosition] = useState({ x: 0, y: 0 });
    const onCloseDocument = () => setIsDocumentOpen(false);
    
    const calculateCenterPosition = (width, height) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        
        return {
          x: (viewportWidth - width) / 2,
          y: scrollTop + (viewportHeight - height) / 2
        };
      };

    // Fetch SRS history on component mount
    useEffect(() => {
        const fetchSRSHistoryData = async () => {
            setLoading(true);
            try {
                const response = await fetchSRSforProject(projectId); 
                setSRSHistory(response[0] || []); 
                
            } catch (error) {
                console.error("Error fetching SRS history:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSRSHistoryData();
    }, [projectId]);

    const handleFileClick = (document) => {
        setSelectedDocument(document);
        setDocumentPosition(calculateCenterPosition(documentSize.width, documentSize.height));
        setIsDocumentOpen(true);
    };
    const generateSRSPDF = () => {
        const element = document.getElementById("srs_document");
        const options = {
            margin: 0.5,
            filename: 'SRS.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: [10, 11],
                orientation: 'portrait'
            }
        };

        html2pdf().from(element).set(options).save();
    };

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });


    const sortData = (key) => {
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
    
        const sortedData = [...srsHistory.srsData].sort((a, b) => {
            if (key === 'date') {
                
                return direction === 'ascending'
                    ? new Date(b.updatedAt) - new Date(a.updatedAt)
                    : new Date(a.updatedAt) - new Date(b.updatedAt);  
            }
            if (key === 'name') {
                
                return direction === 'ascending' 
                    ? a.studentId.name.localeCompare(b.studentId.name) 
                    : b.studentId.name.localeCompare(a.studentId.name);
            }
            return 0; 
        });
    
        
        setSRSHistory({ ...srsHistory, srsData: sortedData });
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const handleResize = () => {
            if (isDocumentOpen) {
                setDocumentPosition(calculateCenterPosition(documentSize.width, documentSize.height));
            }
            if (isChatHistoryOpen) {
                setChatBoxPosition(calculateCenterPosition(chatBoxSize.width, chatBoxSize.height));
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isDocumentOpen, isChatHistoryOpen, documentSize, chatBoxSize]);

    const handleChatHistoryClick = (chatHistory) => {
        setSelectedChatHistory(JSON.parse(chatHistory));
        setChatBoxPosition(calculateCenterPosition(chatBoxSize.width, chatBoxSize.height));
        onOpenChatHistory();
    };
    
    

    return (
        <Flex direction="column" alignItems="center" p={4}>
            
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <Box width={"100%"} p={6} mx="auto" mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
                <Heading fontSize="lg" mb={4}>Your project SRS</Heading>
                    <Table variant="simple" size="md">
                    <Thead>
                        <Tr>
                            <Th onClick={() => sortData('name')} style={{ cursor: 'pointer' }}>
                                Student Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                            </Th>
                            <Th>project backlog</Th>
                            <Th>Chat history</Th>
                            <Th>File</Th>
                            <Th>Non-Functional?</Th>
                            <Th onClick={() => sortData('date')} style={{ cursor: 'pointer' }}>
                                Date {sortConfig.key === 'date' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                            </Th>
                        </Tr>
                    </Thead>
                        <Tbody>
                            {srsHistory?.srsData?.map((entry) => (
                                <Tr key={entry._id}>
                                    <Td>{entry.studentId.name}</Td> 
                                    <Td>
                                        {entry?.projectBacklog 
                                            ? entry.projectBacklog.length > 10 
                                                ? <>
                                                    {entry.projectBacklog.substring(0, 10)}...
                                                    <Button size="xs" variant="link" color="purple.900" onClick={() => {
                                                        setSelectedBacklog(entry.projectBacklog);
                                                        onOpenBacklog();
                                                    }}>
                                                        View More
                                                    </Button>
                                                </>
                                                : entry.projectBacklog
                                            : "N/A"
                                        }
                                    </Td>

                                    <Td>
                                        {entry?.chatHistory
                                            ? <Button size="sm" variant="link" color="purple.900" onClick={() => handleChatHistoryClick(entry.chatHistory)}>
                                                View Chat History
                                            </Button>
                                            : "N/A"
                                        }
                                    </Td>

                                    <Td>
                                        <Button variant="link" onClick={() => handleFileClick(entry)} color="purple.900">
                                            View Document
                                        </Button>
                                    </Td>
                                    <Td>{entry?.isNonFunctionalRequired==true? 'True' : 'False'}</Td>
                                    <Td>{new Date(entry.updatedAt).toLocaleString()}</Td> {/* Adjust for your date structure */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}

            {/* Modal for displaying selected document details */}
            {isDocumentOpen && (
                <Rnd
                    size={{ width: documentSize.width, height: documentSize.height }} // Apply dynamic size
                    position={documentPosition} // Apply dynamic position
                    minWidth={400}
                    minHeight={300}
                    dragHandleClassName="handle"
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setDocumentSize({ width: parseInt(ref.style.width), height: parseInt(ref.style.height) });
                    }}
                    onDragStop={(e, d) => {
                        setDocumentPosition({ x: d.x, y: d.y });
                    }}
                >
                    <Box width="100%" height="100%" bg="gray.50" color="black" borderRadius="xl" display="flex" flexDirection="column" boxShadow="2xl">
                        {/* Header with Close Button */}
                        <Flex className="handle" cursor="move" alignItems="center" justifyContent="space-between" bg="purple.900" p={2} borderTopRadius="xl" color="white">
                            <Text ml={2} fontWeight="bold">Document Details</Text>
                            <IconButton size="sm" icon={<FiX />} aria-label="Close" variant="ghost" color="white" onClick={onCloseDocument} />
                        </Flex>

                        {/* Document Content */}
                        <Box p={4} overflowY="auto" flex="1">
                            <VStack spacing={4} align="start">
                                {selectedDocument && selectedDocument.srsData.map((srs, index) => (
                                    <Box id='srs_document' key={index} p={4} borderWidth={1} borderRadius="md">
                                        <Heading mb={4} size="md">Functional and Non-Functional Requirements</Heading>

                                        {/* Show Functional Requirements only when isNonFunctionalRequired is false */}
                                        {!selectedDocument.isNonFunctionalRequired && (
                                            <>
                                                <Heading mb={4} size="sm">1a. Functional Requirements</Heading>
                                                
                                                {srs["1 Functional and Non-Functional Requirements"]["1a"][" Functional Requirements"]["Requirements"].map((req, idx) => (
                                                    <Box mb={2} key={idx} borderWidth="1px" borderRadius="md" p={4}>
                                                        <Heading mb={2} size="sm">{req["Requirement Shell"]["Requirement #"]}</Heading>
                                                        <Text mb={2}>{req["Requirement Shell"]["Description"]}</Text>
                                                        <Text mb={2} fontWeight="bold">Rationale:</Text>
                                                        <Text mb={2}>{req["Requirement Shell"]["Rationale"]}</Text>
                                                        <Text mb={2} fontWeight="bold">Fit Criterion:</Text>
                                                        <UnorderedList>
                                                            {req["Requirement Shell"]["Fit Criterion"].map((criterion, idx) => (
                                                                <ListItem mb={2} key={idx}>{criterion}</ListItem>
                                                            ))}
                                                        </UnorderedList>
                                                        <Text mb={2} fontWeight="bold">Preconditions:</Text> 
                                                        <Text mb={2}>{req["Requirement Shell"]["Preconditions"]}</Text>
                                                        <Text mb={2} fontWeight="bold">Postconditions:</Text> 
                                                        <Text mb={2}>{req["Requirement Shell"]["Postconditions"]}</Text>
                                                        <Text mb={2} fontWeight="bold">Dependencies:</Text> 
                                                        <Text mb={2}>{req["Requirement Shell"]["Dependencies"]}</Text>
                                                        <Text mb={2} fontWeight="bold">Supporting Materials:</Text> 
                                                        <Text mb={2}>{req["Requirement Shell"]["Supporting Materials"]}</Text>
                                                    </Box>
                                                ))}
                                            </>
                                        )}

                                        {/* Show Non-Functional Requirements only when isNonFunctionalRequired is true */}
                                        {selectedDocument.isNonFunctionalRequired && (
                                            <>
                                                <Heading mb={3} mt={3} size="sm">1b. Non-Functional Requirements</Heading>
                                                
                                                {srs["1 Functional and Non-Functional Requirements"]["1b"][" Non-Functional Requirements"].map((req, idx) => (
                                                    <Box mb={2} key={idx} borderWidth="1px" borderRadius="md" p={4}>
                                                        <Text mb={2}>{req.Description}</Text>
                                                        <Text mb={2} fontWeight="bold">Rationale: {req.Rationale}</Text>
                                                    </Box>
                                                ))}
                                            </>
                                        )}
                                    </Box>
                                ))}
                            </VStack>
                        </Box>

                        {/* Footer with Close & Download Button */}
                        <Flex p={2} justifyContent="space-between" bg="gray.100" borderBottomRadius="xl">
                            <Button onClick={onCloseDocument}>Close</Button>
                            <Button bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }} onClick={generateSRSPDF}>
                                Download
                            </Button>
                        </Flex>
                    </Box>
                </Rnd>
            )}

            <Modal isOpen={isOpenBacklog} onClose={onCloseBacklog} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Project Backlog</ModalHeader>
                <ModalBody>
                    <Text>{selectedBacklog}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCloseBacklog}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        {isChatHistoryOpen && (
                <Rnd
                    size={{ width: chatBoxSize.width, height: chatBoxSize.height }} 
                    position={chatBoxPosition}
                    minWidth={300}
                    minHeight={300}
                    dragHandleClassName="handle"
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setChatBoxSize({ width: ref.style.width, height: ref.style.height });
                    }}
                    onDragStop={(e, d) => {
                        setChatBoxPosition({ x: d.x, y: d.y });
                    }}
                >
                    <Box width="100%" height="100%" bg="gray.50" color="black" borderRadius="xl" display="flex" flexDirection="column" boxShadow="2xl">
                        {/* Chat Header */}
                        <Flex className="handle" cursor="move" alignItems="center" justifyContent="space-between" bg="purple.900" p={2} borderTopRadius="xl" color="white">
                            <Text ml={2} fontWeight="bold">Chat History</Text>
                            <IconButton size="sm" icon={<FiX />} aria-label="Close" variant="ghost" color="white" onClick={onCloseChatHistory} />
                        </Flex>

                        {/* Chat Messages */}
                        <VStack p={2} flex="1" overflowY="auto" bg="#f7f7f8" width="100%" position="relative" flexDirection="column-reverse">
                            {[...selectedChatHistory].reverse().map((msg, idx) => (
                                <Flex key={idx} align="center" justify={msg.role === 'user' ? 'flex-end' : 'flex-start'} w="100%">
                                    <Box bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'} p={2} m={2} borderRadius="xl" maxWidth="80%" wordBreak="break-word">
                                        <Text>{msg.content}</Text>
                                    </Box>
                                </Flex>
                            ))}
                        </VStack>
                    </Box>
                </Rnd>
            )}



        </Flex>
        
    );
};

export default SRSHistory;
