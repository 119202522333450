import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Radio, 
  RadioGroup, 
  Stack, 
  Checkbox, 
  Tabs, 
  TabPanels, 
  TabPanel, 
  IconButton, 
  Flex, 
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { saveConfig, getConfig } from "../../../Services/Teacher/teacherService.tsx";
import { useParams } from "react-router-dom";

const Config = () => {
  const [giGPTEnabled, setGiGPTEnabled] = useState(false);
  const [learnQEnabled, setLearnQEnabled] = useState(false);
  const [learnCodeEnabled, setLearnCodeEnabled] = useState(false);
  const [srsEnabled, setSrsEnabled] = useState(false);
  const [selectedAI, setSelectedAI] = useState("gpt");
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false); 
  const [apiKeys, setApiKeys] = useState({
    gpt: "",
    azureAI: "",
  }); 
  const { classId } = useParams();
  const toast = useToast();
  const teacherId = sessionStorage.getItem("CurrentLoggedInTId");
  const storedClassName = sessionStorage.getItem(`class_${classId}`);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfig(classId);
        if (config) {
          setSelectedAI(config.selectedAI);
          setGiGPTEnabled(config?.classId?.isGigptEnabled || false);
          setLearnQEnabled(config?.classId?.isLearnQEnabled || false);
          setLearnCodeEnabled(config?.classId?.isLearnCodeEnabled || false);
          setSrsEnabled(config?.classId?.isSRSEnabled || false);

          const newApiKeys = { 
            gpt: config.selectedAI === "gpt" ? config.key : "", 
            azureAI: config.selectedAI === "azureAI" ? config.key : ""
          };
          setApiKeys(newApiKeys);
          setApiKey(newApiKeys[config.selectedAI] || "");
        }
      } catch (error) {
        console.error("Error loading configuration:", error);
        toast({
          title: "Error",
          description: "Failed to load configuration.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    fetchConfig();
  }, [teacherId, toast]);

  const handleSave = async () => {
    if (giGPTEnabled && !apiKey) {
      toast({
        title: "API Key Required",
        description: "Please enter an API key.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const configData = { 
        teacherId, 
        classId,
        isGiGPTEnabled: giGPTEnabled, 
        isLearnQEnabled: learnQEnabled, 
        isLearnCodeEnabled: learnCodeEnabled, 
        isSRSEnabled: srsEnabled,
        selectedAI: giGPTEnabled ? selectedAI : null, 
        key: giGPTEnabled ? apiKey : null 
      };
      await saveConfig(configData);

      toast({
        title: "Configuration Saved",
        description: `Settings updated successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setApiKeys((prevKeys) => {
        const newKeys = { ...prevKeys };
        if (selectedAI === "gpt") {
          delete newKeys.azureAI; 
        } else if (selectedAI === "azureAI") {
          delete newKeys.gpt; 
        }
        newKeys[selectedAI] = apiKey; 
        return newKeys;
      });

    } catch (error) {
      console.error("Error saving configuration:", error);
      toast({
        title: "Error",
        description: "Failed to save configuration.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box width="60%"  p={5}>
      {storedClassName && (
        <FormLabel ml={4}>
        {storedClassName}
        </FormLabel>
      )}
      <Tabs variant="soft-rounded" colorScheme="purple">
        <TabPanels>
          <TabPanel>
            <FormControl id="giGPT">
              <Checkbox isChecked={giGPTEnabled} onChange={(e) => setGiGPTEnabled(e.target.checked)}>
                GI GPT
              </Checkbox>
            </FormControl>

            {giGPTEnabled && (
              <Box ml={6} mt={4}> 
                <FormControl id="aiSelection">
                  <FormLabel>Select AI Model</FormLabel>
                  <RadioGroup onChange={setSelectedAI} value={selectedAI}>
                    <Stack direction="row" spacing={5}>
                      <Radio value="gpt">GPT</Radio>
                      <Radio value="azureAI">Azure AI</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl id="apiKey" mt={4} isRequired>
                  <FormLabel>API Key</FormLabel>
                  <InputGroup>
                    <Input
                      type={showApiKey ? "text" : "password"} 
                      placeholder="Enter your API key here"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      focusBorderColor="purple.500"
                    />
                    <InputRightElement>
                      <IconButton
                        icon={showApiKey ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={() => setShowApiKey(!showApiKey)}
                        variant="ghost"
                        aria-label="Toggle API key visibility"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
            )}

            <FormControl id="learnQ" mt={3} isDisabled={!giGPTEnabled}>
              <Checkbox isChecked={learnQEnabled} onChange={(e) => setLearnQEnabled(e.target.checked)}>
                Learn Q
              </Checkbox>
            </FormControl>

            <FormControl id="learnCode" mt={3} isDisabled={!giGPTEnabled}>
              <Checkbox isChecked={learnCodeEnabled} onChange={(e) => setLearnCodeEnabled(e.target.checked)}>
                Learn Code
              </Checkbox>
            </FormControl>

            <FormControl id="srs" mt={3} isDisabled={!giGPTEnabled}>
              <Checkbox isChecked={srsEnabled} onChange={(e) => setSrsEnabled(e.target.checked)}>
                SRS
              </Checkbox>
            </FormControl>

            <Flex justifyContent="flex-start" mt={10}>
              <Button
                bg="purple.900"
                color="white"
                _hover={{ bg: "purple.900" }}
                onClick={handleSave}
                isLoading={loading}
                loadingText="Saving"
              >
                Save Configuration
              </Button>
            </Flex>

          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Config;
