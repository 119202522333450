import { useState, useEffect } from 'react';
import { Box, Container, Heading, Text, Button, VStack, SimpleGrid, Badge, useColorMode, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useToast, HStack, IconButton } from '@chakra-ui/react';
import { FileUp, Clock, CheckCircle2, Code2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';

import React from 'react';
import Navbar from '../NavBar.tsx';
import { fetchAssignmentsForClass } from '../../../Services/Student/studentService.tsx';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { analyzeAssignment } from '../services/learn.js';
import { app } from '../../../config.js';
import { FaEye } from 'react-icons/fa';


const isProduction = process.env.NODE_ENV === 'production';

pdfjsLib.GlobalWorkerOptions.workerSrc = isProduction
  ? "https://generativeideas.org/pdf.worker.min.mjs"
  : "/pdf.worker.min.mjs";

const MAX_FILE_SIZE_MB = 5;


const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 }
  }
};

export default function AssignmentsPage() {
  const { colorMode } = useColorMode();
  const {classId} = useParams();
  const [assignments, setAssignments] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingAssignments, setLoadingAssignments] = useState({});
  const toast = useToast();
  

  useEffect(() => {
    const fetchAndStoreAssignments = async () => {
      try {
        if (!classId) return; 
        setLoading(true);
        const assignmentsData = await fetchAssignmentsForClass(classId);
        setAssignments(assignmentsData || []);
  
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
      finally {
        setLoading(false); 
      }
    };
  
    fetchAndStoreAssignments();
  }, [classId]); 


  const extractFileData = async (file) => {

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
      return null;
    }

    const fileType = file.type;

    if (fileType.includes('pdf')) {
      const pdfData = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const pageText = await page.getTextContent();
        text += pageText.items.map(item => item.str).join(' ');
      }
      return text;
    } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return result.value;
    } else if (fileType.includes('text/plain')) {
      const textData = await file.text();
      return textData;
    } else {
      alert('Unsupported file type.');
      return null;
    }
  };

const downloadFileWithAccessToken = async (accessToken, fileId) => {
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        });

        const blob = response.data;


  if (response) {
    return blob;
  } else {
    console.error('Error fetching file content:', response.statusText);
  }
};


const extractFileId = (link) => {
  const regex = /\/d\/(.*?)\//;
  const matches = link.match(regex);
  return matches ? matches[1] : null;
};


const handleOpenAssignmentClick = async (file) => {
  setLoadingAssignments(prev => ({ ...prev, [file._id]: true }));

  try {
    const fileId = extractFileId(file.classFiles[0].link);
    let token;
    if (fileId) {
      const userType = sessionStorage.getItem("userType");
      if (userType === "student") {
        token = localStorage.getItem("studentGoogleAccessToken");
      } else if (userType === "teacher") {
        token = localStorage.getItem("googleAccessToken");
      }

      const expirationTime = new Date(localStorage.getItem("studentTokenExpirationTime"));
      const currentTime = new Date();
      const timeLeft = expirationTime - currentTime;
      if (timeLeft < 5 * 60 * 1000) {
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: "select_account" });
        provider.addScope("https://www.googleapis.com/auth/drive");
        provider.addScope("https://www.googleapis.com/auth/drive.file");
        provider.addScope("https://www.googleapis.com/auth/documents");
        const result = await signInWithPopup(auth, provider);
        const loggedInUser = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        token = credential.accessToken;
        const accessToken = await loggedInUser.getIdTokenResult(true);
        if (userType === "student") {
          localStorage.setItem("studentGoogleAccessToken", token);
          localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
        } else if (userType === "teacher") {
          localStorage.setItem("googleAccessToken", token);
          localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
        }
      }
      
      const fileContent = await downloadFileWithAccessToken(token, fileId);
      const fileText = await extractFileData(fileContent);

      // Call analyzeAssignment and handle errors properly
      try {
        const res = await analyzeAssignment(fileText, classId);
        navigate(`/student-portal/${classId}/learncode/assignment-challenges/${file._id}?file=${JSON.stringify(file)}`);
      } catch (analysisError) {
        console.error("Assignment analysis error:", analysisError);

        // Show API error message in toast
        toast({
          title: "Analysis Error",
          description: analysisError.message || "Failed to analyze the assignment.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  } catch (error) {
    console.error("Error opening assignment:", error);
    toast({
      title: "Error",
      description: error.message || "Failed to open the assignment. Please try again.",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  } finally {
    setLoadingAssignments(prev => ({ ...prev, [file._id]: false }));
  }
};


  
  if (loading) {
    return (
      <>
        <Navbar />
        <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
          <Container maxW="container.xl">
            <Text textAlign="center" fontSize="lg" color="gray.600">Loading assignments...</Text>
          </Container>
        </Box>
      </>
    );
  }

  if (assignments.length === 0) {
    return (
      <>
        <Navbar />
        <Box
          minH="calc(100vh - 72px)"
          bg={colorMode === 'light' ? 'white' : 'gray.900'}
          py={10}
        >
          <Container maxW="container.xl">
            <MotionBox initial="hidden" animate="visible" variants={containerVariants}>
              <VStack spacing={8} align="stretch">
                <MotionBox variants={itemVariants}>
                  <Heading
                    size="xl"
                    color={colorMode === 'light' ? 'gray.800' : 'white'}
                    textAlign="center"
                  >
                    My Assignments
                  </Heading>
                </MotionBox>
                
                <MotionBox
                  variants={itemVariants}
                  w="full"
                  maxW="3xl"
                  mx="auto"
                  p={10}
                  bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                  rounded="lg"
                  borderWidth="1px"
                  borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <VStack spacing={6}>
                    <Heading size="md" color={colorMode === 'light' ? 'gray.800' : 'white'}>
                      No Assignments Available
                    </Heading>
                    <Text color={colorMode === 'light' ? 'gray.600' : 'gray.300'} textAlign="center" maxW="md">
                      There are no assignments available for this class at the moment.
                    </Text>
                  </VStack>
                </MotionBox>
              </VStack>
            </MotionBox>
          </Container>
        </Box>
      </>
    );
  }
  

  return (
    <>
    <Navbar />
    <Box
      minH="calc(100vh - 72px)"
      bg={colorMode === 'light' ? 'white' : 'gray.900'}
      py={10}
    >
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading
              size="xl"
              color={colorMode === 'light' ? 'gray.800' : 'white'}
            >
              My Assignments
            </Heading>
          </Box>

          <MotionSimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={6}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {assignments.map((assignment) => (
            <MotionBox
              key={assignment._id}
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              p={6}
              bg={colorMode === 'light' ? 'white' : 'gray.800'}
              rounded="lg"
              shadow="lg"
              borderWidth="1px"
              borderColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
            >
              <VStack align="stretch" spacing={4}>
               
                <Box>
                  <Box 
                    onClick={() => window.open(assignment?.classFiles[0]?.link, '_blank')}
                    cursor="pointer"
                    _hover={{ textDecoration: 'underline' }} 
                  >
                    <Heading
                      size="md"
                      color={colorMode === 'light' ? 'gray.800' : 'white'}
                      display="flex"
                      alignItems="center"
                      gap={3}
                    >
                      {assignment?.filename.charAt(0).toUpperCase() + assignment?.filename.slice(1)}
                    </Heading>
                  </Box>

                  <Text
                  fontSize="xs"
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                  mt={1}
                >
                  {new Date(assignment.updatedAt).toLocaleDateString()} {/* Shows only the date */}
                </Text>

                </Box>

                
                <Button
                  rightIcon={<Code2 size={18} />}
                  colorScheme="blue"
                  size="md" 
                  width="full"
                  onClick={() => handleOpenAssignmentClick(assignment)}
                  isLoading={loadingAssignments[assignment._id] || false}
                  _hover={{ bg: colorMode === 'light' ? 'blue.600' : 'blue.700' }} 
                  _active={{ bg: colorMode === 'light' ? 'blue.700' : 'blue.800' }} 
                  borderRadius="md" 
                >
                  Start Coding
                </Button>
              </VStack>
            </MotionBox>
))}

          </MotionSimpleGrid>
        </VStack>
      </Container>
    </Box>
    </>
  );
}