// src/components/protectedRoute/StudentProtectedRoute.tsx
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStudentAuth } from '../../contexts/StudentAuthContext.tsx';
import { getStudentByEmail } from '../../Services/Student/studentService.tsx';
import { getAuth } from 'firebase/auth';


interface ProtectedRouteProps {
  element: React.ReactElement;
}

const StudentProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user, loading } = useStudentAuth();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const fetchGoogleAccessToken = async () => {
    const auth = getAuth();
    if (user) {
      const tokenResult = await user.getIdTokenResult(true);
      const token = tokenResult.token;
      localStorage.setItem("studentGoogleAccessToken", token);
      localStorage.setItem("studentTokenExpirationTime", tokenResult.expirationTime);
    }
  };

  if (user) {
    const checkStudentAuthorization = async () => {
      try {
        if (!localStorage.getItem("studentGoogleAccessToken")) {
          await fetchGoogleAccessToken();
        }
        const student = await getStudentByEmail(user.email);
        if (student) {
          sessionStorage.setItem("CurrentLoggedInSId", student._id); 
          sessionStorage.setItem("userType", "student");
          sessionStorage.setItem("studentUserData", JSON.stringify(student));
          setIsAuthorized(true); 
        } else {
          setIsAuthorized(false); 
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
        setIsAuthorized(false); 
      }
    };

    checkStudentAuthorization();
  }

  return isAuthorized === null ? null : isAuthorized ? element : <Navigate to={`/student-portal/login?redirect=${location.pathname}`} />;
};

export default StudentProtectedRoute;
