import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Container, Heading, VStack, Text, Button, SimpleGrid, Badge, useColorMode, useToast, HStack, Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Rocket, Trophy, Brain, Code2 } from 'lucide-react';
import { fetchChallenges, getChallengesFromAI } from '../services/learn.js';
import React from 'react';
import Navbar from '../NavBar.tsx';
import { FaEye } from 'react-icons/fa';


const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);


const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 }
  }
};

export default function ChallengesListPage() {
  const { concept } = useParams<{ concept: string }>();
  const [searchParams] = useSearchParams();
  const difficulty = searchParams.get('difficulty');
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(false);
  const {classId} = useParams();
  const studentId = sessionStorage.getItem("CurrentLoggedInSId")?.trim();

  useEffect(() => {
    const loadChallenges = async () => {
      setLoading(true);
      try {
        const result = await fetchChallenges(studentId, classId, concept, difficulty);
        setChallenges(result?.challenges || []);
      } catch (error) {
        console.error("Error loading challenges:", error);
      } finally {
        setLoading(false);
      }
    };

    loadChallenges();
  }, [studentId, classId, concept, difficulty])

 

  const handleStartFirstChallenge = async () => {
    setLoading(true);
    try {
      const result = await getChallengesFromAI(concept || '', difficulty, classId || '', studentId || '');
      setChallenges(result?.challenges || []);
      
      
      toast({
        title: 'Challenge Created',
        description: 'Your first challenge is ready. Good luck!',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Error loading challenges:', error);
      toast({
        title: 'Error loading challenges',
        description: 'Failed to load challenges. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNextChallenge = async () => {
    setLoading(true);
    try {
      const last15Challenges = challenges.slice(-15).map(ch => ({
        title: ch.challengeData.title
      }));
      const result = await getChallengesFromAI(concept || '', difficulty, classId || '', studentId || '', last15Challenges || []);
      setChallenges(result?.challenges || []);
    } catch (error) {
      console.error('Error loading next challenge:', error);
      toast({
        title: 'Error loading next challenge',
        description: 'Failed to load the next challenge. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  if (challenges.length === 0) {
    return (
      <>
      <Navbar />
      <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
        <Container maxW="container.xl">
          <MotionBox
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <VStack spacing={8} align="center" textAlign="center">
              <MotionBox variants={itemVariants}>
                <Box
                  p={4}
                  bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                  color={colorMode === 'light' ? 'blue.500' : 'blue.200'}
                  rounded="full"
                  display="inline-block"
                  mb={4}
                >
                  <Rocket size={40} />
                </Box>
                <Heading
                  size="xl"
                  color={colorMode === 'light' ? 'gray.800' : 'white'}
                  mb={4}
                >
                  Get Started with {concept?.charAt(0).toUpperCase() + concept?.slice(1)} - {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)} Path
                </Heading>
                <Text
                  fontSize="lg"
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                  maxW="2xl"
                  mb={8}
                >
                  Begin your learning journey with hands-on challenges designed to build your skills from the ground up.
                </Text>
                <Button
                  size="lg"
                  colorScheme="blue"
                  leftIcon={<Brain size={20} />}
                  onClick={handleStartFirstChallenge}
                  isLoading={loading}
                  loadingText="Loading Challenge"
                >
                  Start Your First Challenge
                </Button>
              </MotionBox>
            </VStack>
          </MotionBox>
        </Container>
      </Box>
      </>
    );
  }

  return (
    <>
    <Navbar />
    <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'} py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading
              size="xl"
              color={colorMode === 'light' ? 'gray.800' : 'white'}
              display="flex"
              alignItems="center"
              gap={3}
            >
              <Trophy size={32} />
              {concept?.charAt(0).toUpperCase() + concept?.slice(1)} - {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)} Path
              
            </Heading>
            <Button
              colorScheme="blue"
              onClick={handleNextChallenge}
              isLoading={loading}
              leftIcon={<Code2 size={20} />}
            >
              Next Challenge
            </Button>
          </Box>

          <MotionSimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={6}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {challenges.map((challenge) => (
              <MotionBox
                key={challenge.challengeId}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                p={6}
                bg={colorMode === 'light' ? 'white' : 'gray.800'}
                rounded="xl"
                shadow="lg"
                borderWidth="1px"
                borderColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
              >
                <VStack align="stretch" spacing={4} height="100%">
                <HStack spacing={3} align="center">
                  <Box
                    p={3}
                    bg={colorMode === 'light' ? 'blue.50' : 'blue.900'}
                    color={colorMode === 'light' ? 'blue.500' : 'blue.200'}
                    rounded="lg"
                    width="fit-content"
                  >
                   <Code2 size={24} />
                  </Box>
                  <Badge colorScheme={challenge.progress === 100 ? 'green' : 'blue'}>
                    Total Points: {challenge.points}
                  </Badge>
                </HStack>


                  <Box>
                    <Heading size="md" mb={2}>
                      {challenge.challengeData.title}
                    </Heading>
                    <Text 
                      color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                      noOfLines={3}
                    >
                      {challenge.challengeData.description}
                    </Text>
                  </Box>

                  

                  <Box mt="auto">
                  {challenge.viewedSolution && (
                  <HStack mb={4}  align="center">
                    <Icon as={FaEye} color="gray.500" boxSize={3} />
                    <Text fontSize="xs" color="gray.500">Viewed Solution</Text>
                  </HStack>
                )}
                    <HStack mb={4}>
                      <Badge colorScheme={challenge.progress === 100 ? 'green' : 'blue'}>
                        awarded points: {challenge.awardedPoints} 
                      </Badge>
                    </HStack>

                    <Button
                      colorScheme={challenge.awardedPoints > 0 ? 'green' : 'blue'}
                      onClick={() => navigate(`/student-portal/${classId}/learncode/challenge/${challenge.challengeId}/${concept}?difficulty=${challenge.challengeData.difficulty}`)}
                      leftIcon={challenge.awardedPoints > 0 ? <Trophy size={18} /> : <Code2 size={18} />}
                      width="100%"
                    >
                      {challenge.awardedPoints > 0 ? 'Restart challenge' : 'Start Challenge'}
                    </Button>
                  </Box>

                </VStack>
              </MotionBox>
            ))}
          </MotionSimpleGrid>
        </VStack>
      </Container>
    </Box>
    </>
  );
}