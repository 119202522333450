import React, { useState } from 'react';
import { MdNotStarted } from "react-icons/md";

import {
  Box,
  IconButton,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import axios from 'axios';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
const isProduction = process.env.NODE_ENV === 'production';

pdfjsLib.GlobalWorkerOptions.workerSrc = isProduction
  ? "https://generativeideas.org/pdf.worker.min.mjs"
  : "/pdf.worker.min.mjs";

const MAX_FILE_SIZE_MB = 5;

const LearnQHome = ({isEnabled, setActiveTab, assignments, setFileText, setCurrentTId, classId, setAssignmentId }) => {

  const [loading, setLoading] = useState(false);

  const extractFileData = async (file) => {

      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
        return null;
      }

      const fileType = file.type;

      if (fileType.includes('pdf')) {
        const pdfData = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
        let text = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const pageText = await page.getTextContent();
          text += pageText.items.map(item => item.str).join(' ');
        }
        return text;
      } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        return result.value;
      } else if (fileType.includes('text/plain')) {
        const textData = await file.text();
        return textData;
      } else {
        alert('Unsupported file type.');
        return null;
      }
    };

  const downloadFileWithAccessToken = async (accessToken, fileId) => {
    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'blob',
          });

          const blob = response.data;


    if (response) {
      return blob;
    } else {
      console.error('Error fetching file content:', response.statusText);
    }
  };


  const extractFileId = (link) => {
    const regex = /\/d\/(.*?)\//;
    const matches = link.match(regex);
    return matches ? matches[1] : null;
  };


const handleOpenQuizClick = async(file) => {
    setLoading(true);
    setCurrentTId(file.uploadedBy._id);
    const fileId = extractFileId(file.classFiles[0].link);
    let token;
    if (fileId) {
      const userType = sessionStorage.getItem("userType");
      if (userType === "student") {
        token = localStorage.getItem("studentGoogleAccessToken");
      } else if (userType === "teacher") {
        token = localStorage.getItem("googleAccessToken");
      }

      const expirationTime = new Date(localStorage.getItem("studentTokenExpirationTime"));
      const currentTime = new Date();
      const timeLeft = expirationTime - currentTime;
      if (timeLeft < 5 * 60 * 1000) {
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: "select_account" });
        provider.addScope("https://www.googleapis.com/auth/drive");
        provider.addScope("https://www.googleapis.com/auth/drive.file");
        provider.addScope("https://www.googleapis.com/auth/documents");
        const result = await signInWithPopup(auth, provider);
        const loggedInUser = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        token = credential.accessToken;
        const accessToken = await loggedInUser.getIdTokenResult(true);
        if (userType === "student") {
          localStorage.setItem("studentGoogleAccessToken", token);
          localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
        } else if (userType === "teacher") {
          localStorage.setItem("googleAccessToken", token);
          localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
        }
      }
      const fileContent = await downloadFileWithAccessToken(token, fileId);
      const fileText = await extractFileData(fileContent);
      setFileText(fileText);
      setActiveTab(4);
      setAssignmentId(file._id);
    }
    setLoading(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <Box>
      <Box
      css={{
        '&::-webkit-scrollbar': {
            display: 'none', 
        },
        '-ms-overflow-style': 'none', 
        'scrollbar-width': 'none', 
    }}
      p={6} 
      h={'90vh'}
      mx="auto" 
      ml={-5}  
      maxH="570px" 
      overflowY="auto"
      borderWidth="1px" borderRadius="md" boxShadow="md"
      >
        <Heading fontSize="lg" ml={5} mb={4}>Your Assignments</Heading>
        {loading ? (
          <Flex justify="center" align="center" height="100%">
            <LoadingSpinner />
          </Flex>
        ) : (
          <>
              <Table variant="simple" size="md" fontSize={'md'}>
                <Thead>
                  <Tr>
                    <Th>File Name</Th>
                    <Th>Uploaded By</Th>
                    <Th>Uploaded At</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assignments.filter(file =>
                    file.classFiles.some(cf => cf.classId._id === classId)
                  ).length > 0 ? (
                    assignments.filter(file =>
                      file.classFiles.some(cf => cf.classId._id === classId)
                    ).map((file) => (
                      <Tr key={file._id}>
                        <Td>{file.filename}</Td>
                        <Td>{file.uploadedBy.name}</Td>
                        <Td>{formatDate(file.createdAt)}</Td>
                        <Td>
                          <Tooltip label="View file" aria-label="View file tooltip">
                            <IconButton
                              aria-label="View file"
                              icon={<FaEye />}
                              variant={'ghost'}
                              onClick={() => window.open(file.classFiles[0].link, '_blank')}
                            />
                          </Tooltip>

                          {isEnabled && (
                            <Tooltip label="Open Quiz" aria-label="Open Quiz tooltip">
                              <IconButton
                                aria-label="Open Quiz"
                                icon={<MdNotStarted />}
                                variant={'ghost'}
                                onClick={() => handleOpenQuizClick(file)}
                              />
                            </Tooltip>
                          )}

                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="4" textAlign="center">No uploaded files for this class.</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
          </>
        )}
      </Box>
    </Box>
  );
};

export default LearnQHome;
