import { useEffect, useState } from 'react';
import {
    Box, Button, Flex, Heading, IconButton, Text, Textarea,
    VStack, Spinner, HStack, Tooltip, Modal, ModalOverlay,
    ModalContent, ModalHeader, ModalFooter, ModalBody, useToast, Checkbox, ListItem, UnorderedList,
    Card, CardBody, CardHeader, RadioGroup, Radio, Stack
} from "@chakra-ui/react";
import { ArrowBackIcon, QuestionIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { getSRSFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import { fetchSRSforProject, postSRSData } from '../../../Services/Student/studentService.tsx';
import DummyChatBox from './GPTbox.jsx';
import html2pdf from 'html2pdf.js';
import { getProjectsByIds } from '../../../Services/Teacher/teacherService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const SRS = () => {
    const { projectId, classId } = useParams();
    const [srsLoading, setSRSLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [projectBackLog, setProjectBackLog] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [srsData, setSRSData] = useState({});
    const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId")?.trim() || "";
    const [available, setAvailable] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [response, setResponse] = useState([]);
    const [requirementType, setRequirementType] = useState('functional');
    
    const toast = useToast();
    const [currentTeacherId, setCurrentTeacherId] = useState("");

    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState([{ role: 'bot', content: 'Have all the inputs been specified?' }]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [noEntered, setNoEntered] = useState(false);
    const [askedRegenerate, setAskedRegenerate] = useState(false);
    const [missingItems, setMissingItems] = useState([]);

    useEffect(() => {
        if (requirementType === 'non-functional') {
            setMessages([{ role: 'bot', content: 'Is the requirement unambiguous?' }]);
        }
    }, [requirementType]);
      
    useEffect(() => {
        const fetchSRSData = async () => {
            try {
                const response = await fetchSRSforProject(projectId || "");
                if (response) {
                    setResponse(response);
                    setProjectDescription(response[0]?.projectDescription);
                }
                const data = await getProjectsByIds([projectId || ""]);
                setCurrentTeacherId(data[0].teacherIds[0]);
            } catch (error) {
                console.error("Error fetching SRS data:", error);
            }
        };

        fetchSRSData();
    }, [projectId]);

    const handleSRSsubmit = async () => {
        setSRSLoading(true);
        try {
            const response = await getSRSFromGPT(projectDescription, projectBackLog, requirementType === 'non-functional', classId || "");
            
            let trimmedResponse = response;
            if (response.startsWith("```")) {
                trimmedResponse = response.replace(/^```json|```$/g, "");
            }
            trimmedResponse = JSON.parse(trimmedResponse);
            setSRSData(trimmedResponse);
            await postSRSData(projectId || "", projectDescription, projectBackLog, '', trimmedResponse, currentLoggedInSId, requirementType === 'non-functional');
        } catch (error) {
            console.error("Error reading the file:", error);
        } finally {
            setAvailable(true);
            setSRSLoading(false);
        }
    }

    const handleSRSBack = () => {
        setAvailable(false);
    }

    const handleOpenChat = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const generateSRSPDF = () => {
        const element = document.getElementById("srs_document");
        const options = {
            margin: 0.5,
            filename: 'SRS.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: [10, 11],
                orientation: 'portrait'
            }
        };

        html2pdf().from(element).set(options).save();
    };

    const handleSaveClick = () => {
        setIsConfirmationOpen(true);
    };

    const saveSRStoDB = async () => {
        setSaveLoading(true);
        try {
            toast({
                title: "Success!",
                description: "SRS data saved successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error saving SRS data:", error);
            toast({
                title: "Error!",
                description: "Failed to save SRS data.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setSaveLoading(false);
            setIsConfirmationOpen(false);
            sessionStorage.setItem("activeTabInStudentProject", "5");
            window.location.reload();
        }
    };

    const handleProjectBackLogChange = (e) => {
        setProjectBackLog(e.target.value);
    };

    return (
        <Flex justifyContent="center" alignItems="center" textColor={'purple.900'}>
            <Box w="90%" h="auto" p={8} borderRadius="lg"  bg="white" position="relative">
                {saveLoading && (
                    <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="rgba(255, 255, 255, 0.8)" display="flex" justifyContent="center" alignItems="center" zIndex={1}>
                        <LoadingSpinner/>
                    </Box>
                )}
                
                <VStack spacing={6} align="stretch">

                    {available ? (
                        <>
                            <Flex justifyContent="space-between" alignItems="center" mb={6}>
                                <Button
                                    leftIcon={<ArrowBackIcon />}
                                    onClick={handleSRSBack}
                                    bg="purple.900"
                                color="white"
                                _hover={{ bg: "purple.900" }}
                                >
                                    Back
                                </Button>

                                <HStack spacing={4}>
                                    <Tooltip label="Ask AI to refine/regenerate missing elements in SRS" hasArrow>
                                        <Button
                                            onClick={handleOpenChat}
                                            bg="purple.900"
                                            color="white"
                                            _hover={{ bg: "purple.900" }}
                                            leftIcon={<QuestionIcon />}
                                        >
                                            Refine SRS
                                        </Button>
                                    </Tooltip>
                                    <Button
                                        onClick={generateSRSPDF}
                                        bg="purple.900"
                                        color="white"
                                        _hover={{ bg: "purple.900" }}
                                    >
                                        Generate PDF
                                    </Button>
                                </HStack>
                            </Flex>

                            {isChatOpen && (
                                <DummyChatBox 
                                    onClose={handleCloseChat}
                                    srsData={srsData}
                                    setSRSData={setSRSData}
                                    isNonFunctionalRequired={requirementType === 'non-functional'}
                                    projectBackLog={projectBackLog}
                                    projectDescription={projectDescription}
                                    currentTeacherId={currentTeacherId}
                                    messages={messages}
                                    setMessages={setMessages}
                                    currentQuestionIndex={currentQuestionIndex}
                                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                                    noEntered={noEntered}
                                    setNoEntered={setNoEntered}
                                    askedRegenerate={askedRegenerate}
                                    setAskedRegenerate={setAskedRegenerate}
                                    missingItems={missingItems}
                                    setMissingItems={setMissingItems}
                                />
                            )}

                            <Box id="srs_document" borderWidth="1px" borderColor="gray.200" borderRadius="lg" p={6}>
                                <VStack spacing={6} align="stretch">
                                    <Heading size="lg" color="purple.900">
                                        {requirementType === 'functional' ? 'Functional Requirements' : 'Non-Functional Requirements'}
                                    </Heading>

                                    {requirementType === 'functional' ? (
                                        srsData["1 Functional and Non-Functional Requirements"]?.["1a. Functional Requirements"]?.["Requirements"]?.map((req, index) => (
                                            <Box key={index} borderWidth="1px" borderColor="gray.200" borderRadius="lg" p={6} bg="white" _hover={{ boxShadow: "sm" }}>
                                                <Heading size="md" mb={4} color="purple.900">{req["Requirement Shell"]["Requirement #"]}</Heading>
                                                <VStack align="stretch" spacing={4}>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Description</Text>
                                                        <Text>{req["Requirement Shell"]["Description"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Rationale</Text>
                                                        <Text>{req["Requirement Shell"]["Rationale"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Fit Criterion</Text>
                                                        <UnorderedList pl={4}>
                                                            {req["Requirement Shell"]["Fit Criterion"].map((criterion, idx) => (
                                                                <ListItem key={idx}>{criterion}</ListItem>
                                                            ))}
                                                        </UnorderedList>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Preconditions</Text>
                                                        <Text>{req["Requirement Shell"]["Preconditions"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Postconditions</Text>
                                                        <Text>{req["Requirement Shell"]["Postconditions"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Dependencies</Text>
                                                        <Text>{req["Requirement Shell"]["Dependencies"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">Supporting Materials</Text>
                                                        <Text>{req["Requirement Shell"]["Supporting Materials"]}</Text>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="semibold" color="purple.900">History</Text>
                                                        <Text>{req["Requirement Shell"]["History"]}</Text>
                                                    </Box>
                                                </VStack>
                                            </Box>
                                        ))
                                    ) : (
                                        srsData["1 Functional and Non-Functional Requirements"]?.["1b. Non-Functional Requirements"]?.map((req, index) => (
                                            <Box key={index} borderWidth="1px" borderColor="gray.200" borderRadius="lg" p={6} bg="white" _hover={{ boxShadow: "sm" }}>
                                                <Text mb={4}>{req.Description}</Text>
                                                <Text fontWeight="semibold" color="purple.900">
                                                    Rationale: <Text as="span" fontWeight="normal">{req.Rationale}</Text>
                                                </Text>
                                            </Box>
                                        ))
                                    )}
                                </VStack>
                            </Box>
                        </>
                    ) : (
                        <VStack spacing={8} align="stretch">
                            <Card variant="outline" borderColor="gray.200" borderRadius="lg">
                                <CardHeader>
                                    <Heading size="md" color="purple.900">What type of requirements would you like to specify?</Heading>
                                </CardHeader>
                                <CardBody>
                                    <RadioGroup value={requirementType} onChange={setRequirementType}>
                                        <Stack direction="row" spacing={8}>
                                            <Radio value="functional" size="md" colorScheme="purple">
                                                <VStack align="start" spacing={1}>
                                                    <Text fontSize="md" color="purple.900">Functional Requirements</Text>
                                                    <Text fontSize="sm" color="purple.900">Specify what the system should do</Text>
                                                </VStack>
                                            </Radio>
                                            <Radio value="non-functional" size="md" colorScheme="purple">
                                                <VStack align="start" spacing={1}>
                                                    <Text fontSize="md" color="purple.900">Non-Functional Requirements</Text>
                                                    <Text fontSize="sm" color="purple.900">Specify how the system should perform</Text>
                                                </VStack>
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </CardBody>
                            </Card>

                            <Card variant="outline" borderColor="gray.200" borderRadius="lg">
                                <CardHeader>
                                    <Heading size="md" color="purple.900">Project Description</Heading>
                                </CardHeader>
                                <CardBody>
                                    <Textarea
                                        placeholder="Describe your project's goals, scope, and main features in detail..."
                                        value={projectDescription}
                                        onChange={(e) => setProjectDescription(e.target.value)}
                                        minH="200px"
                                        borderColor="gray.200"
                                        _hover={{ borderColor: "gray.300" }}
                                        _focus={{ borderColor: "gray.400", boxShadow: "0 0 0 1px gray.400" }}
                                        fontSize="md"
                                        p={4}
                                    />
                                </CardBody>
                            </Card>

                            <Card variant="outline" borderColor="gray.200" borderRadius="lg">
                                <CardHeader>
                                    <Heading size="md" color="purple.900">
                                        {requirementType === 'functional' ? "User Story" : "Non-Functional Requirements"}
                                    </Heading>
                                </CardHeader>
                                <CardBody>
                                    <Textarea
                                        placeholder={
                                            requirementType === 'functional'
                                                ? "Enter user stories following the format:\n\nAs a [type of user],\nI want to [perform some action],\nSo that [achieve some goal/benefit]..."
                                                : "Specify system requirements for:\n- Performance (e.g., response time, throughput)\n- Security (e.g., authentication, encryption)\n- Usability (e.g., user interface, accessibility)\n- Reliability (e.g., availability, fault tolerance)\n- Scalability (e.g., concurrent users, data volume)"
                                        }
                                        value={projectBackLog}
                                        onChange={handleProjectBackLogChange}
                                        minH="200px"
                                        borderColor="gray.200"
                                        _hover={{ borderColor: "gray.300" }}
                                        _focus={{ borderColor: "gray.400", boxShadow: "0 0 0 1px gray.400" }}
                                        fontSize="md"
                                        p={4}
                                    />
                                </CardBody>
                            </Card>

                            <Flex justify="center">
                                <Button
                                    bg={'purple.900'}
                                    color={'white'}
                                    _hover={{ bg: "purple.800" }}
                                    isDisabled={!projectDescription || !projectBackLog || srsLoading}
                                    onClick={handleSRSsubmit}

                                >
                                    {srsLoading ? (
                                        <HStack spacing={2}>
                                            <LoadingSpinner />
                                            <Text>Generating...</Text>
                                        </HStack>
                                    ) : (
                                        "Generate SRS Document"
                                    )}
                                </Button>
                            </Flex>
                        </VStack>
                    )}
                </VStack>
            </Box>

            <Modal isOpen={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Save</ModalHeader>
                    <ModalBody>
                        <Text>Are you sure you want to save this final SRS?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            borderColor="gray.300"
                            color="purple.900"
                            _hover={{ bg: "gray.50" }}
                            onClick={saveSRStoDB}
                            mr={3}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="ghost"
                            onClick={() => setIsConfirmationOpen(false)}
                        >
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default SRS;