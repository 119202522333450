import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  VStack,
  HStack,
  Grid,
  GridItem,
  Heading,
  Select,
  Card,
  CardHeader,
  CardBody,
  Text,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Progress,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Skeleton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Portal,
  useDisclosure,
  Fade,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { Code, BookOpen, Clock, Award, Search, X, SortAsc, SortDesc, ChevronDown, ChevronRight, ChevronUp, Eye, EyeOff, Maximize2, Minimize2 } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getClassesByUserId, getStudentsByIds } from '../../../Services/Teacher/teacherService.tsx';
import { fetchAssignmentsForClass } from '../../../Services/Student/studentService.tsx';
import { fetchAssignmentChallenges, fetchChallenges } from '../../LearnCode/services/learn';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const SubmissionCard = ({ submission, index, challengeCode, language }: {
  submission: any;
  index: number;
  challengeCode: string;
  language: string;
}) => {
  const { isOpen, onToggle } = useDisclosure();
  
  return (
    <Box borderWidth={1} borderRadius="md" overflow="hidden">
      <Box 
        py={3} 
        px={4}
        onClick={onToggle} 
        cursor="pointer" 
        _hover={{ bg: 'gray.50' }}
        bg="white"
        transition="background-color 0.2s ease"
      >
        <HStack justify="space-between" wrap="wrap" spacing={4}>
          <VStack align="start" spacing={1}>
            <Text fontSize="sm" color="gray.600">
              Submitted on {new Date(submission.submittedAt).toLocaleDateString()}
            </Text>
            <Badge colorScheme={submission.awardedPoints >= challengeCode.points ? 'green' : 'orange'}>
              {submission.awardedPoints} points awarded
            </Badge>
          </VStack>
          <HStack>
            <Text fontSize="sm" fontWeight="medium">Attempt #{index + 1}</Text>
            <Icon 
              as={isOpen ? ChevronUp : ChevronDown} 
              transition="transform 0.2s ease"
              transform={isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'}
            />
          </HStack>
        </HStack>
      </Box>
      <Box
        maxH={isOpen ? '2000px' : '0'}
        opacity={isOpen ? 1 : 0}
        transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
        overflow="hidden"
        transform={`translateY(${isOpen ? '0' : '-10px'})`}
        visibility={isOpen ? 'visible' : 'hidden'}
      >
        <Box p={4}>
          {submission.evaluationFeedback?.differences && (
            <Box mb={4}>
              <ExpandableText
                title="Feedback"
                text={submission.evaluationFeedback.differences}
              />
            </Box>
          )}
          
          <CodeComparison
            challengeCode={challengeCode}
            submissionCode={submission.submissionData.code}
            language={language}
          />
        </Box>
      </Box>
    </Box>
  );
};

const SubmissionsList = ({ submissions, challengeCode, language }: {
  submissions: any[];
  challengeCode: string;
  language: string;
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  
  return (
    <Box>
      <Box 
        py={2}
        px={4}
        onClick={onToggle} 
        cursor="pointer"
        _hover={{ bg: 'gray.50' }}
        borderRadius="md"
        bg="white"
        mb={2}
        transition="background-color 0.2s ease"
      >
        <HStack justify="space-between">
          <Text fontWeight="medium">Submission History ({submissions.length})</Text>
          <Icon 
            as={isOpen ? ChevronUp : ChevronDown} 
            transition="transform 0.2s ease"
            transform={isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'}
          />
        </HStack>
      </Box>
      
      <Box
        maxH={isOpen ? '10000px' : '0'}
        opacity={isOpen ? 1 : 0}
        transition="all 0.4s cubic-bezier(0.4, 0, 0.2, 1)"
        overflow="hidden"
        transform={`translateY(${isOpen ? '0' : '-10px'})`}
        visibility={isOpen ? 'visible' : 'hidden'}
      >
        <VStack spacing={4} align="stretch">
          {submissions.map((submission, index) => (
            <SubmissionCard
              key={submission.submissionId}
              submission={submission}
              index={index}
              challengeCode={challengeCode}
              language={language}
            />
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

const SearchableMenu = ({ 
  items, 
  value, 
  onChange, 
  placeholder, 
  isLoading, 
  renderItem, 
  getSearchValue 
}: {
  items: any[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  isLoading?: boolean;
  renderItem: (item: any) => React.ReactNode;
  getSearchValue: (item: any) => string;
}) => {
  const [search, setSearch] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const filteredItems = useMemo(() => {
    if (!search) return items;
    const searchLower = search.toLowerCase();
    return items.filter(item => 
      getSearchValue(item).toLowerCase().includes(searchLower)
    );
  }, [items, search, getSearchValue]);

  const selectedItem = items.find(item => item.id === value || item._id === value);

  return (
    <Menu 
      placement="bottom" 
      matchWidth 
      isOpen={isOpen} 
      onOpen={onOpen} 
      onClose={onClose}
    >
      <MenuButton
        as={Button}
        rightIcon={<ChevronDown size={16} />}
        w="100%"
        textAlign="left"
        bg="white"
        _hover={{ bg: 'gray.50' }}
        _active={{ bg: 'gray.100' }}
        isLoading={isLoading}
        h="40px"
        fontSize="sm"
        color={selectedItem ? 'black' : 'gray.500'}
        borderWidth={1}
        borderColor="gray.200"
        _focus={{ boxShadow: 'outline' }}
      >
        {selectedItem ? renderItem(selectedItem) : placeholder}
      </MenuButton>
      <Portal>
        <MenuList 
          maxH="300px" 
          overflow="auto" 
          w="100%" 
          shadow="lg" 
          borderWidth={1}
          py={2}
        >
          <Box px={4} pb={2}>
            <InputGroup size="sm">
              <InputLeftElement pointerEvents="none">
                <Search size={14} />
              </InputLeftElement>
              <Input
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="filled"
                _focus={{ bg: 'gray.100' }}
              />
              {search && (
                <InputRightElement cursor="pointer" onClick={() => setSearch('')}>
                  <X size={14} />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>
          <Divider mb={2} />
          {filteredItems.map(item => (
            <MenuItem
              key={item.id || item._id}
              onClick={() => {
                onChange(item.id || item._id);
                setSearch('');
                onClose();
              }}
              px={4}
              py={2}
              _hover={{ bg: 'gray.50' }}
              _focus={{ bg: 'gray.50' }}
            >
              {renderItem(item)}
            </MenuItem>
          ))}
          {filteredItems.length === 0 && (
            <Box p={4} textAlign="center" color="gray.500">
              No results found
            </Box>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};

const ExpandableText = ({ 
  text, 
  title,
  maxHeight = "100px"
}: { 
  text: string;
  title?: string;
  maxHeight?: string;
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      );
    }
  }, [text]);

  return (
    <Box 
      bg="gray.50" 
      p={4} 
      borderRadius="md"
      transition="all 0.2s"
      _hover={{ bg: 'gray.100' }}
    >
      {title && (
        <HStack justify="space-between" mb={2}>
          <Text fontWeight="medium" color="gray.700">{title}</Text>
          {isOverflowing && (
            <IconButton
              aria-label={isOpen ? "Show less" : "Show more"}
              icon={isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              size="sm"
              variant="ghost"
              onClick={onToggle}
            />
          )}
        </HStack>
      )}
      <Box
        ref={contentRef}
        maxH={isOpen ? 'none' : maxHeight}
        overflow="hidden"
        position="relative"
        transition="max-height 0.3s ease-in-out"
      >
        <Text
          fontSize="sm"
          color="gray.700"
          whiteSpace="pre-wrap"
          lineHeight="1.6"
        >
          {text}
        </Text>
        {!isOpen && isOverflowing && (
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            height="40px"
            bgGradient="linear(to-t, gray.50, transparent)"
          />
        )}
      </Box>
    </Box>
  );
};

const CodeComparison = ({
  challengeCode,
  submissionCode,
  language
}: {
  challengeCode: string;
  submissionCode: string;
  language: string;
}) => {
  const [showDiff, setShowDiff] = useState(true);

  return (
    <Box>
      <HStack justify="space-between" mb={4}>
        <Text fontWeight="medium">Code Comparison:</Text>
        <IconButton
          aria-label={showDiff ? "Hide challenge" : "Show challenge"}
          icon={showDiff ? <Eye size={16} /> : <EyeOff size={16} />}
          size="sm"
          onClick={() => setShowDiff(!showDiff)}
        />
      </HStack>
      
      <Grid
        templateColumns={showDiff ? "repeat(2, 1fr)" : "1fr"}
        gap={4}
        w="100%"
      >
        {showDiff && (
          <Box w="100%">
            <Text fontSize="sm" fontWeight="medium" mb={2} color="gray.600">Challenge:</Text>
            <Box 
              borderRadius="md" 
              overflow="hidden"
              borderWidth={1}
              borderColor="gray.200"
              h="400px"
              position="relative"
            >
              <Box 
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                overflow="auto"
              >
                <Box minW="100%" h="100%">
                  <SyntaxHighlighter
                    language={language}
                    style={materialDark}
                    showLineNumbers
                    customStyle={{ 
                      margin: 0,
                      height: '100%',
                    }}
                  >
                    {challengeCode}
                  </SyntaxHighlighter>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box w="100%">
          <Text fontSize="sm" fontWeight="medium" mb={2} color="gray.600">Submission:</Text>
          <Box 
            borderRadius="md" 
            overflow="hidden"
            borderWidth={1}
            borderColor="gray.200"
            h="400px"
            position="relative"
          >
            <Box 
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              overflow="auto"
            >
              <Box minW="100%" h="100%">
                <SyntaxHighlighter
                  language={language}
                  style={materialDark}
                  showLineNumbers
                  customStyle={{ 
                    margin: 0,
                    height: '100%',
                  }}
                >
                  {submissionCode}
                </SyntaxHighlighter>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

const App = () => {
  // States for filters
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedAssignment, setSelectedAssignment] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const userId = sessionStorage.getItem('CurrentLoggedInTId').trim();
  const user = sessionStorage.getItem('userType');

  // States for data
  const [classes, setClasses] = useState<Class[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [assignments, setAssignments] = useState<{ _id: string; filename: string; }[]>([]);
  const [challenges, setChallenges] = useState<Challenge[]>([]);

  // Loading states
  const [isLoadingClasses, setIsLoadingClasses] = useState(true);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [isLoadingChallenges, setIsLoadingChallenges] = useState(false);

  // Fetch classes on mount

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClassesByUserId(userId, user);
        if (!classesData.message) setClasses(classesData);
        else setClasses([]);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
      finally {
        setIsLoadingClasses(false);
      }
    };

    fetchClasses();
  }, [userId, user]);

  // Fetch students when class is selected
  useEffect(() => {
    const fetchStudents = async () => {
      if (!selectedClass) {
        setStudents([]);
        return;
      }
      setIsLoadingStudents(true);
      try {
        const data = await getStudentsByIds(selectedClass.students);
        setStudents(data);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setIsLoadingStudents(false);
      }
    };
    fetchStudents();
  }, [selectedClass]);

  // Fetch assignments when needed
  useEffect(() => {
    const fetchAssignments = async () => {
      if (selectedType !== 'assignment') return;
      try {
        const assignmentsData = await fetchAssignmentsForClass(selectedClass._id);
        setAssignments(assignmentsData || []);
  
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };
  
    fetchAssignments();
  }, [selectedType, selectedClass]); 

  // Fetch challenges based on filters
  useEffect(() => {
    const fetchChallengesBasedonFactors = async () => {
      if (!selectedClass || !selectedStudent) return;
      
      setIsLoadingChallenges(true);
      try {
      if(selectedType === 'assignment' && selectedAssignment) {
        const data = await fetchAssignmentChallenges(selectedStudent,selectedClass._id,selectedAssignment);
        console.log(data);
        setChallenges(data || []);
      }
      else if(selectedType === 'frontend' && selectedCategory && selectedDifficulty) {
        const data =  await fetchChallenges(selectedStudent,selectedClass._id,selectedCategory,selectedDifficulty);
        console.log(data);
        setChallenges(data || []);
      }    
      else{
        setChallenges([]);
      }    
      } catch (error) {
        console.error('Error fetching challenges:', error);
      } finally {
        setIsLoadingChallenges(false);
      }
    };

    const debounceTimer = setTimeout(fetchChallengesBasedonFactors, 300);
    return () => clearTimeout(debounceTimer);
  }, [
    selectedClass,
    selectedStudent,
    selectedType,
    selectedAssignment,
    selectedCategory,
    selectedDifficulty,
    searchTerm,
    sortOrder,
  ]);

  const handleClassChange = (classId) => {
    const selectedClassObj = classes.find(c => c._id === classId);
    setSelectedClass(selectedClassObj);
    setSelectedStudent('');
    setSelectedType('');
    setSelectedAssignment('');
    setSelectedCategory('');
    setSelectedDifficulty('');
    setChallenges([]);
  };

  const handleStudentChange = (value: string) => {
    setSelectedStudent(value);
    setSelectedType('');
    setSelectedAssignment('');
    setSelectedCategory('');
    setSelectedDifficulty('');
    setChallenges([]);
  };

  const handleTypeChange = (value: string) => {
    setSelectedType(value);
    setSelectedAssignment('');
    setSelectedCategory('');
    setSelectedDifficulty('');
  };

  return (
    <Box maxW="1200px" mx="auto" p={6}>
      <VStack spacing={6} align="stretch">
        {/* Filters Section */}
        <Card bg="white" shadow="sm">
          <CardBody>
            <VStack spacing={6} align="stretch">
              {/* Primary Filters */}
              <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                <GridItem>
                  <Text fontSize="sm" fontWeight="medium" mb={2}>Class</Text>
                  <Select
                    placeholder="Select Class"
                    value={selectedClass}
                    onChange={(e) => handleClassChange(e.target.value)}
                    isDisabled={isLoadingClasses}
                    bg="white"
                    cursor={'pointer'}
                  >
                    {classes.map(c => (
                      <option key={c._id} value={c._id}>{c.classname}</option>
                    ))}
                  </Select>
                </GridItem>

                {selectedClass && (
                  <GridItem>
                    <Text fontSize="sm" fontWeight="medium" mb={2}>Student</Text>
                    <SearchableMenu
                      items={students}
                      value={selectedStudent}
                      onChange={handleStudentChange}
                      placeholder="Select Student"
                      isLoading={isLoadingStudents}
                      renderItem={(student) => (
                        <VStack align="start" spacing={0}>
                          <Text>{student.name}</Text>
                          <Text fontSize="xs" color="gray.500">{student.email}</Text>
                        </VStack>
                      )}
                      getSearchValue={(student) => `${student.name} ${student.email}`}
                    />
                  </GridItem>
                )}

                {selectedStudent && (
                  <GridItem>
                    <Text fontSize="sm" fontWeight="medium" mb={2}>Challenge Type</Text>
                    <Select
                      placeholder="Select Type"
                      value={selectedType}
                      onChange={(e) => handleTypeChange(e.target.value)}
                      bg="white"
                      cursor={'pointer'}
                    >
                      <option value="assignment">Assignment</option>
                      <option value="frontend">Frontend</option>
                    </Select>
                  </GridItem>
                )}
              </Grid>

              {/* Secondary Filters */}
              {selectedType && (
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                  {selectedType === 'assignment' ? (
                    <GridItem>
                      <Text fontSize="sm" fontWeight="medium" mb={2}>Assignment</Text>
                      <SearchableMenu
                        items={assignments}
                        value={selectedAssignment}
                        onChange={setSelectedAssignment}
                        placeholder="Select Assignment"
                        renderItem={(assignment) => assignment.filename}
                        getSearchValue={(assignment) => assignment.filename}
                      />
                    </GridItem>
                  ) : (
                    <>
                      <GridItem>
                        <Text fontSize="sm" fontWeight="medium" mb={2}>Category</Text>
                        <Select
                          placeholder="Select Category"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          bg="white"
                          cursor={'pointer'}
                        >
                          <option value="html">HTML</option>
                          <option value="css">CSS</option>
                          <option value="javascript">JavaScript</option>
                        </Select>
                      </GridItem>

                      <GridItem>
                        <Text fontSize="sm" fontWeight="medium" mb={2}>Difficulty</Text>
                        <Select
                          placeholder="Select Difficulty"
                          value={selectedDifficulty}
                          onChange={(e) => setSelectedDifficulty(e.target.value)}
                          bg="white"
                          cursor={'pointer'}
                        >
                          <option value="beginner">Beginner</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="advanced">Advanced</option>
                        </Select>
                      </GridItem>
                    </>
                  )}
                </Grid>
              )}
            </VStack>
          </CardBody>
        </Card>

        {/* Challenges List */}
        <VStack spacing={8} align="stretch">
          {isLoadingChallenges ? (
            <LoadingSpinner alignSelf="center" />
          ) : (
              <Card key={challenges._id} bg="white" shadow="md">
                <CardHeader>
                  <HStack justify="space-between" wrap="wrap" spacing={4}>
                    <VStack align="start" spacing={1}>
                    {challenges.challenges?.length > 0 && (
                      <HStack>
                        <Icon as={challenges.type === 'frontend' ? Code : BookOpen} />
                        <Text fontSize="lg" fontWeight="bold">
                          {'Challenges'}
                        </Text>
                      </HStack>
                    )}
                      {/* <HStack spacing={4} wrap="wrap">
                        {challenges.type === 'frontend' && (
                          <>
                            <Badge colorScheme="green">{challenges.frontendCategory}</Badge>
                            <Badge colorScheme="orange">{challenges.difficulty}</Badge>
                          </>
                        )}
                      </HStack> */}
                    </VStack>
                    <HStack>
                    {challenges.challenges?.length >0 && (
                      <>
                      <Icon as={Clock} />
                      <Text fontSize="sm" color="gray.500">
                        {new Date(challenges.createdAt).toLocaleDateString()}
                      </Text>
                      </>
                    )}
                    </HStack>
                  </HStack>
                </CardHeader>
                
                <CardBody pt={0}>
                  <Accordion allowMultiple>
                    {challenges?.challenges?.map((challenge) => (
                      <AccordionItem key={challenge.challengeId} border="1px solid"
                      borderColor="gray.200" borderRadius="md" mb={4}>
                      <Box 
                        position="sticky" 
                        top="64px" 
                        zIndex={2}
                        bg="white"
                        borderRadius="md"
                        mb={2}
                      >
                        <AccordionButton 
                          py={3}
                          _hover={{ bg: 'gray.50' }}
                          borderRadius="md"
                        >
                          <Box flex="1">
                            <HStack justify="space-between" wrap="wrap" spacing={4}>
                              <Text fontWeight="medium">{challenge.challengeData.title}</Text>
                              <HStack spacing={4}>
                                <Icon as={Award} color="gold" />
                                <Text>{challenge.awardedPoints} / {challenge.points} points</Text>
                                <AccordionIcon />
                              </HStack>
                            </HStack>
                            <Progress
                              value={(challenge.awardedPoints / challenge.points) * 100}
                              size="sm"
                              mt={2}
                              colorScheme="green"
                            />
                          </Box>
                        </AccordionButton>
                        </Box>
                        <AccordionPanel>
                          <VStack align="stretch" spacing={6}>
                            {/* Challenge Description */}
                            <ExpandableText
                              title="Description"
                              text={challenge.challengeData.description}
                            />
                            
                            {/* Challenge Code */}
                            <Box>
                              <Text fontWeight="medium" mb={2}>Challenge Code:</Text>
                              <Box borderRadius="md" overflowY={'auto'} height={'400px'}>
                                <SyntaxHighlighter
                                  language={challenge.challengeData.code.includes('<!DOCTYPE html>') ? 'html' : 'javascript'}
                                  style={materialDark}
                                  showLineNumbers
                                  customStyle={{ margin: 0 }}
                                >
                                  {challenge.challengeData.solution}
                                </SyntaxHighlighter>
                              </Box>
                            </Box>

                            {/* Submissions */}
                            {challenge.submissions.length > 0 && (
                                <SubmissionsList
                                  submissions={challenge.submissions}
                                  challengeCode={challenge.challengeData.solution}
                                  language={challenge.challengeData.code.includes('<!DOCTYPE html>') ? 'html' : 'html'}
                                />
                            )}
                          </VStack>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </CardBody>
              </Card>
          )}
        </VStack>
      </VStack>
    </Box>
  );
};

export default App;