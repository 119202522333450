import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, GridItem, Heading, Text, Button, HStack, VStack, Badge, useColorMode, useToast, Tabs, TabList, TabPanels, TabPanel, Tab, Tooltip, IconButton, Menu, MenuButton, MenuList, MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  List,
  ListItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Play, HelpCircle, ArrowLeft, CheckCircle, XCircle, Terminal, Layout, Code2, Send, BookOpen, AlignLeft, FileText, ArrowRight } from 'lucide-react';
import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { evaluateCode, getAIHelp, getCodeExplanation, getChallengesFromAI, fetchChallengeById, getLast15Challenges, saveViewedSolution } from '../services/learn.js';
import AIChat from '../AIChat.tsx';
import React from 'react';
import Navbar from '../NavBar.tsx';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { FiRefreshCw } from 'react-icons/fi';


const renderToIframe = (container: HTMLElement | null, content: string, colorMode: string, onConsoleLog?: (log: string) => void) => {
  if (!container || !content) return;
  
  container.innerHTML = '';
  
  const iframe = document.createElement('iframe');
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.border = 'none';
  container.appendChild(iframe);

  const doc = iframe.contentDocument;
  if (doc) {
    const consoleScript = `
      <script>
        (function() {
          const originalConsole = window.console;
          window.console = {
            log: function() {
              const args = Array.from(arguments).map(arg => 
                typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
              );
              window.parent.postMessage({
                type: 'console',
                method: 'log',
                args: args
              }, '*');
              originalConsole.log.apply(originalConsole, arguments);
            },
            error: function() {
              const args = Array.from(arguments).map(arg => 
                arg instanceof Error ? arg.message : String(arg)
              );
              window.parent.postMessage({
                type: 'console',
                method: 'error',
                args: args
              }, '*');
              originalConsole.error.apply(originalConsole, arguments);
            },
            warn: function() {
              const args = Array.from(arguments).map(arg => String(arg));
              window.parent.postMessage({
                type: 'console',
                method: 'warn',
                args: args
              }, '*');
              originalConsole.warn.apply(originalConsole, arguments);
            }
          };
        })();
      </script>
    `;

    doc.open();
    const [docHead, ...rest] = content.split('</head>');
    doc.write(`
      ${docHead}
      ${consoleScript}
      </head>
      ${rest.join('</head>')}
    `);
    doc.close();

   
    if (onConsoleLog) {
      window.addEventListener('message', (event) => {
        if (event.data?.type === 'console') {
          const { method, args } = event.data;
          const timestamp = new Date().toLocaleTimeString();
          const logMessage = `[${timestamp}] ${method.toUpperCase()}: ${args.join(' ')}`;
          onConsoleLog(logMessage);
        }
      });
    }
  }
};

const getEditorLanguage = (concept: string, code: string): string => {
  switch (concept) {
    case 'javascript':
      return 'html';
    case 'css':
      return 'html';
    case 'html':
    default:
      return 'html';
  }
};

const formatTestResult = (result: string): string => {
  try {
    const parsed = JSON.parse(result);
    return JSON.stringify(parsed, null, 2);
  } catch {
    return result;
  }
};

export default function ChallengeViewPage() {
  const { challengeId } = useParams<{ challengeId: string }>();
  const {classId} = useParams<{classId: string}>();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const cancelRef = useRef();
  
  const [code, setCode] = useState('');
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isGettingHelp, setIsGettingHelp] = useState(false);
  const [isExplaining, setIsExplaining] = useState(false);
  const [testResults, setTestResults] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showAIChat, setShowAIChat] = useState(false);
  const [aiMessages, setAiMessages] = useState<any[]>([]);
  const [aiChatTitle, setAiChatTitle] = useState('');
  const [viewedSolution, setViewedSolution] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [consoleLogs, setConsoleLogs] = useState<string[]>([]);
  const [editorLanguage, setEditorLanguage] = useState('html');
  const [editorKey, setEditorKey] = useState(0);
  const studentId = sessionStorage.getItem("CurrentLoggedInSId")?.trim();
  const { concept } = useParams<{ concept: string }>();
  const [searchParams] = useSearchParams();
  const difficulty = searchParams.get('difficulty');
  const [challenge, setChallenge] = useState([]);
  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState([]);
  const [descriptionTab, setDescriptionTab] = useState(0);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const editorRef = useRef(null);


  
  useEffect(() => {
    console.log("useEffect triggered with dependencies:", { studentId, classId, concept, difficulty, challengeId });
  
    if (!studentId || !classId || !concept || !difficulty || !challengeId) {
      console.log("Skipping useEffect due to missing dependencies");
      return;
    }
  
    const loadChallenge = async () => {
      try {
        console.log("Fetching challenges...");
        setLoading(true);
        
        const challenge = await fetchChallengeById(challengeId, studentId, classId, concept, difficulty);
        
        if (!challenge) {
          console.error("Challenge not found with id:", challengeId);
          return;
        }
  
        setChallenge(challenge);
        setEditorLanguage(getEditorLanguage(concept, challenge.challengeData.code || ''));
        if (challenge?.submissions.length > 0) {
          setCode(challenge?.submissions[challenge.submissions.length - 1]?.submissionData?.code);
        } else {
          setCode(challenge.challengeData.code || '');
        }        
        setEditorKey(prev => prev + 1);
        setViewedSolution(challenge.viewedSolution);
  
        const expectedOutputContainer = document.getElementById('expected-output');
        if (expectedOutputContainer && challenge.challengeData.solution) {
          renderToIframe(expectedOutputContainer, challenge.challengeData.solution, colorMode);
        }
      } catch (error) {
        console.error("Error loading challenge:", error);
      } finally {
        setLoading(false);
      }
    };
  
    loadChallenge();
  }, [studentId, classId, concept, difficulty, colorMode, challengeId]);
  
  
  

  const onMount = (editor, monaco) => {
    editorRef.current = editor;
    editor.focus();

    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey, shiftKey } = event;
      if ((keyCode === 33 || keyCode === 52 || keyCode === 54 || keyCode === 19) && (metaKey || ctrlKey || shiftKey)) {
        event.preventDefault();
  };
});
}



  if (!challenge) {
    return (
      <Box minH="calc(100vh - 72px)" display="flex" alignItems="center" justifyContent="center">
        <Text>Challenge not found</Text>
      </Box>
    );
  }


const confirmViewSolution = () => {
  setIsConfirmOpen(false);
  handleGetSolution(); 
};

 

  const handleRunCode = () => {
    try {
      setConsoleLogs([]); 
      const outputContainer = document.getElementById('challenge-output');
      if (outputContainer) {
        renderToIframe(outputContainer, code, colorMode, (log) => {
          setConsoleLogs(prev => [...prev, log]);
        });
      }
      setActiveTab(0);
    } catch (error) {
      console.error('Error running code:', error);
      toast({
        title: 'Error running code',
        description: 'There was an error executing your code',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleSubmitCode = async () => {
    setIsEvaluating(true);
    try {
      const results = await evaluateCode(challenge, code, classId, studentId, concept, difficulty);
      
      // Format test results for better display
      const formattedResults = {
        ...results,
        testResults: results.testResults.map(result => ({
          ...result,
          expected: formatTestResult(result.expected),
          output: formatTestResult(result.output)
        }))
      };
      
      setTestResults(formattedResults);
      setActiveTab(2);
      
      if (results.passed) {
        const score = viewedSolution ? 0 : results.score;
        const earnedScore = score;
        challenge.awardedPoints = score;
        

        toast({
          title: viewedSolution ? 'Challenge Completed' : `Earned ${earnedScore} points!`,
          description: viewedSolution 
            ? 'Great job! However, no points were awarded since you viewed the solution.'
            : 'You can now move to the next challenge',
          status: 'success',
          duration: 5000,
        });
      } else {
        toast({
          title: 'Tests Failed',
          description: 'Review the test results and try again',
          status: 'error',
          duration: 3000,
        });
      }
    } catch (error) {
      console.error('Error evaluating code:', error);
      toast({
        title: 'Error evaluating code',
        description: 'Failed to evaluate your code. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleGetHelp = async () => {
    setIsGettingHelp(true);
    try {
      const hint = await getAIHelp(challenge, code, classId);
      setAiMessages([{ type: 'text', content: hint }]);
      setAiChatTitle('AI Help');
      setShowAIChat(true);
    } catch (error) {
      console.error('Error getting help:', error);
      toast({
        title: 'Error getting help',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsGettingHelp(false);
    }
  };

  const handleGetSolution = async () => {
    if(!challenge?.viewedSolution || !viewedSolution)
    try{
      await saveViewedSolution(challengeId, studentId, classId, concept, difficulty);
    }
    catch(e){
      console.error(e);
    }
    setViewedSolution(true);
    setAiMessages([
      { 
        type: 'text', 
        content: 'Here\'s the solution for this challenge. Note: Using this solution will result in 0 points for this challenge.' 
      },
      { 
        type: 'code', 
        content: challenge.challengeData.solution, 
        language: concept === 'javascript' ? 'javascript' : 
                 concept === 'css' ? 'css' : 'html' 
      }
    ]);
    setAiChatTitle('AI Solution');
    setShowAIChat(true);
  };

  const handleGetExplanation = async (type: 'line-by-line' | 'summary') => {
    setIsExplaining(true);
    try {
      const explanation = await getCodeExplanation(code, type, classId);
      
      if (type === 'line-by-line') {
        setAiMessages(explanation.map((item: any) => ({
          type: 'explanation',
          line: item.line,
          content: item.explanation
        })));
      } else {
        setAiMessages([{ type: 'text', content: explanation }]);
      }
      
      setAiChatTitle('Code Explanation');
      setShowAIChat(true);
    } catch (error) {
      console.error('Error getting explanation:', error);
      toast({
        title: 'Error getting explanation',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsExplaining(false);
    }
  };

  const handleNextChallenge = async () => {
    setIsLoadingNext(true);
    try {
      const last15Challenges = await getLast15Challenges(studentId, classId, concept, difficulty);
      const result = await getChallengesFromAI(concept || '', difficulty, classId || '', studentId || '', last15Challenges || []);
      setChallenges(result?.challenges || []);
      const newChallenges = result?.challenges || [];
      const nextChallenge = newChallenges.length > 0 ? newChallenges[newChallenges.length - 1] : null;
      if (nextChallenge) {
        navigate(`/student-portal/${classId}/learncode/challenge/${nextChallenge.challengeId}/${concept}?difficulty=${nextChallenge.challengeData.difficulty}`);
      } 
    } catch (error) {
      console.error('Error loading next challenge:', error);
      toast({
        title: 'Error loading next challenge',
        status: 'error',
        duration: 3000,
      });
    } finally {
      
      setTestResults(null);
      setConsoleLogs([]);
      setCode('');
      handleRunCode();
      setIsLoadingNext(false);
    }
  };

  const handleRefreshOutput = () => {
    const expectedOutputContainer = document.getElementById('expected-output');
    if (expectedOutputContainer && challenge.challengeData.solution) {
      renderToIframe(expectedOutputContainer, challenge.challengeData.solution, colorMode);
    }
  };

  const handleItemClick = (submission) => {
    setCode(submission.submissionData.code);
    handleRunCode();
    handleRefreshOutput();
    setDescriptionTab(0);
  };
  

  if (loading) {
    return (
      <Box minH="calc(100vh - 72px)" display="flex" alignItems="center" justifyContent="center">
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <>
    <Navbar />
      <Box minH="calc(100vh - 72px)" bg={colorMode === 'light' ? 'white' : 'gray.900'}>
      <PanelGroup direction="horizontal">
        {/* Left Panel - Challenge Description */}
        <Panel defaultSize={40} minSize={30}>
        <Box h="calc(100vh - 72px)" overflowY="auto" bg={colorMode === 'light' ? 'white' : 'gray.800'} p={6}>
            <VStack align="stretch" spacing={6}>
              <HStack justify="space-between" align="center">
                <Button
                  leftIcon={<ArrowLeft size={20} />}
                  variant="ghost"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <HStack>
                  {challenge.awardedPoints !== undefined && (
                    <Badge
                      colorScheme={challenge.awardedPoints > 0 ? 'green' : 'gray'}
                      px={3}
                      py={1}
                      rounded="full"
                      fontSize="md"
                    >
                      {challenge.awardedPoints} / {challenge.points} Points
                    </Badge>
                  )}
                  {challenge.submissions && challenge.submissions.length>0 && (
                    <Button
                      rightIcon={<ArrowRight size={20} />}
                      colorScheme="blue"
                      size="sm"
                      onClick={handleNextChallenge}
                      isLoading={isLoadingNext}
                    >
                      Next Challenge
                    </Button>
                  )}
                </HStack>
              </HStack>
              <Tabs index={descriptionTab} onChange={setDescriptionTab}>
                <TabList>
                  <Tab>Description</Tab>
                  <Tab>Submissions</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel px={0}>
                    <VStack align="stretch" spacing={6}>

              <Box>
                <Heading size="lg" mb={4}>
                 {challenge?.challengeData?.title}
                </Heading>
                <Text 
                  whiteSpace="pre-wrap" 
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                >
                  {challenge?.challengeData?.description}
                </Text>
              </Box>

              {challenge?.challengeData?.solution && (
                <Box>
                <HStack justifyContent="left">
                  <Heading size="md" mb={2}>Expected Output</Heading>
                  <IconButton
                    mb={2}
                    aria-label="Refresh Output"
                    icon={<FiRefreshCw size={16} />}
                    variant="ghost"
                    colorScheme='blue'
                    onClick={handleRefreshOutput}
                  />
                </HStack>
                <Box
                  id="expected-output"
                  height={'350'}
                  bg={colorMode === 'light' ? 'white' : 'gray.700'}
                  rounded="md"
                  overflow="hidden"
                  borderWidth={1}
                  borderColor={colorMode === 'light' ? 'gray.200' : 'gray.200'}
                />
              </Box>
              )}

              <Box>
                <Heading size="md" mb={2}>Hints</Heading>
                <VStack align="stretch" spacing={2}>
                  {challenge?.challengeData?.hints.map((hint: string, index: number) => (
                    <Text 
                      key={index} 
                      color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                      p={3}
                      bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
                      rounded="md"
                    >
                      {index + 1}. {hint}
                    </Text>
                  ))}
                </VStack>
              </Box>
            </VStack>
            </TabPanel>
            
            <TabPanel px={0}>
  <VStack align="stretch" spacing={4}>
    <Heading size="md">Your Submissions</Heading>

    {challenge.submissions.length === 0 ? (
      <List spacing={3}>
      <ListItem
      p={4}
      bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
      rounded="md"
      borderWidth={1}
      borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
    >
      <Text color="gray.500" textAlign="center">
        No submissions yet.
      </Text>
      </ListItem>
      </List>
    ) : (
      <List spacing={3}>
        {challenge.submissions.map((submission, index) => (
          <ListItem
            key={submission.id}
            p={4}
            bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
            rounded="md"
            borderWidth={1}
            borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
            onClick={() => handleItemClick(submission)}
            cursor="pointer"
          >
            <HStack justify="space-between">
              <Text fontWeight="bold">#{index + 1}</Text>
              <Text fontSize="sm" color="gray.500">
                {new Date(submission.submittedAt).toLocaleString()}
              </Text>
            </HStack>
            <HStack mt={2} spacing={4}>
              <Text fontSize="sm">
                Feedback:{' '}
                <Button
                  variant="link"
                  colorScheme="blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFeedback(submission.evaluationFeedback?.differences || "No feedback available");
                  }}
                >
                  {submission.evaluationFeedback?.differences
                    ? submission.evaluationFeedback.differences.length > 20
                      ? submission.evaluationFeedback.differences.substring(0, 20) + '...'
                      : submission.evaluationFeedback.differences
                    : "No feedback"}
                </Button>
              </Text>
              <Text fontSize="sm">Points: {submission.awardedPoints}</Text>
            </HStack>
          </ListItem>
        ))}
      </List>
    )}
  </VStack>

  {/* Feedback Modal */}
  <Modal isOpen={!!selectedFeedback} onClose={() => setSelectedFeedback(null)}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Evaluation Feedback</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>{selectedFeedback}</Text>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" onClick={() => setSelectedFeedback(null)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
</TabPanel>

            </TabPanels>
            </Tabs>
            </VStack>
          </Box>
          </Panel>

          {/* Divider */}
          <PanelResizeHandle style={{ width: '1px', background: colorMode === 'light' ? '#E2E8F0' : '#4A5568' }} />

          {/* Right Panel - Code Editor */}
          <Panel defaultSize={60} minSize={40}>
          <Box h="90%" minH={'1000px'} display="flex" flexDirection="column">
            <HStack
              w="full"
              p={4}
              bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
              justify="space-between"
              borderBottom="1px"
              borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
            >
              <HStack>
                <Tooltip label="Run Code">
                  <IconButton
                    aria-label="Run Code"
                    icon={<Play size={20} />}
                    variant="ghost"
                    onClick={handleRunCode}
                  />
                </Tooltip>
                <Tooltip label="Submit Code">
                  <IconButton
                    aria-label="Submit Code"
                    icon={<Send size={20} />}
                    variant="ghost"
                    onClick={handleSubmitCode}
                    isLoading={isEvaluating}
                  />
                </Tooltip>
              </HStack>
              
              <HStack spacing={2}>
                <Menu>
                  <Tooltip label="Explain Code">
                    <MenuButton
                      as={IconButton}
                      aria-label="Explain Code"
                      icon={<BookOpen size={20} />}
                      variant="ghost"
                      isLoading={isExplaining}
                    />
                  </Tooltip>
                  <MenuList>
                    <MenuItem
                      icon={<AlignLeft size={16} />}
                      onClick={() => handleGetExplanation('line-by-line')}
                    >
                      Line by Line
                    </MenuItem>
                    <MenuItem
                      icon={<FileText size={16} />}
                      onClick={() => handleGetExplanation('summary')}
                    >
                      Summary
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Tooltip label="Get AI Help">
                  <IconButton
                    aria-label="Get AI Help"
                    icon={<HelpCircle size={20} />}
                    variant="ghost"
                    onClick={handleGetHelp}
                    isLoading={isGettingHelp}
                  />
                </Tooltip>
                <Tooltip label="Get AI Solution">
                  <IconButton
                    aria-label="Get AI Solution"
                    icon={<Code2 size={20} />}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                      if (challenge.viewedSolution || viewedSolution) {
                        handleGetSolution(); 
                      } else {
                        setIsConfirmOpen(true); 
                      }
                    }}
                  />
                </Tooltip>

              </HStack>
            </HStack>

            <PanelGroup direction="vertical">
            <Panel defaultSize={50} minSize={40}>
                <Box h="full">
                  <Editor
                    key={editorKey}
                    height="100%"
                    defaultLanguage={editorLanguage}
                    language={editorLanguage}
                    theme={colorMode === 'light' ? 'light' : 'vs-dark'}
                    value={code}
                    onChange={(value) => setCode(value || '')}
                    onMount={onMount}
                    options={{
                      minimap: { enabled: false },
                      fontSize: 14,
                      lineNumbers: 'on',
                      roundedSelection: false,
                      scrollBeyondLastLine: false,
                      automaticLayout: true,
                      tabSize: 2,
                      wordWrap: 'on',
                      wrappingIndent: 'indent',
                      formatOnPaste: true,
                      formatOnType: true,
                      contextmenu: false
                    }}
                  />
                </Box>
              </Panel>

              <PanelResizeHandle style={{ height: '1px', background: colorMode === 'light' ? '#E2E8F0' : '#4A5568' }} />

              <Panel>
                <Tabs 
                  index={activeTab} 
                  onChange={setActiveTab}
                  colorScheme={colorMode === 'light' ? 'blue' : 'gray'}
                  h={'full'}
                  overflowY={'auto'}
                >
                  <TabList px={4} borderBottomColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}>
                    <Tab _selected={{ bg: colorMode === 'light' ? 'gray.100' : 'gray.700' }}>
                      <HStack><Layout size={16} /><Text>Output</Text></HStack>
                    </Tab>
                    <Tab _selected={{ bg: colorMode === 'light' ? 'gray.100' : 'gray.700' }}>
                      <HStack><Terminal size={16} /><Text>Console</Text></HStack>
                    </Tab>
                    <Tab _selected={{ bg: colorMode === 'light' ? 'gray.100' : 'gray.700' }}>
                      <HStack><CheckCircle size={16} /><Text>Test Results</Text></HStack>
                    </Tab>
                  </TabList>

                  <TabPanels>
                    {/* Output Panel */}
                    <TabPanel >
                      <Box  h={'500'} id="challenge-output"  />
                    </TabPanel>
                    
                    {/* Console Panel */}
                    <TabPanel>
                      <Box
                        fontFamily="mono"
                        fontSize="sm"
                        whiteSpace="pre-wrap"
                        p={4}
                        bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
                        rounded="md"
                        h={'full'}
                      >
                        {consoleLogs.length === 0 ? (
                          <Text color="gray.500">No console output yet. Run your code to see logs here.</Text>
                        ) : (
                          consoleLogs.map((log, index) => (
                            <Text key={index} color={
                              log.includes('ERROR') ? 'red.500' :
                              log.includes('WARN') ? 'yellow.500' :
                              colorMode === 'light' ? 'gray.800' : 'gray.100'
                            }>
                              {log}
                            </Text>
                          ))
                        )}
                      </Box>
                    </TabPanel>
                    
                    {/* Test Results Panel */}
                    <TabPanel>
                      {testResults ? (
                        <VStack align="stretch" spacing={4}>
                          <Box
                            p={4}
                            bg={colorMode === 'light' ? 'white' : 'gray.700'}
                            rounded="md"
                            borderWidth="1px"
                            borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                          >
                            <Heading size="sm" mb={2}>Overall Results</Heading>
                            <HStack spacing={4}>
                              <Badge
                                colorScheme={testResults.passed ? 'green' : 'red'}
                                px={2}
                                py={1}
                                rounded="md"
                              >
                                {testResults.passed ? 'All Tests Passed' : 'Tests Failed'}
                              </Badge>
                              <Text>Score: {testResults.score}</Text>
                            </HStack>
                          </Box>

                          {testResults.testResults.map((result: any, index: number) => (
                            <Box
                              key={index}
                              p={4}
                              bg={colorMode === 'light' ? 'white' : 'gray.700'}
                              rounded="md"
                              borderWidth="1px"
                              borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                            >
                              <HStack spacing={2} mb={4}>
                                {result.passed ? (
                                  <CheckCircle size={20} color="green" />
                                ) : (
                                  <XCircle size={20} color="red" />
                                )}
                                <Text fontWeight="bold">{result.name}</Text>
                              </HStack>
                              <VStack align="stretch" spacing={2}>
                                <Box>
                                  <Text fontWeight="medium" mb={1}>Expected:</Text>
                                  <Box
                                    p={2}
                                    bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                                    rounded="md"
                                    fontFamily="mono"
                                    fontSize="sm"
                                    whiteSpace="pre-wrap"
                                  >
                                    {result.expected}
                                  </Box>
                                </Box>
                                <Box>
                                  <Text fontWeight="medium" mb={1}>Actual:</Text>
                                  <Box
                                    p={2}
                                    bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                                    rounded="md"
                                    fontFamily="mono"
                                    fontSize="sm"
                                    whiteSpace="pre-wrap"
                                    color={result.passed ? 'inherit' : 'red.500'}
                                  >
                                    {result.output}
                                  </Box>
                                </Box>
                              </VStack>
                            </Box>
                          ))}
                          <Box
                              p={4}
                              bg={colorMode === 'light' ? 'white' : 'gray.700'}
                              rounded="md"
                              borderWidth="1px"
                              borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                            >
                              <Heading size="sm" mb={2}>Feedback</Heading>
                              {testResults?.differences}
                            </Box>
                        </VStack>
                      ) : (
                        <VStack spacing={4} align="center" justify="center" h="full">
                          <Text color="gray.500">
                            No test results yet. Submit your code to run tests.
                          </Text>
                        </VStack>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Panel>
            </PanelGroup>
          </Box>
        </Panel>
      </PanelGroup>
      </Box>

      <AIChat
        isOpen={showAIChat}
        onClose={() => setShowAIChat(false)}
        messages={aiMessages}
        title={aiChatTitle}
      />
      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              View AI Solution?
            </AlertDialogHeader>

            <AlertDialogBody>
              Viewing the AI solution will award <strong>0 points</strong> for this challenge
              and <strong>cannot be undone</strong>. Are you sure you want to continue?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmViewSolution} ml={3}>
                Yes, Show Solution
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </>
  );
}