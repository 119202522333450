import React, { useState } from "react";
import { Box, Button, Heading, Image, Input, FormControl, FormLabel, Stack, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, Spinner } from "@chakra-ui/react";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config"; 
import logo from '../../../Assets/UnichatLogo.png';
import loginimage from '../../../Assets/loginImage1.png';
import { getStudentByEmail,sendUserData } from "../../../Services/Student/studentService.tsx"; 
import { useNavigate, useLocation } from "react-router-dom";
import giimage from '../../../Assets/G-Ideas.png'
import { LoadingSpinner } from "../../../commons/LoadingSpinner.tsx";
import { getUserData } from "../../../Services/Teacher/teacherService.tsx";


const SLogin = () => {
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [user,setUser] = useState([]);
  const [loading, setLoading] = useState(false);


  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope("https://www.googleapis.com/auth/drive");
    provider.addScope("https://www.googleapis.com/auth/drive.file");
    provider.addScope("https://www.googleapis.com/auth/documents");

    const auth = getAuth(app);

    try {
      const result = await signInWithPopup(auth, provider);
      setLoading(true);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const idToken = await loggedInUser.getIdToken();
      localStorage.setItem("idToken", idToken);
      window.dispatchEvent(new Event('storage'));
      const token = credential.accessToken;
      localStorage.setItem("studentGoogleAccessToken", token);
      sessionStorage.setItem("userType", "student");
      sessionStorage.setItem("activeNav",JSON.stringify(0));
      
      if (loggedInUser) {
        setUser(loggedInUser);
        const accessToken = await loggedInUser.getIdTokenResult(true);
        const expirationTimeUTC = new Date(accessToken.expirationTime).toISOString();
        localStorage.setItem("studentTokenExpirationTime", expirationTimeUTC);

        try {
          const student = await getStudentByEmail(loggedInUser.email);
          if (student.isFirstTimeLogin || student.length === 0) {
            setIsFirstTimeLogin(true);
            setLoading(false);
          } else {
            sessionStorage.setItem("CurrentLoggedInSId", student._id); 
            const response = await getUserData(student?._id,"student");
            sessionStorage.setItem('sideBarData',JSON.stringify(response));
            const redirectPath = new URLSearchParams(location.search).get("redirect") || '/student-portal/homeframe';
            navigate(redirectPath);
            setLoading(false);
            sessionStorage.setItem("studentUserData", JSON.stringify(student));
          }
          
        } catch (error) {
          console.log("error occurred", error);
        }
      }
    } catch (error) {
      console.error("Sign-in Error:", error);
    }
  };

  const handleSubmit = async () => {
    if (!firstName || !lastName || !schoolEmail) {
      setError("All fields are mandatory. Please fill in all fields.");
    } else if (!/\S+@\S+\.\S+/.test(schoolEmail)) {
      setError("Email format is invalid. Please check your email and try again.");
    } else {
        const userData = {
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            firstName: firstName,
            lastName: lastName,
            schoolEmail: schoolEmail,
            isFirstTimeLogin: false
          };
          
          sessionStorage.setItem("studentUserData", JSON.stringify(userData));

      try {
        setLoading(true);
        const stud = await sendUserData(userData);
        sessionStorage.setItem("CurrentLoggedInSId", stud.data._id); 
        const response = await getUserData(stud.data._id,"student");
        sessionStorage.setItem('sideBarData',JSON.stringify(response));
        navigate('/student-portal/homeframe');
        setLoading(false);
      } catch (error) {
        console.error("Error submitting user data:", error);
      }
    }

    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
      borderRadius="md"
    >
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="md"
        p={8}
        maxWidth={isFirstTimeLogin ? "2xl" : "lg"}
        width="full"
        textAlign="center"
      >
        <Image
          src={giimage}
          alt="GI Logo"
          boxSize="80px"
          borderRadius="full"
          mx="auto"
          mb={6}
        />
        <Heading as="h1" size="lg" mb={6}>
          Welcome to Generative Ideas
        </Heading>
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertTitle mr={2}>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError("")} />
          </Alert>
        )}
        {isFirstTimeLogin ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            bg="gray.50"
          >
            <Image
              src={loginimage}
              alt="Login Illustration"
              boxSize="200px"
              borderRadius="md"
              mb={4}
            />
            <Stack spacing={4} width="full">
              <Stack direction="row" spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="first-name">First Name</FormLabel>
                  <Input
                    id="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="last-name">Last Name</FormLabel>
                  <Input
                    id="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                  />
                </FormControl>
              </Stack>
              <FormControl isRequired>
                <FormLabel htmlFor="school-email">School Email</FormLabel>
                <Input
                  id="school-email"
                  value={schoolEmail}
                  onChange={(e) => setSchoolEmail(e.target.value)}
                  placeholder="School Email"
                />
              </FormControl>
            </Stack>
            <Button
              onClick={handleSubmit}
              bg="purple.900"
              color="white"
              width="200px"
              mt={4}
              _hover={{ bg: "purple.800" }}
              _focus={{ boxShadow: "none" }}
            >
              Submit
              {loading && (
                  <LoadingSpinner />  
                )}
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            bg="gray.50"
          >
            <Image
              src={loginimage}
              alt="Login Illustration"
              boxSize="200px"
              borderRadius="md"
            />
            <Button
                onClick={signInWithGoogle}
                bg="purple.900"
                color="white"
                width="200px"
                _hover={{ bg: "purple.900" }}
                _focus={{ boxShadow: "none" }}
              >
                Sign in with Google
                {loading && (
                  <LoadingSpinner/>  
                )}
            </Button>

          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SLogin;
