import React, { useState, useEffect } from 'react';
import { getClassesByUserId, uploadAssignment } from '../../../Services/Teacher/teacherService.tsx';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Input,
  Text,
  HStack,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Flex,
  Spinner,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Select,
} from '@chakra-ui/react';
import { FaChevronDown, FaEye, FaTimes } from 'react-icons/fa';
import { CloseIcon } from '@chakra-ui/icons';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { app } from "../../../config.js";
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAssignmentsForClass } from '../../../Services/Student/studentService.tsx';

const LearnQ = () => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // State for modal visibility
  const toast = useToast();
  const userId = sessionStorage.getItem('CurrentLoggedInTId').trim();
  const [assignments, setAssignments] = useState([]);
  const { classId } = useParams();

  useEffect(() => {
  const fetchAssignments = async () => {
    try {
      const assignmentsData = await fetchAssignmentsForClass(classId);
      setAssignments(assignmentsData || []);
    } catch (error) {
      toast({
        title: 'Error fetching assignments',
        description: 'There was an error fetching your assignments. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  fetchAssignments();
}, [userId, toast]);




  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadSuccess(false);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast({
        title: 'Select file to upload',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setUploading(true);
    let token;
    const userType = sessionStorage.getItem("userType");
    
    if (userType === "student") {
      token = localStorage.getItem("studentGoogleAccessToken");
    } else if (userType === "teacher") {
      token = localStorage.getItem("googleAccessToken");
    }

    const expirationTime = new Date(localStorage.getItem("TokenExpirationTime"));
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 5 * 60 * 1000) {
      const auth = getAuth(app);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      const accessToken = await loggedInUser.getIdTokenResult(true);
      if(userType == "student"){
      localStorage.setItem("studentGoogleAccessToken", token);
      localStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
      }
      else if(userType == "teacher"){
        localStorage.setItem("googleAccessToken", token);
        localStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
      }
    }
    
    try {
      const formData = new FormData();
      formData.append('classIds', [classId]);
      formData.append('userId', userId);
      formData.append('token', token);
      if (selectedFile) formData.append('file', selectedFile);
      const response = await uploadAssignment(formData);
      const newAssignment = response.assignment; 
      setAssignments((prevAssignments) => [...prevAssignments, newAssignment]);
      setUploading(false);
      setUploadSuccess(true);
      setIsOpen(false);
      setSelectedFile(null);
      toast({
        title: 'Assignment uploaded successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setUploading(false);
      toast({
        title: 'Error uploading file',
        description: 'There was an error uploading your assignment. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  return (
    <Box>
      <Flex justify="right" mb={6}>
        <Button onClick={() => setIsOpen(true)} bg={'purple.900'} _hover={{bg:"purple.900"}} color={'white'}>Upload Assignment</Button>
      </Flex>

      <Box h={'90vh'} p={6} mx="auto" ml={-5} mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg" maxHeight="600px" overflowY="auto">
      <Heading fontSize="lg" mb={4}>Uploaded Files</Heading>
      <HStack mt={6} mb={4} justifyContent="right" spacing={8}>
     </HStack>
      <Table variant="simple" size="md" fontSize={'md'}>
      <Thead>
          <Tr>
            <Th w="40%">File Name</Th>
            <Th w="40%">Uploaded At</Th>
            <Th w="20%">Actions</Th>
          </Tr>
        </Thead>

        <Tbody>
          {assignments.length > 0 ? (
            assignments.map((file) => (
              <Tr key={file._id}> 
                <Td w="40%">{file.filename}</Td> 
                <Td w="40%">{new Date(file.createdAt).toLocaleString('en-US', { 
                  month: 'numeric', 
                  day: 'numeric', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit', 
                  hour12: true 
                })}</Td>
                <Td w="20%">
                  <IconButton
                    aria-label="View file"
                    icon={<FaEye />}
                    variant={'ghost'}
                    onClick={() => window.open(file.classFiles[0].link, '_blank')} // Open link in a new tab
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="3" textAlign="center">No uploaded files yet.</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
{/* Modal for Uploading Assignment */}
<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Assignment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* File Upload Section */}
            <Box 
              border="1px" 
              borderColor="gray.300" 
              p={4} 
              textAlign="center" 
              cursor="pointer"
              borderRadius="md"
              _hover={{ borderColor: 'gray.400' }}
              mb={4}
            >
              <Input
                type="file"
                onChange={handleFileSelect}
                accept=".doc,.docx,.pdf"
                display="none" // Hide the default file input
                id="file-input"
              />
              <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                {selectedFile ? (
                  <Text>{selectedFile.name}</Text>
                ) : (
                  <Text>Drag 'n' drop a file here, or click to select one</Text>
                )}
              </label>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button bg={'purple.900'} _hover={{ bg: "purple.800" }} color={'white'} mr={3} onClick={handleFileUpload} isLoading={uploading}>
              Upload
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default LearnQ;
