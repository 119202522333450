import { Box, Flex, Button, useColorMode, Heading } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Sun, Moon, Code2 } from 'lucide-react';
import React from 'react';

export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const {classId} = useParams();
  const storedClassName = sessionStorage.getItem(`class_${classId}`);

  return (
    <Box 
      as="nav" 
      bg={colorMode === 'light' ? 'white' : 'gray.800'} 
      py={4} 
      px={8} 
      borderBottom="1px"
      borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
      position="relative"
      zIndex={2}
      shadow="sm"
    >
      <Flex justify="space-between" align="center" maxW="1200px" mx="auto">
        {/* Logo & Test Box */}
        <Flex align="center" cursor="pointer" onClick={() => navigate(`/student-portal/${classId}/learncode`)}>
          <Code2 size={24} />
          <Heading size="md" ml={2}>CodeMentor AI</Heading>
          {/* Test Box */}
          <Box 
            ml={1} 
            px={1} 
            py={0} 
            fontSize="sm"
            bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
            color={colorMode === 'light' ? 'gray.800' : 'gray.200'}
            rounded="md"
            borderWidth="1px"
            borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
            transition="all 0.2s ease-in-out"
            _hover={{ transform: "scale(1.02)" }}
            _active={{ transform: "scale(0.98)" }}
          >
            {storedClassName}
          </Box>
        </Flex>
        
        {/* Navigation Buttons */}
        <Flex gap={4}>
          <Button variant="ghost" onClick={() => navigate(`/student-portal/${classId}/learncode/assignments`)}>
            Assignments
          </Button>
          <Button variant="ghost" onClick={() => navigate(`/student-portal/${classId}/learncode/concepts`)}>
            Learn
          </Button>
          {/* <Button onClick={toggleColorMode} variant="ghost">
            {colorMode === 'light' ? <Moon size={20} /> : <Sun size={20} />}
          </Button> */}
        </Flex>
      </Flex>
    </Box>
  );
}
