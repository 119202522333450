import React, { useState, useEffect } from 'react';
import { Box, Flex, IconButton, Input, VStack, Text, Image, Avatar } from '@chakra-ui/react';
import { FiSend, FiX, FiMessageSquare, FiHome } from 'react-icons/fi';
import chatgptwhite from '../../../Assets/gptwhite.png'; 
import { Rnd } from 'react-rnd';
import { getChatGPTResponseForSRS, regenerateSRSFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import giimage from '../../../Assets/G-Ideas.png'
import { useParams } from 'react-router-dom';
import { postSRSData } from '../../../Services/Student/studentService.tsx';

const DummyChatBox = ({ 
  onClose, srsData, setSRSData, isNonFunctionalRequired, projectBackLog, projectDescription, currentTeacherId, 
  messages, setMessages, currentQuestionIndex, setCurrentQuestionIndex, noEntered, setNoEntered, askedRegenerate, setAskedRegenerate,
  missingItems, setMissingItems 
}) => {
const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(450);
  const [height, setHeight] = useState(500);
  const [x, setX] = useState(() => calculateInitialX());
  const [y, setY] = useState(() => calculateInitialY());
  const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();
  const { projectId, classId } = useParams();


  function calculateInitialX() {
    return window.innerWidth < 1440 ? 440 : 705; 
  }

  function calculateInitialY() {
    return window.innerWidth < 1440 ? 366 : 100; 
  }

  const questions = isNonFunctionalRequired == true
  ? [
      "Is the requirement unambiguous?",
      "Is the requirement testable?"
    ]
  : [
      "Has the requirement specified all the inputs?",
      "Has the requirement specified all the actions the system should perform?",
      "Has the requirement specified all the conditions the system should check?",
      "Has the requirement specified all data updates?"
    ];

const staticNoResponses = isNonFunctionalRequired == true
  ? {
      0: "Please clarify the ambiguous requirements.",
      1: "Please specify how we can test the requirement."
    }
  : {
      0: "Please let me know what inputs are missing.",
      1: "Please let me know what actions are missing.",
      2: "Please let me know what conditions are missing.",
      3: "Please let me know what data updates are missing."
    };


  const sendMessageWithDelay = async (message) => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 2-second delay
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'bot', content: message }
    ]);
    setLoading(false);
  };

  const handleSendMessage = async (userResponse = input) => {
    if (userResponse.trim() === '') return;

    if (userResponse.trim().toLowerCase() === 'clear') {
      setMessages([
        { role: 'bot', content: isNonFunctionalRequired == true ? 'Is the requirement unambiguous?' : 'Have all the inputs been specified?' }
      ]);
      setCurrentQuestionIndex(0);
      setNoEntered(false);
      setAskedRegenerate(false);
      setMissingItems([]); 
      setInput('');
      return;
    }
  
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: userResponse },
    ]);
    setInput('');
  
    if (askedRegenerate) {
      if (userResponse.toLowerCase() === 'yes') {
        handleRegenerate(); 
      } else {
        sendMessageWithDelay("SRS regeneration canceled.");
        onClose();
      }
    }  else if (noEntered) {
      setMissingItems((prev) => [...prev, userResponse]);
      setNoEntered(false);
      moveToNextQuestionOrFinish([...missingItems, userResponse]); 
    }
    
     else {
      if (userResponse.toLowerCase() === 'no') {
        setNoEntered(true);
        await sendMessageWithDelay(staticNoResponses[currentQuestionIndex]);
      } else {
        moveToNextQuestionOrFinish(); 
      }
    }
  };
    


  const moveToNextQuestionOrFinish = async (updatedMissingItems = missingItems) => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      await sendMessageWithDelay(questions[currentQuestionIndex + 1]);
      setNoEntered(false);
    } else {
      if (updatedMissingItems.length > 0) {
        await sendMessageWithDelay(
          "You've completed all the checks. Would you like to regenerate the SRS with the missing details? (yes/no)"
        );
        setAskedRegenerate(true);
      } else {
        await sendMessageWithDelay("All questions answered. No changes needed.");
        onClose();
      }
    }
  };
  





const handleRegenerate = async () => {
  setLoading(true);
  try {

    const chatHistory = messages.map((msg, index) => ({
      id: index + 1,
      role: msg.role,
      content: msg.content
  }));

      const response = await regenerateSRSFromGPT(
          srsData, 
          missingItems.join(', '), 
          isNonFunctionalRequired, 
          projectBackLog, 
          projectDescription, 
          classId,
          JSON.stringify(messages)
      );

      let trimmedResponse = response.startsWith('```') 
          ? response.replace(/^```json|```$/g, '') 
          : response;
      trimmedResponse = JSON.parse(trimmedResponse);
      setSRSData(trimmedResponse);
      console.log(missingItems);
      await postSRSData(projectId, projectDescription, projectBackLog, JSON.stringify(chatHistory), trimmedResponse, currentLoggedInSId, isNonFunctionalRequired);
      await sendMessageWithDelay('SRS has been regenerated based on your inputs.');
      setAskedRegenerate(false);  
      setMissingItems([]);        
      setCurrentQuestionIndex(0);
      onClose();
  } catch (error) {
      console.error('Error regenerating SRS:', error);
      await sendMessageWithDelay('Sorry, there was an error regenerating the SRS.');
  } finally {
      setLoading(false);
  }
};


  const handleMissingItemsResponse = async () => {
    await sendMessageWithDelay("Would you like to regenerate the SRS? Type 'yes' to regenerate or 'no' to finish.");
  };

  return (
    <Rnd
      size={{ width, height }}
      position={{ x, y }}
      minWidth={300}
      minHeight={300}
      dragHandleClassName="handle"
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
      onDragStop={(e, d) => {
        setX(d.x);
        setY(d.y);
      }}
    >
      <Box width="100%" height="100%" bg="gray.50" color="black" borderRadius="xl" display="flex" flexDirection="column" boxShadow="2xl" fontFamily="initial" fontSize="16">
        {/* Chat Header */}
        <Flex className='handle' cursor="move" alignItems="center" justifyContent="space-between" bg="purple.900" p={2} borderTopRadius="xl" color="white">
          <Flex alignItems="center">
           
            <Text ml={2} fontWeight="bold" fontSize="xl">SRS AI</Text>
          </Flex>
          <Flex>
            <IconButton size="sm" icon={<FiX />} aria-label="Close" variant="ghost" color="white" onClick={onClose} />
          </Flex>
        </Flex>
        <Box bg="gray.200" p={2} textAlign="center" fontSize="sm" fontWeight="medium">
        Enter 'clear' to start again.
        </Box>


        {/* Chat Body */}
        <VStack p={2} flex="1" overflowY="auto" bg="#f7f7f8" width="100%" position="relative" flexDirection={"column-reverse"}>
        {[...messages].reverse().map((msg, idx) => (
          <Flex key={idx} align="center" justify={msg.role === 'user' ? 'flex-end' : 'flex-start'} w="100%">
            {msg.role !== 'user' && (
              <Avatar
                name="GI Avatar" 
                src={giimage}
                size="sm"
                mr={2}
              />
            )}
            <Box bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'} p={2} m={2} borderRadius="xl" maxWidth="80%" color="black" wordBreak="break-word">
              <Text>{msg.content}</Text>
            </Box>
          </Flex>
        ))}
      </VStack>

        {/* Chat Input */}
        <Flex p={2} borderTop="1px solid" borderColor="gray.200" alignItems="center" bg="#f7f7f8">
          <Input value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSendMessage(); }} placeholder="Type your message..." flex="1" height="40px" />
          <IconButton size="sm" icon={<FiSend />} aria-label="Send message" onClick={() => handleSendMessage()} ml={2} bg="purple.900" color="white" isLoading={loading} />
        </Flex>
      </Box>
    </Rnd>
  );
};

export default DummyChatBox;
