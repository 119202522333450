// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.App {
  transform: scale(0.95);
  transform-origin: 0 0;
  width: 105.22%;
  
}
.App{
  background: "gray";
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,cAAc;;AAEhB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["\n\n.App {\n  transform: scale(0.95);\n  transform-origin: 0 0;\n  width: 105.22%;\n  \n}\n.App{\n  background: \"gray\";\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
